<div class="intro-container">
  <div class="intro-wrapper">
      <h1>Bonjour <span *ngIf="customerInfos?.firstName">{{customerInfos?.firstName}},</span></h1>
      <h2>
        Bienvenue sur votre espace fidélité !
        <svg><use xlink:href="assets/img/icons.svg#icon-login-title"></use></svg>
      </h2>
  </div>
</div>

<div class="main-container customer" *ngIf="!globalLoading">
  <h1 class="title"><span class="icon"></span>Ma carte de fidélité</h1>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-lg-4 home-block mb-4">
        <ng-casto-common-customer-card-information [customerId]="storageService.getUser()" [context]="context"></ng-casto-common-customer-card-information>
      </div>

      <div class="col-sm-12 col-lg-8 home-block mb-4" *ngIf="cardInfos">
        <ng-casto-common-customer-points [customerId]="storageService.getUser()" [context]="context"></ng-casto-common-customer-points>
      </div>
    </div>
    <div class="row" *ngIf="displayPremiumInfos()">
      <div class="col-12 home-block mb-4">
        <div class="form-card premium-infos">
          <div class="card-container">
            <svg>
              <use xlink:href="assets/img/icons.svg#ccastomax"></use>
            </svg>
            <span class="price">{{config.getSetting('CastoramaFidelite.PayCardSubscriptionPrice')}}€/an</span>
          </div>
          <div class="description-container">
            <p>Profitez d'un max d'avantages avec la carte C-Casto Max !</p>
            <p class="primary">Offre de bienvenue : 5% le jour de votre choix</p>
            <div class="avantages">
              <span>
                <svg>
                  <use xlink:href="assets/img/icons.svg#icon-check-fill"></use>
                </svg>
                <span>Remises spéciales toute l'année</span>
              </span>
              <span>
                <svg>
                  <use xlink:href="assets/img/icons.svg#icon-check-fill"></use>
                </svg>
                <span>Garanties et services exclusifs</span>
              </span>
            </div>
          </div>
          <div class="button-container">
            <ng-casto-btn class="blue" label="Passer sur la C-Casto-Max" (click)="goToUpgradeCard()"></ng-casto-btn>
            <a (click)="openModalCardInfo()" class="catso-link">Plus d'informations</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h1 class="title mt-4"><span class="icon"></span>{{ images?.promotionalImages.title }}</h1>
  <div class="container-fluid">
    <div class="row">
      <div class="home-block">
        <div class="home-item">
          <div>
            <div class="row">
              <div class="col-md mb-3" *ngFor="let image of images?.promotionalImages?.images">
                <a [href]="image.action" target="_blank">
                  <img [src]="image.url" class="d-block w-100">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 home-block">
        <div class="form-card">
          <h2 class="form-title"><span class="icon"></span>En savoir plus</h2>

          <div class="institutional-block">
            <div class="help-block" (click)="goToItem('cgu')">
              <p>Conditions générales d'utilisation</p>
            </div>
            <div class="help-block" (click)="goToItem('faq')">
              <p>FAQ</p>
            </div>
            <div class="help-block" (click)="goToItem('contact')">
              <p>Nous contacter</p>
            </div>
            <div class="help-block" (click)="goToItem('cookies')">
              <p>Données personnelles & cookies</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="main-container customer" *ngIf="globalLoading">
  <div class="text-center">
    <ng-casto-loader></ng-casto-loader>
  </div>
</div>
