<ng-container [ngSwitch]="modalService.currentId">
  <ng-casto-logout *ngSwitchCase="MODAL.LOGOUT"></ng-casto-logout>
  <ng-casto-order-detail class="content" *ngSwitchCase="MODAL.ORDER_DETAILS"></ng-casto-order-detail>
  <ng-casto-link-ticket class="content" *ngSwitchCase="MODAL.LINK_TICKET"></ng-casto-link-ticket>
  <ng-casto-search-product class="content" *ngSwitchCase="MODAL.SEARCH_PRODUCT"></ng-casto-search-product>
  <ng-casto-points-adjustment class="content" *ngSwitchCase="MODAL.POINTS_ADJUSTMENT"></ng-casto-points-adjustment>
  <ng-casto-card-status class="content" *ngSwitchCase="MODAL.CARD_STATUS"></ng-casto-card-status>
  <ng-casto-change-password class="content" *ngSwitchCase="MODAL.CHANGE_PASSWORD"></ng-casto-change-password>
  <ng-casto-payment-method class="content" *ngSwitchCase="MODAL.PAYMENT_METHOD"></ng-casto-payment-method>
  <ng-casto-resend-payment-link-sms class="content" *ngSwitchCase="MODAL.RESEND_PAYMENT_LINK_SMS"></ng-casto-resend-payment-link-sms>
  <ng-casto-cancel-card-confirmation class="content" *ngSwitchCase="MODAL.CANCEL_CARD_CONFIRMATION"></ng-casto-cancel-card-confirmation>
  <ng-casto-activate-card-confirmation class="content" *ngSwitchCase="MODAL.ACTIVATE_CARD_CONFIRMATION"></ng-casto-activate-card-confirmation>
  <ng-casto-success class="content" *ngSwitchCase="MODAL.SUCCESS"></ng-casto-success>
  <ng-casto-vouchers class="content" *ngSwitchCase="MODAL.VOUCHERS"></ng-casto-vouchers>
  <ng-casto-card-infos class="content small" *ngSwitchCase="MODAL.CARD_INFOS"></ng-casto-card-infos>
  <ng-casto-display-barcode class="content" *ngSwitchCase="MODAL.DISPLAY_BARCODE"></ng-casto-display-barcode>
  <ng-casto-warning-email-with-card class="content" *ngSwitchCase="MODAL.WARNING_EMAIL_WITH_CARD"></ng-casto-warning-email-with-card>
  <ng-casto-validate-attach-card class="content" *ngSwitchCase="MODAL.VALIDATE_ATTACH_CARD"></ng-casto-validate-attach-card>
  <ng-casto-get-personal-informations class="content" *ngSwitchCase="MODAL.GET_PERSONAL_INFORMATIONS"></ng-casto-get-personal-informations>
  <ng-casto-generic-popup class="content" *ngSwitchCase="MODAL.GENERIC_POPUP"></ng-casto-generic-popup>
</ng-container>
<ng-casto-loader></ng-casto-loader>
