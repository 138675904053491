<div class="main-container customer">
    <div class="container">
      <div class="form-card">
        <h2 class="form-title">
          <span class="icon"></span>
          Mes récompenses fidélités
        </h2>
        <ng-casto-vouchers [customerId]="this.customerId" [context]="context"></ng-casto-vouchers>
      </div>
    </div>
  </div>
