import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ng-casto-common-create-card-communication-preferences',
  templateUrl: './communication-preferences.component.html'
})
export class CreateCardCommunicationPreferencesComponent {
  @Input() marketingConsentForm: FormGroup;

  constructor() {}
}
