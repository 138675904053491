import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  isLoading: boolean;
  currentId: number = 0;
  currentDatas: any;
  private _returnValue?: any;
  private _onClose?: () => void;

  constructor() { }

  public display(modalId: number, datas: any = null, onClose?: () => void) {
    this.currentId = modalId;
    this.currentDatas = datas;
    this._returnValue = null;
    this._onClose = onClose;
  }

  public close(returnValue?: any) {
    this.currentId = MODAL.NONE;
    this.currentDatas = null;
    this._returnValue = returnValue;
    this._onClose?.();
  }

  public get returnValue(): any {
    return this._returnValue;
  }
}

export enum MODAL {
  NONE,
  LOGOUT,
  ORDER_DETAILS,
  LINK_TICKET,
  SEARCH_PRODUCT,
  POINTS_ADJUSTMENT,
  CARD_STATUS,
  SERVICES,
  CHANGE_PASSWORD,
  PAYMENT_METHOD,
  RESEND_PAYMENT_LINK_SMS,
  CANCEL_CARD_CONFIRMATION,
  ACTIVATE_CARD_CONFIRMATION,
  SUCCESS,
  VOUCHERS,
  CARD_INFOS,
  DISPLAY_BARCODE,
  WARNING_EMAIL_WITH_CARD,
  VALIDATE_ATTACH_CARD,
  GET_PERSONAL_INFORMATIONS,
  GENERIC_POPUP,
  ERROR_DETECTED_ON_KINGFISHER_ACCOUNT,
  ASSIGN_CARD_CANCELED,
  UPDATE_ROLE,
  ADD_ROLE,
  DISABLE_VOUCHER,
  DISABLE_VOUCHER_CONFIRMATION
}
