import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CaptainWalletService {
  constructor() {}

  generateUrl(integration3_id: string, tag: string = 'default'): string{
    return environment.apis.captainWallet.url +
      environment.apis.captainWallet.campaignId +
      '?user[identifier]=' + integration3_id +
      '&channel=' + environment.apis.captainWallet.channel +
      '&tag=' + tag;
  }
}
