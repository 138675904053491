import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CustomerRegistrationSource } from '../application/contracts/customer-registration-source.enum';
import type { UpgradeDowngradeCard } from '../application/contracts/models';
import type { CustomerDto, SmallUpdateCustomerDto, UpdateCustomerDto } from '../../dto-common-website/customers/models';
import type { FidelityCardDto, InfoReturnCreateFidelityCardDto, PointInfoDto } from '../../dto-common-website/fidelity-card/models';
import type { UpdateCustomerMarketingConsentKfDto } from '../../kingfisher-api/models/models';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  apiName = 'Default';
  

  createFidelityCard = (infoNewCustomer: UpdateCustomerDto, registeredToken: string, registrationSource?: CustomerRegistrationSource, storeId?: string, sectorId?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, InfoReturnCreateFidelityCardDto>({
      method: 'POST',
      url: '/api/app/customer/fidelity-card',
      params: { registeredToken, registrationSource, storeId, sectorId },
      body: infoNewCustomer,
    },
    { apiName: this.apiName,...config });
  

  emailCanBeAssigned = (customerEmail: string, customerIdToIgnore?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/customer/email-can-be-assigned',
      params: { customerEmail, customerIdToIgnore },
    },
    { apiName: this.apiName,...config });
  

  forceFreeCard = (infoCustomer: UpgradeDowngradeCard, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/customer/force-free-card',
      body: infoCustomer,
    },
    { apiName: this.apiName,...config });
  

  forgottenPassword = (login: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/customer/forgotten-password',
      params: { login },
    },
    { apiName: this.apiName,...config });
  

  getCustomer = (registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerDto>({
      method: 'GET',
      url: '/api/app/customer/customer',
      params: { registeredToken },
    },
    { apiName: this.apiName,...config });
  

  getFidelityCard = (registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FidelityCardDto>({
      method: 'GET',
      url: '/api/app/customer/fidelity-card',
      params: { registeredToken },
    },
    { apiName: this.apiName,...config });
  

  getPointInfo = (registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PointInfoDto>({
      method: 'GET',
      url: '/api/app/customer/point-info',
      params: { registeredToken },
    },
    { apiName: this.apiName,...config });
  

  renewPremiumCard = (registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/customer/renew-premium-card',
      params: { registeredToken },
    },
    { apiName: this.apiName,...config });
  

  smallUpdateCustomer = (newInfoCustomer: SmallUpdateCustomerDto, registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/customer/small-update-customer',
      params: { registeredToken },
      body: newInfoCustomer,
    },
    { apiName: this.apiName,...config });
  

  updateCustomer = (newInfoCustomer: UpdateCustomerDto, registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/customer/customer',
      params: { registeredToken },
      body: newInfoCustomer,
    },
    { apiName: this.apiName,...config });
  

  updateMarketingConsentCustomer = (newInfoCustomer: UpdateCustomerMarketingConsentKfDto, registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/customer/marketing-consent-customer',
      params: { registeredToken },
      body: newInfoCustomer,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
