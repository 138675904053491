import { mapEnumToOptions } from '@abp/ng.core';

export enum CriticalErrorType_CriticalErrorCode {
  CheetahRegulationPoint = 0,
  CheetahPromotionCartePayanteCheetahKo = 1,
  CheetahPromotionCartePayanteMemberNotFound = 2,
  CheetahPromotionCartePayanteCollegueEchec = 3,
  CheetahPromotionCartePayanteEchec = 4,
}

export const criticalErrorType_CriticalErrorCodeOptions = mapEnumToOptions(CriticalErrorType_CriticalErrorCode);
