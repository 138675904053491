import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from '../../../_services/responsive.service';
import { ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/_services/env.service';
import { CustomerRegistrationSource } from 'src/publicproxy/src/proxy/public/application/contracts/customer-registration-source.enum';

@Component({
  selector: 'ng-casto-customer-login',
  templateUrl: './login.component.html'
})
export class CustomerLoginComponent implements OnInit {

  protected readonly LoginContext = LoginContextEnum;
  contexts: LoginContextEnum[] = [];
  roles: string[] = [];

  constructor(public responsive: ResponsiveService, private route: ActivatedRoute, private envService: EnvService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.envService.visitSource = undefined;
      if (params.source) {
        this.envService.visitSource = CustomerRegistrationSource[params.source.toLowerCase() as keyof typeof CustomerRegistrationSource];
      }

      if (!this.envService.visitSource) {
        this.envService.visitSource = CustomerRegistrationSource.qrcode;
      }

      if (this.envService.visitSource === CustomerRegistrationSource.qrcode) {
        if (params.magasin) {
          this.envService.visitSourceStore = params.magasin;

          if (params.secteur) {
            this.envService.visitSourceSector = params.secteur;
          }
        }
      }
    });

    if (!this.responsive.isMobile) {
      this.contexts = [LoginContextEnum.login, LoginContextEnum.createCard];
    }
  }

  /**
   * Gestion des components affiché
   * @param context
   */
  switchContext(context: LoginContextEnum): void {
    if (this.responsive.isMobile) {
      this.contexts = [context];
    } else {
      if (this.contexts.length === 1 && context === LoginContextEnum.login) {
        this.contexts = [context, LoginContextEnum.createCard];
        return;
      }

      this.contexts.push(context);

      switch (context) {
        case LoginContextEnum.login:
          this.contexts = this.contexts.filter(c => c != LoginContextEnum.forgottenPassword);
          break;

        case LoginContextEnum.forgottenPassword:
          this.contexts = this.contexts.filter(c => c != LoginContextEnum.login);
          break;

        case LoginContextEnum.createCard:
          this.contexts = this.contexts.filter(c => c != LoginContextEnum.registation);
          break;

        case LoginContextEnum.registation:
          this.contexts = [context];
          break;
      }
    }
  }
}

export enum LoginContextEnum {
  login,
  forgottenPassword,
  createCard,
  registation
}
