import { Component, Input, OnInit } from '@angular/core';
import { DisableVoucherEnum, Voucher } from '../../../utils/voucher';
import { MODAL, ModalService } from '../../modal.service';
import { PermissionService } from '@abp/ng.core';

@Component({
  selector: 'ng-casto-voucher',
  templateUrl: './voucher.component.html',
})
export class VoucherComponent implements OnInit {
  @Input() voucher: Voucher;
  now: Date;
  canDisableVoucher: boolean = false;

  constructor(
    private modalService: ModalService,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.now = new Date();
    this.now.setHours(0, 0, 0, 0);

    const canCancelVoucher = this.permissionService.getGrantedPolicy('Divers.CancelCoupon');
    const canBurnVoucher = this.permissionService.getGrantedPolicy('Divers.BurnCoupon');

    this.canDisableVoucher = canCancelVoucher || canBurnVoucher;
  }

  isExpire(dateEnd: Date): boolean {
    dateEnd = new Date(dateEnd);
    dateEnd.setHours(0, 0, 0, 0);
    return dateEnd.getTime() < this.now.getTime();
  }

  printVoucher() {
    this.modalService.display(MODAL.DISPLAY_BARCODE, {
      barcodeValue: this.voucher.code,
      icon: 'assets/img/icons.svg#icon-discount',
      title: 'Coupon de réduction de ' + this.voucher.title,
    });
  }

  disableVoucher() {
    this.modalService.display(MODAL.DISABLE_VOUCHER, {
      title: 'Coupon de réduction de ' + this.voucher.title,
      voucher: this.voucher,
    });
  }
}
