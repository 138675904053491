import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GTM } from '../_services/gtm.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private gtm: GTM, private oAuthService: OAuthService){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone();

    if(req.url.includes('connect/token')){
      this.gtm.trackEvent('clic_event', 'connexion', 'succes', [{userType: "collegue"}]);
    }

    return next.handle(req);
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];