import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { PaytweakCallBackPaymentDto } from '../../dto-common-website/paytweak/models';
import type { GetPaymentLinkPwDto, PaytweakRequestDto, SendPaymentSmsPwDto } from '../../paytweak-api/dto/models';

@Injectable({
  providedIn: 'root',
})
export class PaytweakService {
  apiName = 'Default';
  

  getPaymentLinkPaytweak = (input: PaytweakRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GetPaymentLinkPwDto>({
      method: 'GET',
      url: '/api/app/paytweak/payment-link-paytweak',
      params: { customerId: input.customerId, siret: input.siret, numTvaIntra: input.numTvaIntra, cardNumber: input.cardNumber, firstname: input.firstname, lastname: input.lastname, address: input.address, postalCode: input.postalCode, city: input.city, country: input.country, phone: input.phone, email: input.email },
    },
    { apiName: this.apiName,...config });
  

  getPaymentSmsPaytweak = (input: PaytweakRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SendPaymentSmsPwDto>({
      method: 'GET',
      url: '/api/app/paytweak/payment-sms-paytweak',
      params: { customerId: input.customerId, siret: input.siret, numTvaIntra: input.numTvaIntra, cardNumber: input.cardNumber, firstname: input.firstname, lastname: input.lastname, address: input.address, postalCode: input.postalCode, city: input.city, country: input.country, phone: input.phone, email: input.email },
    },
    { apiName: this.apiName,...config });
  

  receiptPaymentFailedPaytweak = (infoCallBackPaytweak: PaytweakCallBackPaymentDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/paytweak/receipt-payment-failed-paytweak',
      body: infoCallBackPaytweak,
    },
    { apiName: this.apiName,...config });
  

  receiptPaymentValidatePaytweak = (infoCallBackPaytweak: PaytweakCallBackPaymentDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/paytweak/receipt-payment-validate-paytweak',
      body: infoCallBackPaytweak,
    },
    { apiName: this.apiName,...config });
  

  sendPaymentCheckout = (customerId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/paytweak/send-payment-checkout/${customerId}`,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
