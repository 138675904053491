import { mapEnumToOptions } from '@abp/ng.core';

export enum CriticalErrorType_CriticalErrorSystem {
  CastoramaFidelite = 0,
  Kingfisher = 1,
  Cheetah = 2,
  Paytweak = 3,
}

export const criticalErrorType_CriticalErrorSystemOptions = mapEnumToOptions(CriticalErrorType_CriticalErrorSystem);
