import { Component, Input  } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MemberTypeCh } from '@proxy/cheetah/client/enum';
import { Context } from 'src/app/utils/context';

const {required} = Validators;

@Component({
  selector: 'ng-casto-common-create-card-situation',
  templateUrl: './situation.component.html'
})
export class CreateCardSituationComponent {
  @Input() form: FormGroup;
  @Input() context?: Context = Context.Colleague;

  projectsActive: boolean = false;
  MemberTypeCh: typeof MemberTypeCh;

  constructor(){
    this.MemberTypeCh = MemberTypeCh
  }

  isRequired(id: string): boolean {
    return this.form.controls[id].hasValidator(required);
  }

  toggleProjects(): void {
    this.projectsActive = !this.projectsActive;
  }

  isCustomer(): boolean {
    return this.context === Context.Customer;
  }
}
