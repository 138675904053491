import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CardHistoryChDto, PointsHistoryChDto } from '../../cheetah/client/dto/models';
import type { AllGiftCardOfferCouponDto } from '../../dto-common-website/fidelity-card/models';
import type { SearchHistoryDto } from '../../dto-common-website/models';

@Injectable({
  providedIn: 'root',
})
export class FidelityCardService {
  apiName = 'Default';
  

  disabledCard = (registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/fidelity-card/disabled-card',
      params: { registeredToken },
    },
    { apiName: this.apiName,...config });
  

  getAllOfferCard = (registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AllGiftCardOfferCouponDto>({
      method: 'GET',
      url: '/api/app/fidelity-card/offer-card',
      params: { registeredToken },
    },
    { apiName: this.apiName,...config });
  

  getCardHistory = (searchHistory: SearchHistoryDto, registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CardHistoryChDto>>({
      method: 'GET',
      url: '/api/app/fidelity-card/card-history',
      params: { customerId: searchHistory.customerId, pageNumber: searchHistory.pageNumber, resultPerPage: searchHistory.resultPerPage, periodStart: searchHistory.periodStart, periodEnd: searchHistory.periodEnd, registeredToken },
    },
    { apiName: this.apiName,...config });
  

  getPointsHistory = (searchPointsHistory: SearchHistoryDto, registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PointsHistoryChDto>>({
      method: 'GET',
      url: '/api/app/fidelity-card/points-history',
      params: { customerId: searchPointsHistory.customerId, pageNumber: searchPointsHistory.pageNumber, resultPerPage: searchPointsHistory.resultPerPage, periodStart: searchPointsHistory.periodStart, periodEnd: searchPointsHistory.periodEnd, registeredToken },
    },
    { apiName: this.apiName,...config });
  

  reactivatedCard = (registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/fidelity-card/reactivated-card',
      params: { registeredToken },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
