<div class="form-card form-card-large" [formGroup]="marketingConsentForm">
  <h2 class="form-title">
    <span class="icon"></span>
    Préférences de communication
  </h2>
  <div class="form-group mt-3">
    <div class="form-row">
      <div class="form-col">
        <div class="form-check form-switch">
          <label class="form-check-label" for="optinEmail">Email</label>
          <input class="form-check-input"
                 type="checkbox"
                 role="switch"
                 id="optinEmail"
                 formControlName="optinEmail"
                 [defaultChecked]="true">
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <div class="form-check form-switch">
          <label class="form-check-label" for="optinSms">Sms</label>
          <input class="form-check-input"
                 type="checkbox"
                 role="switch"
                 id="optinSms"
                 formControlName="optinSms"
                 [defaultChecked]="false">
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <div class="form-check form-switch">
          <label class="form-check-label" for="optinPhone">Appel</label>
          <input class="form-check-input"
                 type="checkbox"
                 role="switch"
                 id="optinPhone"
                 formControlName="optinPhone"
                 [defaultChecked]="false">
        </div>
      </div>
    </div>
    <div class="form-row form-inline">
      <input type="checkbox"
             id="optoutPostal"
             formControlName="optoutPostal">
      <label for="optoutPostal">
        Je ne souhaite pas recevoir d'offres commerciales par courrier de la part de Castorama
      </label>
    </div>
  </div>
</div>
