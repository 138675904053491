import { Injectable } from '@angular/core';
import { EnvService } from './env.service';

@Injectable()
export class GTM {

  public persistantCustomData: any = {};
  private tracking: boolean;
  private trackLog: boolean;

  constructor(private envService: EnvService) {
    this.tracking = this.envService.trackingEnabled();
    this.trackLog = this.envService.trackLogEnabled();
  }

  public init() { }

  public get dataLayer(): any {
    return (<any>window).dataLayer;
  }

  public trackPage(pageUrl: string, pageTitle: string = 'Castorama Fidélité') {
    let layerData: any = {
      event: 'VPV'
    };

    layerData.pageName = pageUrl;
    layerData.pageTitle = pageTitle;

    this.buildTrackObj(layerData, null, false);
  }

  private _logLayerDataPush(layerData) {
    // let _color = (layerData.event == 'virtual_pageview') ? '#bada55' : '#76c4e2';
    console.log("#######  DATALAYER #######");
    console.table(layerData);
  }

  public trackEvent(eventName: string, category: string, label: string, customDatas?: any) {

    let layerData: any = {
      event: eventName,
      categorie: category,
      libelle: label,
    };

    this.buildTrackObj(layerData, customDatas);
  }

  private buildTrackObj(layerData: any, customDatas?: any, usePersistentData: boolean = true) {
    if (usePersistentData) {
      for (const key in this.persistantCustomData) layerData[key] = this.persistantCustomData[key];
    }

    if (customDatas) {
      for (const key in customDatas) layerData[key] = customDatas[key];
    }

    if (this.trackLog) this._logLayerDataPush(layerData);

    if (this.tracking) {
      this.dataLayer.push(layerData);
    }
  }
}
