<div class="header-container" *ngIf="!isLogin">
  <div class="header-wrapper">
    <input type="checkbox" id="nav-check" [(ngModel)]="isMenuOpen" />

    <div class="logo-nav" [routerLink]="['/']"><img class="brand-logo" src="assets/img/castorama-logo.svg" alt="Castorama Fidelite"></div>

    <div class="nav-btn" *ngIf="!isLogin" >
      <label for="nav-check">
        <span class="burger"></span><span class="burger"></span><span class="burger"></span>
        <span class="times">
          <svg>
            <use xlink:href="assets/img/icons.svg#icon-view-close"></use>
          </svg>
        </span>
      </label>
    </div>

    <nav>
      <ng-container *ngIf="context !== 'customer'">
        <div class="nav-container nav-item" [routerLink]="['/accueil']" [routerLinkActive]="'active'">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-home"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">
            Accueil
          </div>
        </div>

        <div class="nav-container nav-item" [routerLink]="['/clients/creationCarte']" [routerLinkActive]="'active'">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-creation-carte"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">
            Création client
          </div>
        </div>

        <div class="nav-container nav-item" [routerLink]="['/clients/recherche']" [routerLinkActive]="'active'">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-recherche-client"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">
            Recherche client
          </div>
        </div>
        <div class="nav-container nav-item" [routerLink]="['/reporting']" [routerLinkActive]="'active'" *abpPermission="'Rapports.AccesOngletReporting'">
            <div class="nav-icon">
                <div>
                    <svg>
                        <use xlink:href="assets/img/icons.svg#icon-reporting"></use>
                    </svg>
                </div>
            </div>
            <div class="nav-text">
                Reporting
            </div>
        </div>

        <div class="nav-container nav-item" [routerLink]="['/administration']" [routerLinkActive]="'active'" *abpPermission="'OffreFidelite.ParametrageOffreFidelite'">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-gear-header"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">
            Administration
          </div>
        </div>

        <div class="nav-container nav-user">
          <div class="user-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-user"></use>
              </svg>
            </div>
          </div>
          <div class="user-info">
            <div class="user-name">{{connectedUser?.name}} {{connectedUser?.surName}}</div>
            <div class="user-matricule" *ngIf="connectedUser?.impersonatorUserId">Matricule : {{connectedUser?.impersonatorUserId}}</div>
          </div>
          <span class="logout-icon" (click)="askLogout()">
            <div>
              <svg fill="#0078d7">
                <use xlink:href="assets/img/icons.svg#icon-power"></use>
              </svg>
            </div>
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="context === 'customer'">
        <div class="nav-container nav-item nav-mobile" [routerLink]="['/accueil']" [routerLinkActive]="'active'">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-home"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">
            Accueil
          </div>
        </div>
        <div class="nav-container nav-item nav-mobile" [routerLink]="['/informations']" [routerLinkActive]="'active'">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-user-edit"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">
            Mes informations
          </div>
        </div>
        <div class="nav-container nav-item nav-mobile" [routerLink]="['/fidelite']" [routerLinkActive]="'active'">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-discount"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">
            Mes récompenses fidélité
          </div>
        </div>
        <div class="nav-container nav-item nav-mobile" [routerLink]="['/historique']" [routerLinkActive]="'active'">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-archive"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">
            Mon historique d'achats
          </div>
        </div>
        <div class="nav-container nav-item" (click)="navigateTo('https://www.castorama.fr/services/contactez-nous')">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-tel"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">Contactez-nous</div>
        </div>
        <div class="nav-container nav-item" *ngIf="customerInfo" [routerLink]="['/informations']">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-user-customer"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">{{customerInfo.firstName}} {{customerInfo.name}}</div>
        </div>
        <div class="nav-container nav-item" *ngIf="!isLogin" (click)="logout()">
          <div class="nav-icon">
            <div>
              <svg>
                <use xlink:href="assets/img/icons.svg#icon-power-customer"></use>
              </svg>
            </div>
          </div>
          <div class="nav-text">
            Déconnexion
          </div>
        </div>
      </ng-container>
    </nav>
  </div>
</div>

<div class="sidebar-container" #sidebar *ngIf="context === 'customer' && !isLogin && router.url !== '/creation'">
  <div class="sidebar-wrapper">
    <nav>
      <div class="nav-item" [routerLink]="['/accueil']" [routerLinkActive]="'active'">
        <span class="nav-icon">
          <div>
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-home"></use>
            </svg>
          </div>
        </span>
        <span class="nav-text">Accueil</span>
      </div>
      <div class="nav-item" [routerLink]="['/informations']" [routerLinkActive]="'active'">
        <span class="nav-icon">
          <div>
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-user-edit"></use>
            </svg>
          </div>
        </span>
        <span class="nav-text">Mes informations</span>
      </div>
      <div class="nav-item" [routerLink]="['/fidelite']" [routerLinkActive]="'active'">
        <span class="nav-icon">
          <div>
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-discount"></use>
            </svg>
          </div>
        </span>
        <span class="nav-text">Mes récompenses fidélité</span>
      </div>
      <div class="nav-item" [routerLink]="['/historique']" [routerLinkActive]="'active'">
        <span class="nav-icon">
          <div>
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-archive"></use>
            </svg>
          </div>
        </span>
        <span class="nav-text">Mon historique d'achats</span>
      </div>
      <!--div class="nav-item" [routerLink]="['/services']" [routerLinkActive]="'active'">
          <span class="nav-icon">
              <div>
                  <svg>
                      <use xlink:href="assets/img/icons.svg#icon-gear"></use>
                  </svg>
              </div>
          </span>
          <span class="nav-text">Vos services</span>
      </div-->
    </nav>
  </div>
</div>
