import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaytweakRequestDto } from '@proxy/paytweak-api/dto/models';
import { PaytweakService } from '@proxy/user-casto/paytweak';
import { regexPhoneNumber } from 'src/app/utils/constants';
import { ModalService } from '../modal.service';

const { required } = Validators;

@Component({
  selector: 'ng-casto-resend-payment-link-sms',
  templateUrl: './resend-payment-link-sms.component.html'
})
export class ResendPaymentLinkSmsComponent implements OnInit {
  paytweakPayload: PaytweakRequestDto;
  phoneNumberForm: FormGroup;

  loading = false;
  sendSuccess = false;

  constructor(private paytweakService: PaytweakService,
    private formBuilder: FormBuilder,
    public modalService: ModalService) { }

  ngOnInit(): void {
    this.paytweakPayload = this.modalService.currentDatas.paytweakPayload;

    this.phoneNumberForm = this.formBuilder.group({
      phoneNumber: [this.paytweakPayload.phone, [required, Validators.pattern(regexPhoneNumber)]]
    });

    this.phoneNumberForm.get('phoneNumber').valueChanges.subscribe(() => {
      this.sendSuccess = false;
    });
  }

  sendLink() {
    if (this.phoneNumberForm.valid) {
      this.loading = true;
      this.sendSuccess = false;
      this.paytweakPayload.phone = this.phoneNumberForm.get('phoneNumber').value;

      this.paytweakService.getPaymentSmsPaytweak(this.paytweakPayload).subscribe(() => {
        this.loading = false;
        this.sendSuccess = true;
      });
    }
  }
}
