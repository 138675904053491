import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const USER_KEY = 'auth-user';
const TOKEN_KEY = 'auth-token';
const EMAIL_KEY = 'auth-email';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  
  private logged: BehaviorSubject<boolean>;

  constructor() {
    this.logged = new BehaviorSubject<boolean>(false);
  
  }

  clean(): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(USER_KEY);
  }

  public saveToken(token: any): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    token.expirationDate = new Date().getTime() + (token.expires_in * 1000);
    window.sessionStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  }

  public getToken(): any {
    const token = window.sessionStorage.getItem(TOKEN_KEY);
    if (token) {
      return JSON.parse(token);
    } 

    return {};
  }

  public clearEmail(): void {
    window.sessionStorage.removeItem(EMAIL_KEY);
  }

  public saveEmail(email: string): void {
    this.clearEmail();
    window.sessionStorage.setItem(EMAIL_KEY, email);
  }

  public getEmail(): string {
    const email = window.sessionStorage.getItem(EMAIL_KEY);
    if (email) {
      return email;
    }

    return '';
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    this.logged.next(true);
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public isLoggedIn(): boolean {
    const token: any = window.sessionStorage.getItem(TOKEN_KEY);
    const today = new Date();
    if (token && today.getTime() < JSON.parse(token).expirationDate) {
      return true;
    }

    return false;
  }

  getLogged(): Observable<boolean> {
    return this.logged.asObservable();
  }
}