import { CustomerRegistrationSource } from "src/publicproxy/src/proxy/public/application/contracts";

export class EnvService {
    private tracking = false;
    private trackLog = false;
    private public = true;

    private envFileLoaded = false;

    public visitSource?: CustomerRegistrationSource;
    public visitSourceStore?: string;
    public visitSourceSector?: string;

    public trackingEnabled(): boolean {
        return this.envFileLoaded ? this.tracking : false;
    }

    public trackLogEnabled(): boolean {
        return this.envFileLoaded ? this.trackLog : false;
    }

    public isPublic(): boolean {
        return this.envFileLoaded ? this.public : false;
    }
}