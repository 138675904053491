import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { PaymentMethodEnum } from 'src/app/utils/payment-method.enum';
import { PaytweakRequestDto } from '@proxy/paytweak-api/dto';
import { Context } from 'src/app/utils/context';

@Component({
  selector: 'ng-casto-payment-method',
  templateUrl: './payment-method.component.html'
})
export class PaymentMethodComponent implements OnInit {

  protected readonly Context = Context;
  protected readonly PaymentMethodEnum = PaymentMethodEnum;

  context: Context;
  paymentLoading: boolean = false;
  step: number = 1;
  paymentMethod: PaymentMethodEnum;
  paytweakPayload: PaytweakRequestDto;

  constructor(public modalService: ModalService) { }

  ngOnInit(): void {
    this.context = this.modalService.currentDatas.context;
    this.paytweakPayload = this.modalService.currentDatas.paytweakPayload;

    if (this.isCustomer()) {
      document.getElementsByTagName('ng-casto-payment-method')[0].classList.add('directPayment');
      this.paymentMethod = PaymentMethodEnum.DIRECT;
      this.increaseStep();
    }
  }

  choosePayment(type: PaymentMethodEnum) {
    this.paymentMethod = type;
    this.increaseStep();
  }

  increaseStep() {
    this.step = this.step + 1;
  }

  changePaymentMethod() {
    this.step = this.step - 1;
  }

  goToCustomerCard(refresh: boolean) {
    this.modalService.close(refresh);
  }

  isCustomer(): boolean {
    return this.context === Context.Customer;
  }
}
