<header>
  <div class="wrap">
    <div class="text">
      <h1 class="title mt-3">
        <span class="text">Les récompenses fidélité</span>
      </h1>

      <div class="text-center" *ngIf="isLoading">
        <ng-casto-loader></ng-casto-loader>
      </div>

      <div class="vouchers-list" *ngIf="!isLoading">
        <ng-casto-voucher *ngFor="let voucher of getVouchers(true)" [voucher]="voucher"></ng-casto-voucher>

        <div class="no-discount" *ngIf="voucherActives.length === 0">
          <svg>
            <use xlink:href="assets/img/icons.svg#icon-discount"></use>
          </svg>
          <p *ngIf="!isCustomer()">Le client ne possède pas de récompenses actives actuellement !</p>
          <p *ngIf="isCustomer()">Vous ne possédez pas de récompenses actives actuellement !</p>
        </div>
      </div>

      <ng-container *ngIf="voucherInactives.length > 0">
        <h2 class="modal-subtitle mt-5" *ngIf="seeUseVouchers">Les récompenses fidélité déjà utilisées</h2>

        <div class="vouchers-list" *ngIf="seeUseVouchers">
          <ng-casto-voucher *ngFor="let voucher of voucherInactives" [voucher]="voucher"></ng-casto-voucher>
        </div>

        <div class="text-center mt-3">
          <a class="casto-link" (click)="setSeeUseVoucher()">
            <span *ngIf="seeUseVouchers"><svg><use xlink:href="assets/img/icons.svg#icon-carret-up"></use></svg> Masquer les récompenses déjà utilisées</span>
            <span *ngIf="!seeUseVouchers"><svg><use xlink:href="assets/img/icons.svg#icon-carret-down"></use></svg> Voir les récompenses déjà utilisées</span>
          </a>
        </div>
      </ng-container>
    </div>

    <div class="header-actions">
      <button class="close" (click)="modalService.close()" title="Fermer">
        <span class="icon-view-close" aria-hidden="true">
          <svg>
            <use xlink:href="assets/img/icons.svg#icon-view-close"></use>
          </svg>
        </span>
        <span>Fermer</span>
      </button>
    </div>

  </div>
</header>
<div class="main">
</div>
