<header>
  <div class="wrap">
    <div class="text">
      <h1 class="title mt-3">
        <span class="icon">
          <div>
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-shopping"></use>
            </svg>
          </div>
        </span>
        <span class="text">Détail d'achat du {{ this.orderDetail?.orderDate | shortDate }}</span>
      </h1>

      <div>
        <div>
          <p>
            Référence ticket :
            <span class="text-center" id="barcode"></span>
          </p>
          <p>
            Montant : <span>{{ this.orderDetail?.amountTtc | currency : 'EUR' }}</span>
          </p>
        </div>
      </div>
      <div class="product-detail">
        <h4>Détails produits</h4>
        <table class="modal-table">
          <thead>
            <tr>
              <th>EAN</th>
              <th>Nom produit</th>
              <th>Qté</th>
              <th>Prix unitaire</th>
              <th>Prix TTC</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of this.orderDetail?.products">
              <td>{{ item.barCode }}</td>
              <td>{{ item.productName }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.unitPrice | currency : 'EUR' }}</td>
              <td>{{ item.amount | currency : 'EUR' }}</td>
            </tr>
          </tbody>
        </table>
        <p>PRIX TOTAL TTC : {{ this.orderDetail?.amountTtc | currency : 'EUR' }}</p>
      </div>
    </div>
    <div class="header-actions">
      <button class="close" (click)="modalService.close()" title="Fermer">
        <span class="icon-view-close" aria-hidden="true">
          <svg>
            <use xlink:href="assets/img/icons.svg#icon-view-close"></use>
          </svg>
        </span>
        <span>Fermer</span>
      </button>
    </div>
  </div>
</header>
<div class="main">
  <footer class="popin-footer">
    <ng-casto-btn class="outline" label="Fermer" (click)="modalService.close()"></ng-casto-btn>
    <ng-casto-btn class="blue" label="Imprimer" (click)="print()"></ng-casto-btn>
  </footer>
</div>
