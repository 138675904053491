import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnonToken, CreateCustomer, CustomerRessource } from '../utils/kingfisher';

const KF_API = environment.apis.kingfisher?.url;
const clientSecret = environment.apis.kingfisher?.clientSecret;
const clientId = environment.apis.kingfisher?.clientId;
const kfBeginUsername = environment.apis.kingfisher?.beginUsername;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  _kfEnvironmentUrl = environment.apis.kingfisher?.environmentUrl;

  constructor(private http: HttpClient) {
    if (this._kfEnvironmentUrl === 'N/A') {
      this._kfEnvironmentUrl = '';
    }
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(
      KF_API + 'oauth/oauth20/token',
      new URLSearchParams(Object.entries({
        password: password,
        grant_type: 'password',
        client_secret: clientSecret,
        client_id: clientId,
        username: kfBeginUsername + email
      })).toString(),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Atmosphere atmosphere_app_id=' + clientId,
          'x-tenant': 'CAFR'
        }),
        withCredentials: true
      },
    );
  }

  getAnonToken(): Observable<AnonToken> {
    return this.http.post<AnonToken>(
      KF_API + 'oauth/oauth20/token',
      new URLSearchParams(Object.entries({
        password: 'ANON',
        grant_type: 'password',
        client_secret: clientSecret,
        client_id: clientId,
        username: kfBeginUsername + 'ANON'
      })).toString(),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Atmosphere atmosphere_app_id=' + clientId,
          'x-tenant': 'CAFR'
        }),
        withCredentials: true
      }
    )
  }

  getCustomerResources(bearer: string): Observable<CustomerRessource> {
    return this.http.get<CustomerRessource>(
      KF_API + this._kfEnvironmentUrl + 'v2/customers/CAFR/customerResources',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
          'Authorization': 'Bearer ' + bearer,
          'x-tenant': 'CAFR'
        }),
        withCredentials: true
      }
    )
  }

  createNewCustomer(createCustomer: CreateCustomer, bearer: string): Observable<any> {
    return this.http.post(
      KF_API + this._kfEnvironmentUrl + 'v2/customers/CAFR',
      {
        data: {
          type: "customerCreate",
          attributes: createCustomer
        }
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
          'Authorization': 'Bearer ' + bearer,
          'x-tenant': 'CAFR'
        }),
        withCredentials: true
      }
    )
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(
      KF_API + 'oauth/oauth20/signup',
      {
        username,
        email,
        password,
      },
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true
      }
    );
  }

  forgottenPassword(email: string, bearer: string): Observable<any> {
    return this.http.post(
      KF_API + this._kfEnvironmentUrl + 'v2/customers/CAFR/forgottenPassword',
      {
        data: {
          type: 'forgottenPasswordInfo',
          attributes: {
            email: email
          }
        }
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
          'Authorization': 'Bearer ' + bearer,
          'x-tenant': 'CAFR'
        }),
        withCredentials: true
      }
    )
  }

  logout(): Observable<any> {
    return this.http.post(KF_API + 'oauth/oauth20/signout', {}, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true
    });
  }
}
