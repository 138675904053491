<div class="voucher" [ngClass]="{ 'disabled': isExpire(voucher.dateEnd) || !voucher.active }">
  <div class="voucher-title">
    <div class="voucher-title-nb">{{ voucher.title }}</div>
    <div class="voucher-title-description" *ngIf="voucher.label">{{ voucher.label }}</div>
  </div>

  <div class="voucher-description">
    <div class="voucher-description-condition">
      À utiliser en magasin. Voir conditions sur <a href="https://castorama.fr/" target="_blank">castorama.fr</a>.
    </div>
    <div class="voucher-description-expiration" *ngIf="!isExpire(voucher.dateEnd) && voucher.active">
      Expire le <b>{{ (voucher.dateEnd | date: 'dd/MM/YYYY') ?? '-' }}</b>.
    </div>

  </div>

  <div class="voucher-footer">
    <p class="text-danger text-center" *ngIf="voucher.active && isExpire(voucher.dateEnd)">Expiré le {{ (voucher.dateEnd | date: 'dd/MM/YYYY') ?? '-' }} !</p>
    <p class="text-danger text-center" *ngIf="!voucher.active && !voucher.cancelled">Coupon utilisé le {{ (voucher.usedDate | date: 'dd/MM/YYYY') ?? '-' }} !</p>
    <p class="text-danger text-center" *ngIf="!voucher.active && voucher.cancelled">Coupon supprimé !</p>
    <p *ngIf="!isExpire(voucher.dateEnd) && voucher.active">
      <ng-casto-btn [label]="'Afficher le code barre'"
                    class="blue"
                    (click)="printVoucher()"></ng-casto-btn>
    </p>
    <div *ngIf="!isExpire(voucher.dateEnd) && voucher.active && canDisableVoucher">
      <a class="casto-link" (click)="disableVoucher()">
        <span>Désactiver le coupon</span>
      </a>
    </div>
  </div>
</div>
