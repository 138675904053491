import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BooleanParserService {
  constructor() { }

  parseString(string: string): boolean {
    return JSON.parse(string.toLowerCase());
  }
}
