<h1 class="title">
  <span class="icon">
    <div>
      <svg>
        <use xlink:href="assets/img/icons.svg#icon-credit-card"></use>
      </svg>
    </div>
  </span>
  <span class="text">Règlement de la cotisation</span>
  <span class="step">
    {{ step }}/{{ maxStep }}
  </span>
</h1>

<div class="form-card form-card-large" *ngIf="!isCustomer()">
  <h2 class="form-title">
    <span class="icon"></span>
    Choisir le moyen de paiement
  </h2>
  <div class="form-group">
    <div class="form-row selector-row">

      <!-- SMS -->
      <div class="casto-selector card-selector" (click)="choosePayment(PaymentMethod.SMS)">
        <input type="radio" name="paymentType" id="sms" value="sms">
        <label for="sms">
          <div class="icon-visuel">
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-smartphone"></use>
            </svg>
          </div>
          <p class="card-name">Par SMS</p>
          <p>Payer par carte bancaire via un espace sécurisé</p>
        </label>
      </div>

      <!-- QR Code -->
      <div class="casto-selector card-selector" (click)="choosePayment(PaymentMethod.QR_CODE)">
        <input type="radio" name="paymentType" id="qrcode" value="qrcode">
        <label for="qrcode">
          <div class="icon-visuel">
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-smartphone-qr-code"></use>
            </svg>
          </div>
          <p class="card-name">Par QR Code</p>
          <p>Payer par carte bancaire via un espace sécurisé</p>
        </label>
      </div>

      <!-- Caisse -->
      <div class="casto-selector card-selector" (click)="choosePayment(PaymentMethod.CHECKOUT)">
        <input type="radio" name="paymentType" id="checkout" value="checkout">
        <label for="checkout">
          <div class="icon-visuel">
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-caisse"></use>
            </svg>
          </div>
          <p class="card-name">Caisse</p>
          <p>Payer lors du passage en caisse en magasin</p>
        </label>
      </div>
    </div>
  </div>
</div>

<div class="btn-container">
  <ng-casto-btn class="outline"
                [label]="labelCancel"
                (click)="onCancel()"></ng-casto-btn>
  <ng-casto-btn class="blue"
                [disabled]="paymentMethodSelected === null"
                [label]="labelSave"
                (click)="onSave()"></ng-casto-btn>
</div>

