import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ng-casto-common-create-card-terms-conditions',
  templateUrl: './terms-conditions.component.html'
})
export class CreateCardTermsConditionsComponent {
  @Input() form: FormGroup;

  cguUrl = environment.links.cguUrl;
  cgvUrl = environment.links.cgvUrl;

  constructor() { }
}
