import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FidelityCardDto } from '@proxy/dto-common-website/fidelity-card';
import { CustomerService as ColleagueCustomerService } from "@proxy/user-casto/customers";
import { FidelityCardTypeCh } from '@proxy/cheetah/client/enum';
import { PrintService } from 'src/app/_services/print.service';
import { MODAL, ModalService } from 'src/app/modals/modal.service';
import { ConfigStateService } from '@abp/ng.core';
import { CustomerDto } from '@proxy/dto-common-website/customers';
import { Context } from 'src/app/utils/context';
import { CustomerService } from 'src/publicproxy/src/proxy/public/customer';
import { StorageService } from 'src/app/_services/storage.service';
import { CaptainWalletService } from 'src/app/_services/captainwallet.service';
import { PaytweakRequestDto } from '@proxy/paytweak-api/dto';
import { getCompleteAddress } from 'src/app/_helpers/common.helper';

@Component({
  selector: 'ng-casto-common-customer-card-information',
  templateUrl: './customer-card-information.component.html'
})
export class CustomerCardInformationComponent implements OnInit {
  @Input() customerId = '';
  @Input() context: Context = Context.Colleague;
  @Input() cardFidelity: FidelityCardDto;
  @Input() cardEmail: string = null;
  @Input() isReadOnly: boolean = false;

  loading: boolean;
  customerInfos: CustomerDto;
  protected readonly FidelityCardType = FidelityCardTypeCh;

  constructor(
    private colleagueCustomerService: ColleagueCustomerService,
    private customerService: CustomerService,
    private storageService: StorageService,
    private modalService: ModalService,
    private router: Router,
    private print: PrintService,
    private captainWalletService: CaptainWalletService,
    public config: ConfigStateService) { }

  ngOnInit() {
    this.loading = true;

    if (this.cardFidelity == null && this.cardFidelity == undefined) {
      if (this.context === Context.Colleague) {
        this.colleagueCustomerService.getFidelityCard(this.customerId).subscribe((response) => {
          this.cardFidelity = response;
          this.loading = false;
        });
  
        this.colleagueCustomerService.getCustomer(this.customerId).subscribe({
          next: (res) => this.customerInfos = res
        });
      } else {
        this.customerService.getFidelityCard(this.storageService.getToken().access_token).subscribe({
          next: (response) => {
            this.cardFidelity = response;
            this.customerService.getCustomer(this.storageService.getToken().access_token).subscribe({
              next: (res) => this.customerInfos = res
            });
            this.loading = false;
          },
          error: (err) => {
            this.router.navigate(['/creation/']);
          }
        });
      }
    } else {
      this.loading = false;
    }
  }

  goToUpgradeCard() {
    this.router.navigate(['/clients/carte/' + this.customerId + '/modification'], { queryParams: { upgrade: true } });
  }

  activateCard() {
    this.modalService.display(MODAL.ACTIVATE_CARD_CONFIRMATION, { 
      cardNumber: this.cardFidelity?.cardNumber,
      customerId: this.customerId,
      registeredToken: this.isCustomer() ? this.storageService.getToken().access_token : '',
      context: this.context
    });
  }

  displayRenewBtn(): boolean {
    if (!this.cardFidelity || !this.customerInfos) return false;

    let today = new Date();

    //Conversion de la date d'expiration en Date javascript
    let parts = this.cardFidelity.finalExpirationDate.split('/');
    let expirationDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));

    let dateToNotice = new Date();

    //Ajout de la date de notification en fonction du setting
    dateToNotice.setTime(expirationDate.getTime() - (parseInt(this.config.getSetting('CastoramaFidelite.NbDaysNoticeEndSubscriptionPaidCard')) * 24 * 60 * 60 * 1000))

    return today > dateToNotice;
  }

  displayPayCardBtn(): boolean {
    if (!this.cardFidelity || !this.customerInfos) return false;
    if (this.customerInfos.isColleague) return false;

    return this.cardFidelity.type === FidelityCardTypeCh.PremiumWaiting && !this.customerInfos.cardIsPaidButNotActiveYet;
  }

  payCard() {
    this.modalService.display(MODAL.PAYMENT_METHOD, {
      context: this.context,
      paytweakPayload: this.getPaytweakPayload(),
      fidelityCard: this.cardFidelity
    },
    () => {
      if (this.modalService.returnValue) {
        window.location.reload();
      }
    });
  }

  cancelCard() {
    this.modalService.display(MODAL.CANCEL_CARD_CONFIRMATION, {
      cardNumber: this.cardFidelity?.cardNumber,
      customerId: this.customerId,
      context: this.context
    });
  }

  printCard() {
    this.modalService.display(MODAL.DISPLAY_BARCODE, { 
      barcodeValue: this.cardFidelity.cardNumber, 
      icon: 'assets/img/icons.svg#icon-creation-carte',
      title: 'Carte de fidélité C-Casto' + (this.cardFidelity.type !== FidelityCardTypeCh.Gratuite ? ' Max' : '')
    });
  }

  renewCard() {

    if (this.context === Context.Colleague) {
      this.colleagueCustomerService.renewPremiumCard({ customerKfId: this.cardFidelity.customerId }).subscribe({
        next: () => {
          this.modalService.display(MODAL.PAYMENT_METHOD, {
            context: this.context,
            paytweakPayload: this.getPaytweakPayload(),
            fidelityCard: this.cardFidelity
          }, () => window.location.reload());
        }
      });
    } else {
      this.customerService.renewPremiumCard(this.storageService.getToken().access_token).subscribe({
        next: () => {
          this.modalService.display(MODAL.PAYMENT_METHOD, {
            context: this.context,
            paytweakPayload: this.getPaytweakPayload(),
            fidelityCard: this.cardFidelity
          }, () => window.location.reload());
        }
      });
    }
  }

  downgradeCard() {
    this.colleagueCustomerService.forceColleagueFreeCard({ customerKfId: this.cardFidelity.customerId }).subscribe({
      next: () => {
        this.modalService.display(MODAL.SUCCESS, {
          title: 'C-Casto',
          message: 'Vous êtes bien passé sur une carte C-Casto.'
        }, () => window.location.reload());
      }
    });
  }

  isCustomer(): boolean {
    return this.context === Context.Customer;
  }

  public addToWallet(): void {
    window.open(this.captainWalletService.generateUrl(this.cardFidelity.captainWalletUserId, 'home'), '_blank').focus();
  }

  private getPaytweakPayload(): PaytweakRequestDto {
    if (!this.cardFidelity || !this.customerInfos) return undefined;
    
    let dto: PaytweakRequestDto = {
      customerId: this.customerId,
      cardNumber: this.cardFidelity.cardNumber,
      address: getCompleteAddress(this.customerInfos.houseNumber, this.customerInfos.address),
      city: this.customerInfos.city,
      country: this.customerInfos.country,
      email: this.customerInfos.mail,
      phone: this.customerInfos.phoneNumber,
      firstname: this.customerInfos.firstName,
      lastname: this.customerInfos.name,
      postalCode: this.customerInfos.postalCode
    };

    return dto;
  }
}
