import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as JsBarcode from 'jsbarcode';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  printContainer: HTMLDivElement;

  constructor(private titleService: Title) {
    this.printContainer = document.createElement('div');
    this.printContainer.className = 'print-container container-fluid';
    window.document.body.appendChild(this.printContainer);
  }

  hasContent(): boolean {
    return this.printContainer.innerHTML != '';
  }

  reset() {
    this.printContainer.innerHTML = '';
  }

  addImage(src: string): Promise<void> {
    let _i = new Image();
    _i.className = 'img-fluid';
    this.printContainer.appendChild(_i);

    return new Promise((resolve, reject) => {
      _i.onload = (e: any) => {
        resolve();
      };
      _i.src = src;
    });

  }

  addText(text: string, style: string) {
    let _p = document.createElement('p');
    _p.innerHTML = text;
    _p.className = style || '';
    this.printContainer.appendChild(_p);
  }

  addDiv(div: HTMLDivElement): Promise<void> {
    this.printContainer.appendChild(div);
    return new Promise((resolve, reject) => {
      let _imgs = div.querySelectorAll("img");

      if (_imgs.length > 0) {

        let _imgToLoadCount = _imgs.length;
        let _imgLoaded = 0;

        for (let i = 0; i < _imgToLoadCount; i++) {
          const _img: HTMLImageElement = _imgs[i];

          if (!_img.complete) {
            _img.onload = (e: any) => {
              _imgLoaded++;
              if (_imgLoaded === _imgToLoadCount) resolve();
            };
            _img.onerror = (e: any) => {
              _imgLoaded++;
              if (_imgLoaded === _imgToLoadCount) resolve();
            };
          } else {
            _imgLoaded++;
            if (_imgLoaded === _imgToLoadCount) resolve();
          }
        }
      } else {
        resolve();
      }
    });
  }

  addTitleRow(level: number, text: string) {
    let _t = document.createElement('h' + level);
    _t.innerHTML = text;
    _t.className = 'bg-blue';
    this.printContainer.appendChild(_t);
  }

  addDom(dom: HTMLElement): void {
    const clone = new DOMParser()
      .parseFromString(dom.innerHTML, 'text/html')
      .children
      .item(0)
      .getElementsByTagName('body')
      .item(0)
      .firstChild;
    this.printContainer.appendChild(clone);
  }

  print(specificName: string = undefined) {
    let oldTitle = this.titleService.getTitle();
    if (specificName != undefined) {
      this.titleService.setTitle(specificName);
    }
    window.print();
    if (specificName != undefined) {
      this.titleService.setTitle(oldTitle);
    }
  }

  printBarcode(title: string, value: number | string, img?: string) {
    return new Promise(() => {
      setTimeout(() => {
          let popupWin = window.open("", "_blank", "top=0,left=0,height=0,width=auto");
          popupWin.document.open();
          popupWin.document.write(`
                  <html>
                    <head>
                    <style scoped>
                    .print-container {
                      display: block;
                      text-align: center;
                      background-color: white;
                      border: 1px solid $light-blue;
                      border-radius: $border-radius;
                      padding: 1.5em;
                    }
                    .no-print {
                      text-align: center;
                      align-items: center;
                      width: 100vw;
                      height:100vh;
                    }
                    .heading-print {
                      font-family: 'Casto Pro Bold';
                      background-color:#e6e6e6;
                      font-size: 30px;
                      font-weight: bold;
                      color: #20407f;
                    }
                    .display-tbl {
                      border-collapse: collapse;
                      table-layout: fixed;
                      width: 100%;
                      height:100%;
                      .tr {
                        align-items: center;
                        width: 100%;
                        .td {
                          text-align: center;
                          width: 100%;
                        }
                      }
                    }
                   .print{
                      display: none;
                    }
                    @media print {
                        .print {
                          display: block;
                        }
                        .no-print {
                          display: none;
                        }
                    }
                  </style>
                    </head>
                    <body>
                      <div class="no-print heading-print">
                        <table class="display-tbl">
                        <tr>
                           <td><h1 style="text-align: center; font-size: 30px; font-weight: bold;">Cliquer/Toucher pour fermer</h1></td>
                        </tr>
                        </table>
                      </div>
                      <div class="print print-container">
                        <h1 class="bg-blue">${title}</h1>
                        ${img ? `<img class="img-fluid" src="${img}">` : ''}
                        <div>
                          ${this.createBarCode(value)}
                        </div>
                      </div>
                      <script defer>
                        function triggerPrint(event) {
                          window.removeEventListener('load', triggerPrint, false);
                          setTimeout(function() {
                            closeWindow(window.print());
                          }, ${1000});
                        }
                        function closeWindow(){}
                        function winclose(){
                          window.close();
                        };
                        window.document.body.addEventListener('click', winclose, true);
                        window.document.body.addEventListener('touchstart', winclose, true);
                        window.document.body.addEventListener('touchmove', winclose, true);
                        window.document.body.addEventListener('touchcancel', winclose, true);
                        window.addEventListener('load', triggerPrint, false);
                      </script>
                    </body>
                  </html>`);
          popupWin.document.close();
        }
      , 0);
    });
  }

  createBarCode(value: number | string): string {
    const xmlSerializer = new XMLSerializer();
    const svgNode = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    
    JsBarcode(svgNode, `${value}`, {
        xmlDocument: document,
        height: 50,
        width: 2.3,
        text: '' + value,
        font: 'Casto Pro Bold',
        fontOptions: 'bold',
        fontSize: 25,
        lineColor: '#000000',
        margin: 20,
        textMargin: 5,
        textAlign: 'center',
        textPosition: 'top'
    });
    
    return xmlSerializer.serializeToString(svgNode);
  }
}
