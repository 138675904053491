<div class="form-card form-card-large"
     [formGroup]="form"
     *ngIf="booleanParser.parseString(config.getSetting('CastoramaFidelite.ActivatedPayingCardCreation'))
            || booleanParser.parseString(config.getSetting('CastoramaFidelite.ActivatedFreeCardCreation'))">
  <h2 class="form-title">
    <span class="icon">
      <!--svg>
        <use xlink:href="assets/img/icons.svg#icon-credit-card"></use>
      </svg-->
    </span>
    Choisissez une carte
  </h2>
  <div class="form-group">
    <div class="form-row selector-row">
      <!-- Premium -->
      <div class="casto-selector card-selector"
           *ngIf="booleanParser.parseString(config.getSetting('CastoramaFidelite.ActivatedPayingCardCreation'))">
        <input type="radio"
               name="cardIsPremium"
               id="premium"
               formControlName="cardIsPremium"
               [value]="true">
        <label for="premium">
          <div class="card-visuel">
            <svg>
              <use xlink:href="assets/img/icons.svg#ccastomax"></use>
            </svg>
            <span class="price">{{config.getSetting('CastoramaFidelite.PayCardSubscriptionPrice')}}€/an</span>
          </div>
          <p class="card-name">Carte C-Casto Max</p>
          <p>Un Max d'avantages : 5 % de bienvenue, 10 % tous les 1 500 points (1 € = 1 point) et bien plus encore !</p>
          <a class="casto-link"
             (click)="seeAdvantages(FidelityCardTypeCh.Premium)"
             *ngIf="!responsive.isMobile">Voir les avantages</a>
          <a class="casto-link"
             (click)="selectCardType(FidelityCardTypeCh.Premium)"
             *ngIf="responsive.isMobile">En savoir plus</a>
        </label>
      </div>

      <!-- Free -->
      <div class="casto-selector card-selector"
           *ngIf="booleanParser.parseString(config.getSetting('CastoramaFidelite.ActivatedFreeCardCreation')) && !isUpgrade">
        <input type="radio"
               name="cardIsPremium"
               id="free"
               formControlName="cardIsPremium"
               [value]="false">
        <label for="free">
          <div class="card-visuel">
            <svg>
              <use xlink:href="assets/img/icons.svg#ccasto"></use>
            </svg>
            <span class="price">Gratuite</span>
          </div>
          <p class="card-name">Carte C-Casto</p>
          <p>5€ tous les 300 points (1€=1 point), Retour facile et historique d'achats en 1 clic !</p>
          <a class="casto-link"
             (click)="seeAdvantages(FidelityCardTypeCh.Gratuite)"
             *ngIf="!responsive.isMobile">Voir les avantages</a>
          <a class="casto-link"
             (click)="selectCardType(FidelityCardTypeCh.Gratuite)"
             *ngIf="responsive.isMobile">En savoir plus</a>
        </label>
      </div>
    </div>
  </div>

  <ng-casto-card-infos *ngIf="responsive.isMobile && type !== null"
                       [type]="type"></ng-casto-card-infos>
</div>
