import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ActivityPurchaseChDto } from '../../cheetah/client/dto/models';
import type { SearchHistoryDto } from '../../dto-common-website/models';
import type { GetPreciseOrderDto, OrderDetailsDto } from '../../dto-common-website/order-history/models';

@Injectable({
  providedIn: 'root',
})
export class OrderHistoryService {
  apiName = 'Default';
  

  getOrderDetails = (getPreciseOrder: GetPreciseOrderDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderDetailsDto>({
      method: 'GET',
      url: '/api/app/order-history/order-details',
      params: { customerId: getPreciseOrder.customerId, orderId: getPreciseOrder.orderId },
    },
    { apiName: this.apiName,...config });
  

  getOrderHistory = (searchOrderData: SearchHistoryDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ActivityPurchaseChDto>>({
      method: 'GET',
      url: '/api/app/order-history/order-history',
      params: { customerId: searchOrderData.customerId, pageNumber: searchOrderData.pageNumber, resultPerPage: searchOrderData.resultPerPage, periodStart: searchOrderData.periodStart, periodEnd: searchOrderData.periodEnd },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
