import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { OrderHistoryService as ColleagueOrderHistoryService } from '@proxy/user-casto/order-history';
import { OrderHistoryService } from 'src/publicproxy/src/proxy/public/order-history';
import { OrderDetailsDto } from '@proxy/dto-common-website/order-history';
import { PrintService } from '../../_services/print.service';
import { Context } from 'src/app/utils/context';

@Component({
  selector: 'ng-casto-order-detail',
  templateUrl: './order-detail.component.html'
})
export class OrderDetailComponent implements OnInit {
  orderId: string;
  customerId: string;
  token: string;
  context: Context;

  orderDetail: OrderDetailsDto;
  loading: boolean = false;

  constructor(
      public modalService:ModalService,
      private orderHistoryService: OrderHistoryService,
      private colleagueOrderHistoryService: ColleagueOrderHistoryService,
      private printService: PrintService) {}

  ngOnInit(): void {
    this.orderId = this.modalService.currentDatas.orderId;
    this.customerId = this.modalService.currentDatas.customerId;
    this.token = this.modalService.currentDatas.registeredToken;
    this.context = this.modalService.currentDatas.context;

    switch(this.context){
      case Context.Colleague:
        this.colleagueOrderHistoryService.getOrderDetails({
          customerId: this.customerId,
          orderId: this.orderId
        }).subscribe(response => {
          this.orderDetail = response;
          const div = document.createElement("div");
          div.innerHTML = this.printService.createBarCode(this.orderDetail?.ticketNumber);
          document.getElementById('barcode').appendChild(div);
        });
        break;
      case Context.Customer:
        this.orderHistoryService.getOrderDetails({
          customerId: this.customerId,
          orderId: this.orderId
        }, this.token).subscribe(response => {
          this.orderDetail = response;
        });
        break;
    }
  }

  print() {
    const content = document
      .getElementsByTagName('ng-casto-order-detail')
      .item(0) as HTMLElement;

    this.printService.addDom(content);
    this.printService.print();
    this.printService.reset();
  }
}
