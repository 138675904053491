<div class="main-container customer">
  <form [formGroup]="personalInformationsForm" *ngIf="!loading">
    <div class="container-fluid">
      <div class="row">
        <div>
          <ng-casto-common-create-card-personal-informations [isEmailSearched]="isEmailSearched"
                                                             [form]="personalInformationsForm"
                                                             [btnTemplate]="btnTemplate">
            <ng-template #btnTemplate>
              <div class="text-center">
                <ng-casto-btn class="blue"
                              [disabled]="personalInformationsForm.invalid"
                              [label]="'Enregistrer'"
                              [loading]="saveLoading"
                              (click)="submitForm()"></ng-casto-btn>
              </div>
            </ng-template>
          </ng-casto-common-create-card-personal-informations>
        </div>
        <div>
          <ng-casto-common-create-card-communication-preferences [marketingConsentForm]="personalInformationsForm.get('marketingConsent')"></ng-casto-common-create-card-communication-preferences>

          <div class="form-card">
            <h2 class="form-title">
              <span class="icon"></span>
              Mot de passe
            </h2>
            <div class="password-container">
              <span>Mot de passe</span>
              <span class="password">********</span>
            </div>
            <div class="btn-container">
              <ng-casto-btn label="Modifier le mot de passe" class="blue" (click)="openPasswdModal()"></ng-casto-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="text-center" *ngIf="loading">
    <ng-casto-loader></ng-casto-loader>
  </div>
</div>
