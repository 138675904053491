import { mapEnumToOptions } from '@abp/ng.core';

export enum ActivityTypePointCh {
  points_regulation = 0,
  sl_purchase = 1,
  sl_reward = 2,
  other = 3,
  retour = 4,
}

export const activityTypePointChOptions = mapEnumToOptions(ActivityTypePointCh);
