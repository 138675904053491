import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/customer/home/home.component';
import { CreateCardComponent } from './pages/customer/create-card/create-card.component';
import { CustomerInformationsComponent } from './pages/customer/customer-informations/customer-informations.component';
import { CustomerHistoryComponent } from './pages/customer/customer-history/customer-history.component';
import { CustomerLoginComponent } from './pages/customer/login/login.component';
import { AuthGuardService } from './_services/auth-guard.service';
import { CustomerVouchersComponent } from './pages/customer/customer-vouchers/customer-vouchers.component';
import { TITLES } from './page-titles';

const routes: Routes = [
  {
    path: 'connexion',
    pathMatch: 'full',
    component: CustomerLoginComponent,
    title: TITLES.CONNEXION
  },
  {
    path: 'accueil',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    title: TITLES.ACCUEIL
  },
  {
    path: 'creation',
    pathMatch: 'full',
    component: CreateCardComponent,
    canActivate: [AuthGuardService],
    title: TITLES.CREATION
  },
  {
    path: 'informations',
    pathMatch: 'full',
    component: CustomerInformationsComponent,
    canActivate: [AuthGuardService],
    title: TITLES.INFORMATIONS
  },
  {
    path: 'historique',
    pathMatch: 'full',
    component: CustomerHistoryComponent,
    canActivate: [AuthGuardService],
    title: TITLES.HISTORIQUE
  },
  {
    path: 'fidelite',
    pathMatch: 'full',
    component: CustomerVouchersComponent,
    canActivate: [AuthGuardService],
    title: TITLES.COUPONS
  },
  {
    path: '',
    redirectTo: '/accueil',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/accueil'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
