import { Component, Input } from '@angular/core';

@Component({
  selector: 'ng-casto-customer-offer',
  templateUrl: './offer.component.html'
})
export class OfferComponent {
  @Input() img: string;
  @Input() action: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() description: string;

  constructor() {}

  goToUrl(){
    window.open(this.action, '_blank').focus();
  }
}
