import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { CustomerService } from 'src/publicproxy/src/proxy/public/customer';
import { AuthService } from '../../../../_services/auth.service';
import { GTM } from '../../../../_services/gtm.service';
import { StorageService } from '../../../../_services/storage.service';
import { CreateCustomer } from '../../../../utils/kingfisher';
import { LoginContextEnum } from '../login.component';

@Component({
  selector: 'ng-casto-customer-registration',
  templateUrl: './registration.component.html'
})
export class CustomerRegistrationComponent implements OnInit {
  @Output() switchContext = new EventEmitter<LoginContextEnum>();

  form: FormGroup;
  errorMessage: string = null;
  loading = false;
  showPasswordCreate: boolean = false;
  showPasswordConfirm: boolean = false;

  protected readonly LoginContext = LoginContextEnum;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private customerService: CustomerService,
    private storageService: StorageService,
    private gtm: GTM) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      givenName: [null, [Validators.required, Validators.min(2)]],
      familyName: [null, [Validators.required, Validators.min(2)]],
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9$@$!%*?&].{12,}'), Validators.required]],
      confirmationPassword: [null]
    }, { validators: this.checkPasswords });
  }

  checkIfPasswordContainLowercase(): boolean {
    const password = this.form.get('password').value;
    if (password) {
      const match = RegExp(/(?=.*[a-z])/).exec(password);
      return match && match.length > 0;
    }
    return false;
  }

  checkIfPasswordContainUppercase(): boolean {
    const password = this.form.get('password').value;
    if (password) {
      const match = RegExp(/(?=.*[A-Z])/).exec(password);
      return match && match.length > 0;
    }
    return false;
  }

  checkIfPasswordContainNumber(): boolean {
    const password = this.form.get('password').value;
    if (password) {
      const match = RegExp(/(?=.*\d)/).exec(password);
      return match && match.length > 0;
    }
    return false;
  }

  checkIfPasswordContainSpecialCharacter(): boolean {
    const password = this.form.get('password').value;
    if (password) {
      const match = RegExp(/(?=.*[$@!%*?&])/).exec(password);
      return match && match.length > 0;
    }
    return false;
  }

  checkIfPasswordHasMinLength(): boolean {
    const password = this.form.get('password').value;
    if (password) {
      return password.length > 12;
    }
    return false;
  }

  checkIfPasswordsHasSame(): boolean {
    if (!this.form.get('password').value || !this.form.get('confirmationPassword').value) {
      return false;
    }

    return this.form.get('password').value === this.form.get('confirmationPassword').value;
  }

  onSwitchContext(loginContext: LoginContextEnum) {
    this.errorMessage = null;

    this.switchContext.emit(loginContext);
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let password = group.get('password').value;
    let confirmationPassword = group.get('confirmationPassword').value
    return password === confirmationPassword ? null : { notSame: true }
  }

  async createCard() {
    if (this.form.valid) {
      this.errorMessage = '';
      this.loading = true;
      const email = this.form.controls['email'].value;
      const customer = this.form.value as CreateCustomer;

      const date = new Date().toISOString().replace('Z', '');
      customer.marketingChannels = [
        { channel: "sms", optIn: false, submitted: date, privacyPolicyVersionId: "1", marketingChannelVersionId: "2" },
        { channel: "phone", optIn: false, submitted: date, privacyPolicyVersionId: "1", marketingChannelVersionId: "2" },
        { channel: "email", optIn: false, submitted: date, privacyPolicyVersionId: "1", marketingChannelVersionId: "2" },
        { channel: "post", optIn: false, submitted: date, privacyPolicyVersionId: "1", marketingChannelVersionId: "2" }
      ];
      delete customer['confirmationPassword'];

      // Si l'adresse email est déjà utilisé ou non
      const emailCanBeAssigned: boolean | void = await lastValueFrom(this.customerService.emailCanBeAssigned(email))
        .catch(async error => {
          this.loading = false;
          console.error(error);
        });

      if (emailCanBeAssigned === true) {
        const anonToken = await lastValueFrom(this.authService.getAnonToken());
        await lastValueFrom(this.authService.createNewCustomer(customer, anonToken.access_token));
        const infoTokenConnect = await lastValueFrom(this.authService.login(email, customer.password));
        this.storageService.saveToken(infoTokenConnect);

        this.authService.getCustomerResources(infoTokenConnect.access_token).subscribe({
          next: userData => {
            this.storageService.saveUser(userData.data.attributes.profileId);
            this.gtm.trackEvent('clic_event', 'connexion', 'succes', [{ userType: 'client' }]);
            this.router.navigate(['/accueil']).then(() => this.loading = false);
          },
          error: err => {
            this.gtm.trackEvent('clic_event', 'connexion', 'echec', [{ userType: 'client' }]);
            this.errorMessage = 'Adresse e-mail ou mot de passe incorrect.';
            this.loading = false;
          }
        });
      } else {
        // Si l'adresse mail recherchée existe et a déjà une carte
        this.errorMessage = 'Un compte avec cette adresse mail existe déjà, veuillez vous connecter.';
        this.loading = false;
      }
    }
  }
}
