import { Component, HostBinding } from '@angular/core';
import { MODAL, ModalService } from './modal.service';

@Component({
  selector: 'ng-casto-modals',
  templateUrl: './modals.component.html'
})
export class ModalsComponent {

  @HostBinding('class.open') get isOpen(){ return this.modalService.currentId > MODAL.NONE; }
  @HostBinding('class.loading') get isLoading(){ return this.modalService.isLoading; }

  constructor(public modalService:ModalService) { }

  MODAL: typeof MODAL = MODAL;
}
