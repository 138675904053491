import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'ng-casto-success',
  templateUrl: './success.component.html'
})
export class SuccessComponent implements OnInit {

  title: string;
  message: string;

  constructor(
    public modalService:ModalService) {}

  ngOnInit(): void {
    this.title = this.modalService.currentDatas.title;
    this.message = this.modalService.currentDatas.message;
  }
}
