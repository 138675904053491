import { Component, Input, OnInit } from '@angular/core';
import { Voucher } from '../../../utils/voucher';
import { MODAL, ModalService } from '../../modal.service';

@Component({
  selector: 'ng-casto-voucher',
  templateUrl: './voucher.component.html'
})
export class VoucherComponent implements OnInit {
  @Input() voucher: Voucher;
  now: Date;

  constructor(private modalService: ModalService) { }

  ngOnInit() {
    this.now = new Date();
    this.now.setHours(0, 0, 0, 0);
  }

  isExpire(dateEnd: Date): boolean {
    dateEnd = new Date(dateEnd);
    dateEnd.setHours(0, 0, 0, 0);
    return dateEnd.getTime() < this.now.getTime();
  }

  printVoucher() {
    this.modalService.display(MODAL.DISPLAY_BARCODE, {
      barcodeValue: this.voucher.code,
      icon: 'assets/img/icons.svg#icon-discount',
      title: 'Coupon de réduction de ' + this.voucher.title
    });
  }
}
