<div class="form-card form-card-large" *ngIf="form" [formGroup]="form" [ngClass]="{disabled: form.value?.cardType === '' || form.value?.cardType === 2}">
  <h2 class="form-title">
    <span class="icon"></span>
    <span *ngIf="context !== customerContext">Votre client est</span>
    <span *ngIf="context === customerContext">Vous êtes</span>
  </h2>
  <div class="form-group">
    <div class="form-row selector-row">
      <div class="casto-selector">
        <input type="radio"
               name="memberType"
               formControlName="memberType"
               id="particulier"
               [value]="MemberTypeCh.Particulier">
        <label for="particulier">
          <img src="assets/img/particulier.png" alt="">
          <p>Particulier</p>
        </label>
      </div>
      <div class="casto-selector">
        <input type="radio"
               name="memberType"
               formControlName="memberType"
               id="professionnel"
               [value]="MemberTypeCh.Professionnel">
        <label for="professionnel">
          <img src="assets/img/professionnel.png" alt="">
          <p>Professionnel</p>
        </label>
      </div>
    </div>
  </div>

  <ng-container *ngIf="form.get('memberType').value === MemberTypeCh.Particulier && context !== customerContext">
    <div class="form-group">
      <div *abpPermission="'GestionClients.CreationCarteCollegue'">
        <div class="form-row form-inline">
          <input type="checkbox"
                name="isColleague"
                formControlName="isColleague"
                id="isColleague"
                value="isColleague" />
          <label for="isColleague">
            <p>Le client est collègue Castorama</p>
          </label>
        </div>

        <div class="form-row form-inline" *ngIf="form.get('colleagueMatricule') && form.get('isColleague').value">
          <div class="form-col">
            <label for="matricule" class="required">Matricule du collègue</label>
            <input class="casto-input"
                  type="text"
                  name="matricule"
                  id="matricule"
                  formControlName="colleagueMatricule">
            <small
              class="error"
              *ngIf="form.controls['colleagueMatricule'].invalid
                  && form.controls['colleagueMatricule'].dirty
                  && form.controls['colleagueMatricule'].touched">
              Le matricule doit être composé de 7 caractères.
           </small>
          </div>
        </div>
      </div>

      <div class="form-row form-inline" *ngIf="form.get('cardIsPremium').value && existingCardStatus === FidelityCardTypeCh.Premium">
        <input type="checkbox" name="newMoveIn" formControlName="newMoveIn" id="newMoveIn" value="newMoveIn" />
        <label for="newMoveIn">
          <p>Le client est nouvel emménagé</p>
        </label>
      </div>

      <div class="form-row form-inline"
           *ngIf="form.get('memberType').value === MemberTypeCh.Particulier && form.get('cardIsPremium').value && form.get('newMoveIn').value">
        <label class="required" for="lastMoveInDate">Date de déménagement</label>
        <select class="casto-input"
                name="lastMoveInDate"
                id="lastMoveInDate"
                formControlName="lastMoveInDate">
          <option *ngFor="let option of newMoveInOptions"
                  [value]="option.value">{{ option.text }}</option>
        </select>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('siret') && form.get('memberType').value === MemberTypeCh.Professionnel">
    <div class="form-group">
      <div class="form-row form-inline">
        <div class="form-col">
          <label for="siret" class="required">SIRET</label>
          <input class="casto-input"
                type="text"
                name="siret"
                id="siret"
                formControlName="siret">
        </div>
      </div>

      <div class="form-row form-inline">
        <div class="form-col">
          <label for="numtva" class="required">N° TVA intracommunautaire</label>
          <input class="casto-input"
                type="text"
                name="numtva"
                id="numtva"
                formControlName="nTvaIntracommunautaire">
        </div>
      </div>
    </div>
  </ng-container>
</div>
