import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { PrintService } from '../../_services/print.service';

@Component({
    selector: 'ng-casto-display-barcode',
    templateUrl: './display-barcode.component.html'
})
export class DisplayBarcodeComponent implements OnInit {
    barcodeValue: string;
    title: string;
    icon: string;

    constructor(
        public modalService: ModalService,
        private printService: PrintService) { }

    ngOnInit(): void {
        this.barcodeValue = this.modalService.currentDatas.barcodeValue;
        this.icon = this.modalService.currentDatas.icon;
        this.title = this.modalService.currentDatas.title;

        const iconEl = document.getElementById('icon');
        iconEl.innerHTML = '<svg><use xlink:href="'+ this.icon +'"></use></svg>';
        
        const div = document.createElement("div");
        div.innerHTML = this.printService.createBarCode(this.barcodeValue);
        document.getElementById('barcode').appendChild(div);
    }

    print() {
        this.printService.printBarcode(this.title, this.barcodeValue);
    }
}
