import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { StorageService } from 'src/app/_services/storage.service';
import { Context } from 'src/app/utils/context';

@Component({
  selector: 'ng-casto-customer-vouchers',
  templateUrl: './customer-vouchers.component.html'
})
export class CustomerVouchersComponent implements OnInit {
  customerId: string;
  public context: Context = Context.Customer;

  constructor(
    private _location: Location,
    private storageService: StorageService) {}

  ngOnInit() {
    this.customerId = this.storageService.getUser();
  }

  goBack() {
    this._location.back();
  }
}
