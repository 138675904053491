import { mapEnumToOptions } from '@abp/ng.core';

export enum FidelityCardTypeCh {
  Aucune = 1,
  Gratuite = 2,
  Premium = 3,
  PremiumWaiting = 9,
}

export const fidelityCardTypeChOptions = mapEnumToOptions(FidelityCardTypeCh);
