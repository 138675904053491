import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FidelityCardTypeCh, MemberTypeCh } from '@proxy/cheetah/client/enum';
import { FidelityCardDto, InfoReturnCreateFidelityCardDto } from '@proxy/dto-common-website/fidelity-card';
import { PaytweakRequestDto } from '@proxy/paytweak-api/dto/models';
import { getCompleteAddress, scrollTo } from 'src/app/_helpers/common.helper';
import { isSiretValid } from 'src/app/_helpers/siret';
import { EnvService } from 'src/app/_services/env.service';
import { GTM } from 'src/app/_services/gtm.service';
import { StorageService } from 'src/app/_services/storage.service';
import { Context } from 'src/app/utils/context';
import { PaymentMethodEnum } from 'src/app/utils/payment-method.enum';
import { UpdateCustomerDto } from 'src/publicproxy/src/proxy/dto-common-website/customers/models';
import { CustomerService } from 'src/publicproxy/src/proxy/public/customer';
import { CardFormCreatorService, StatusEnum } from '../../../_services/card-form-creator.service';

const { required } = Validators;

@Component({
  selector: 'ng-casto-customer-create-card',
  templateUrl: './create-card.component.html'
})
export class CreateCardComponent implements OnInit {

  cardForm: FormGroup;
  projectsActive: boolean = false;
  saveLoading: boolean = false;
  isEmailSearched: boolean = false;
  globalLoading: boolean = false;

  customerId: string;
  email: string;
  cardNumber: string;
  infoNewCard: UpdateCustomerDto;
  fidelityCard: FidelityCardDto;

  step: number = 1;
  maxStep: number;

  isUpgrade = false;
  isUpdate = false;

  existingCardStatus: FidelityCardTypeCh;
  paytweakPayload: PaytweakRequestDto;

  context: Context = Context.Customer;

  protected readonly FidelityCardTypeCh = FidelityCardTypeCh;
  protected readonly PaymentMethodEnum = PaymentMethodEnum;

  constructor(private route: ActivatedRoute,
    private customerService: CustomerService,
    private storageService: StorageService,
    public router: Router,
    private gtm: GTM,
    private _location: Location,
    private cardFormCreator: CardFormCreatorService,
    private envService: EnvService) { }

  ngOnInit() {
    this.customerId = this.storageService.getUser();

    // Récupération des données customer si l'id est présent dans l'url
    this.globalLoading = true;

    this.customerService.getCustomer(this.storageService.getToken().access_token).subscribe(customer => {
      this.email = customer.mail;
      this.cardNumber = customer.cardNumber;
      this.isEmailSearched = true;
      this.existingCardStatus = customer.cardType;

      this.route.queryParams.subscribe(params => {
        if (params.upgrade || params.update) {
          this.isUpgrade = params.upgrade;
          this.isUpdate = params.update;

          if (this.isUpdate) {
            this.cardForm = this.cardFormCreator.createForm(StatusEnum.Update, Context.Customer, customer);
          } else {
            this.cardForm = this.cardFormCreator.createForm(StatusEnum.Upgrade, Context.Customer, customer);
          }
        } else {
          this.cardForm = this.cardFormCreator.createForm(StatusEnum.Create, Context.Customer, customer);
        }

        this.calculateMaxStep();

        this.globalLoading = false;
      });
    });
  }

  /**
   * Permet de calculer le nombre d'étape max du formulaire
   * @private
   */
  private calculateMaxStep(): void {
    this.maxStep = this.cardForm.get('cardIsPremium').value && !this.cardForm.get('isColleague').value && !this.isUpdate ? 3 : 2;

    this.cardForm.get('cardIsPremium').valueChanges.subscribe(cardIsPremium => {
      this.maxStep = cardIsPremium && !this.cardForm.get('isColleague').value && !this.isUpdate ? 3 : 2;
    });

    this.cardForm.get('isColleague').valueChanges.subscribe(isColleague => {
      this.maxStep = this.cardForm.get('cardIsPremium').value && !isColleague && !this.isUpdate ? 3 : 2;
    });
  }

  /**
   * Soumission du formulaire
   */
  submitForm(): void {
    if (this.cardForm.valid) {
      this.saveLoading = true;
      scrollTo('saveButton');

      this.infoNewCard = this.cardForm.getRawValue();
      //Si aucun id trouvé (creation carte) on envoie le formulaire pour créer la carte
      if (!this.infoNewCard?.projectNext6Months) {
        this.infoNewCard.projectNext6Months = {
          kitchen: false,
          bathroom: false,
          floorAndWall: false,
          insulation: false,
          storage: false,
          outdoor: false
        };
      }

      if (!this.isUpdate && !this.isUpgrade) {
        this.submitFormCreate();
      } else {
        this.submitFormUpdate();
      }
    } else {
      console.error('Erreurs de validation du formulaire : ');
      console.table(this.cardForm.errors);
    }
  }

  submitFormCreate(): void {
    this.infoNewCard.customerIdKf = this.customerId;
    this.customerService.createFidelityCard(this.infoNewCard, this.storageService.getToken().access_token, 
      this.envService.visitSource, this.envService.visitSourceStore, this.envService.visitSourceSector)
      .subscribe({
        next: (infoCard: InfoReturnCreateFidelityCardDto) => {
          this.customerId = infoCard.customerId;
          this.buildGTMEvents();
          this.redirectAfterSubmit();
        },
        error: (err) => {
          console.error(err);
          this.saveLoading = false;
        }
    });
  }

  private submitFormUpdate(): void {
    let customerDto: UpdateCustomerDto = { customerIdKf: this.customerId, ...this.infoNewCard };

    this.customerService.updateCustomer(customerDto, this.storageService.getToken().access_token).subscribe({
      next: () => this.redirectAfterSubmit(),
      error: (err) => {
        console.error(err);
        this.saveLoading = false;
      }
    });
  }

  private redirectAfterSubmit(): void {
    this.customerService.getFidelityCard(this.storageService.getToken().access_token).subscribe({
      next: async (fidelityCard: FidelityCardDto) => {
        this.fidelityCard = fidelityCard;

        if (this.isUpdate) {
          await this.router.navigate(['/clients/carte/' + this.fidelityCard.customerId]);
        }

        if (!this.infoNewCard.cardIsPremium) {
          this.step = 4;
        } else {
          this.paytweakPayload = {
            customerId: this.customerId,
            cardNumber: this.fidelityCard.cardNumber,
            address: getCompleteAddress(this.infoNewCard.houseNumber, this.infoNewCard.address),
            city: this.infoNewCard.city,
            country: this.infoNewCard.country,
            email: this.infoNewCard.email,
            phone: this.infoNewCard.phoneNumber,
            firstname: this.infoNewCard.firstName,
            lastname: this.infoNewCard.lastName,
            postalCode: this.infoNewCard.postalCode,
            siret: this.infoNewCard.siret,
            numTvaIntra: this.infoNewCard.nTvaIntracommunautaire
          };
          this.increaseStep();
        }

        this.saveLoading = false;
      },
      error: (err) => {
        console.error(err);
        this.saveLoading = false;
      }
    });
  }

  /**
   * Détermine si le champs est obligatoire ou non
   * @param id id du champ du formulaire
   * @returns boolean
   */
  isRequired(id: string): boolean {
    return this.cardForm.controls[id].hasValidator(required);
  }

  buildGTMEvents(): void {
    let customDatas: any[] = [{ userType: 'customer' }];

    switch (this.cardForm.value.cardType) {
      case FidelityCardTypeCh.Gratuite:
        customDatas.push({ cardType: "gratuite" });
        break;

      case FidelityCardTypeCh.Premium:
      case FidelityCardTypeCh.PremiumWaiting:
        customDatas.push({ cardType: "payante" });
        break;

      case FidelityCardTypeCh.Aucune:
        customDatas.push({ cardType: "aucune" });
        break;
    }

    if (this.cardForm.value.memberType === MemberTypeCh.Particulier) {
      customDatas.push({ customerType: "particulier" });
    } else if (this.cardForm.value.memberType === MemberTypeCh.Professionnel) {
      customDatas.push({ customerType: "professionnel" });
    } else {
      customDatas.push({ customerType: "non renseigné" });
    }

    if (this.cardForm.value.upcomingMove) {
      customDatas.push({ newArrival: true });
    }

    this.gtm.trackEvent("clic_event", "creation-carte", "creation", customDatas);
  }

  goBack() {
    this._location.back();
  }

  increaseStep() {
    this.step++;
    scrollTo('top');
  }

  decreaseStep() {
    this.step--;
    scrollTo('top');
  }

  firstStepValid(): boolean {
    if (this.cardForm.get('cardIsPremium').invalid) return false;
    if (this.cardForm.get('email').invalid) return false;
    if (this.cardForm.get('phoneNumber').invalid) return false;
    if (this.cardForm.get('civility').invalid) return false;
    if (this.cardForm.get('lastName').invalid) return false;
    if (this.cardForm.get('firstName').invalid) return false;
    if (this.cardForm.get('houseNumber').invalid) return false;
    if (this.cardForm.get('address').invalid) return false;
    if (this.cardForm.get('postalCode').invalid) return false;
    if (this.cardForm.get('city').invalid) return false;
    if (this.cardForm.get('birthDate').invalid) return false;
    if (this.cardForm.get('country').invalid) return false;
    if (this.cardForm.get('localityPoBox').invalid) return false;
    if (this.cardForm.get('buildingFloorStaircase').invalid) return false;
    if (this.cardForm.get('memberType').invalid) return false;
    if (this.cardForm.get('newMoveIn').invalid) return false;
    if (this.cardForm.get('lastMoveInDate').invalid) return false;
    if (this.cardForm.get('companyName').invalid) return false;
    if (this.cardForm.get('marketingConsent').get('optinEmail').invalid) return false;
    if (this.cardForm.get('marketingConsent').get('optinSms').invalid) return false;
    if (this.cardForm.get('marketingConsent').get('optinPhone').invalid) return false;
    if (this.cardForm.get('marketingConsent').get('optoutPostal').invalid) return false;
    if (this.cardForm.get('cguValidated').invalid) return false;

    if (this.cardForm.get('memberType').value === MemberTypeCh.Professionnel) {
      if (!isSiretValid(this.cardForm.get('siret').value)) {
        return false;
      }
    }

    return true;
  }

  /**
   * L'étape deux si le client est nouvel emménagé et a une carte premium
   */
  requiredStepTwo(): boolean {
    return this.cardForm.get('newMoveIn').value && this.cardForm.get('cardIsPremium').value;
  }
}
