function formatTitle(title: string): string {
  return `${title} | Castorama Fidélité`;
}

export const TITLES = {
  ACCUEIL: formatTitle('Accueil'),
  RECHERCHE: formatTitle('Recherche client'),
  CREATION: formatTitle('Création client'),
  MODIFICATION: formatTitle('Modification client'),
  CARTE: formatTitle('Fiche client'),
  HISTORIQUE: formatTitle('Historique'),
  LOYALTY: formatTitle('Détails Loyalty'),
  REPORTING: formatTitle('Reporting'),
  ADMINISTRATION: formatTitle('Administration'),
  CONNEXION: formatTitle('Connexion'),
  INFORMATIONS: formatTitle('Informations'),
  COUPONS: formatTitle('Coupons'),
};