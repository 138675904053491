import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { FidelityCardService } from '@proxy/user-casto/fidelity-card';
import { Context } from 'src/app/utils/context';
import { FidelityCardService as CustomerFidelityCardService } from './../../../publicproxy/src/proxy/public/fidelity-card/fidelity-card.service';
import { StorageService } from 'src/app/_services/storage.service';
import { CustomerService as ColleagueCustomerService } from "@proxy/user-casto/customers";
import { CustomerService } from 'src/publicproxy/src/proxy/public/customer';

@Component({
  selector: 'ng-casto-cancel-card-confirmation',
  templateUrl: './cancel-card-confirmation.component.html'
})
export class CancelCardConfirmationComponent implements OnInit {
  cardNumber: string;
  customerId: string;
  context: Context = Context.Colleague;
  loading: boolean;

  constructor(
    public modalService: ModalService,
    private fidelityCardService: FidelityCardService,
    private customerFidelityCardService: CustomerFidelityCardService,
    private storageService: StorageService,
    private colleagueCustomerService: ColleagueCustomerService,
    private customerService: CustomerService) { }

  ngOnInit(): void {
    this.cardNumber = this.modalService.currentDatas.cardNumber;
    this.customerId = this.modalService.currentDatas.customerId;
    this.context = this.modalService.currentDatas.context;
  }

  confirmCardCancel(): void {
    this.loading = true;

    if (this.context === Context.Colleague) {
      this.colleagueCustomerService.forceColleagueFreeCard({ customerKfId: this.customerId }).subscribe({
        next: () => {
          this.fidelityCardService.disabledCard(this.customerId).subscribe({
            next: () => {
              this.modalService.close();
              this.loading = false;
              window.location.reload();
            },
            error: (err) => {
              console.error(err);
              this.modalService.close();
              this.loading = false;
            }
          });
        }
      });
    } else {
      this.customerService.forceFreeCard({ customerKfId: this.customerId, registeredToken: this.storageService.getToken().access_token }).subscribe({
        next: () => {
          this.customerFidelityCardService.disabledCard(this.storageService.getToken().access_token).subscribe({
            next: () => {
              this.modalService.close();
              this.loading = false;
              window.location.reload();
            },
            error: (err) => {
              console.error(err);
              this.modalService.close();
              this.loading = false;
            }
          });
        }
      });
    }
  }
}
