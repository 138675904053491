<section class="form-card">
  <div class="login-card-header">
    <img class="login-card-title" src="assets/img/login/signin.svg">
    <h3 class="login-card-subtitle">Le programme de fidélité qui avance avec vous</h3>
  </div>

  <section class="login-card-body text-center">
    <img src="/assets/img/login/card-icon.svg" alt="">
  </section>

  <footer class="login-card-footer">
    <ng-casto-btn class="blue"
                  [label]="'Je crée ma carte'"
                  (click)="onSwitchContext(LoginContext.registation)"></ng-casto-btn>

    <p class="mt-3" *ngIf="responsive.isMobile">
      <small>
        J’ai déjà une carte de fidélité !
        <a (click)="onSwitchContext(LoginContext.login)"
           class="casto-link">Je me connecte</a>
      </small>
    </p>
  </footer>
</section>
