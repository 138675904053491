import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

if (environment.production) {
  enableProdMode();
}

if (browserWindowEnv?.['tracking']) {
  // add Google Analytics script to <head>
  const gtmScript = document.createElement('script');
  gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${environment.googleTagManager.tag}');`;
  document.head.appendChild(gtmScript);

  if (browserWindowEnv['public']) {
    const iabTcfScript = document.createElement('script');
    iabTcfScript.src = 'assets/scripts/TCF_stub_code.js';
    document.head.appendChild(iabTcfScript);

    // add Trustarc script to body
    const divConsent = document.createElement('div');
    divConsent.id = 'teconsent';
    const divConsentBar = document.createElement('div');
    divConsentBar.id = 'consent_blackbar';

    const trustarcScript = document.createElement('script');
    trustarcScript.async = true;
    trustarcScript.src = '//consent.trustarc.com/notice?domain=castorama-fr-iab.com&c=teconsent&text=true&language=fr&pcookie&js=nj&noticeType=bb';

    document.body.appendChild(divConsent);
    document.body.appendChild(trustarcScript);
    document.body.appendChild(divConsentBar);
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
