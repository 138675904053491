import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MODAL, ModalService } from 'src/app/modals/modal.service';
import { Context } from 'src/app/utils/context';
import { OrderHistoryService as ColleagueOrderHistoryService } from '@proxy/user-casto/order-history';
import { OrderHistoryService } from 'src/publicproxy/src/proxy/public/order-history';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { ActivityPurchaseChDto } from '@proxy/cheetah/client/dto';
import { StorageService } from 'src/app/_services/storage.service';
import { SearchHistoryDto } from '@proxy/dto-common-website';

@Component({
  selector: 'ng-casto-common-customer-order-history',
  templateUrl: './customer-order-history.component.html',
  providers: [ListService],
})
export class CustomerOrderHistoryComponent implements OnInit {

  orderHistory = { items: [], totalCount: 0 } as PagedResultDto<ActivityPurchaseChDto>;
  @Input() customerId = '';
  @Input() resume:boolean = false;
  @Input() context: Context = Context.Colleague;
  loading: boolean = false;
  token: string;
  numberPerPage = 8;

  constructor(
    public readonly list: ListService<SearchHistoryDto>,
    private orderHistoryService: OrderHistoryService,
    private colleagueOrderHistoryService: ColleagueOrderHistoryService,
    private router: Router,
    private modalService: ModalService,
    private storageService: StorageService) {
      this.list.maxResultCount = this.numberPerPage;
    }

  ngOnInit() {
    this.loading = true;

    switch (this.context){
      case Context.Colleague:
        const getOrderHistoryColleague = (query) => this.colleagueOrderHistoryService.getOrderHistory({
          ...query,
          customerId: this.customerId,
          pageNumber: this.list.page + 1,
          resultPerPage: this.numberPerPage,
        });

        this.list.hookToQuery(getOrderHistoryColleague).subscribe({
          next: (response) => {
            this.orderHistory = response;
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        });
        break;
      case Context.Customer:
        this.token = this.storageService.getToken()?.access_token;

        const getOrderHistoryCustomer = (query) => this.orderHistoryService.getOrderHistory(
          {
            ...query,
            customerId: this.customerId,
            pageNumber: this.list.page + 1,
            resultPerPage: this.numberPerPage,
          },
          this.token
        );

        this.list.hookToQuery(getOrderHistoryCustomer).subscribe({
          next: (response) => {
            this.orderHistory = response;
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        });
        break;
    }
  }

  onActivate(event: any) {
    if(event.type === 'click'){
      this.modalService.display(MODAL.ORDER_DETAILS, {
        customerId: this.customerId,
        orderId: event.row.orderIdAssociated,
        registeredToken: this.token,
        context: this.context
      });
    }
  }
}
