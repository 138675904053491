import { Environment } from '@abp/ng.core';
import { version } from './version';

const baseUrl = '#{base_url}#';

export const environment = {
  production: true,
  application: {
    baseUrl,
    name: 'CastoramaFidelite',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: '#{backend_url}#/',
    redirectUri: baseUrl,
    clientId: 'CastoramaFidelite_App_Public',
    responseType: 'code',
    scope: 'CastoramaFidelite',
    requireHttps: true,
  },
  apis: {
    default: {
      url: '#{backend_url}#',
      rootNamespace: 'CastoramaFidelite',
    },
    kingfisher: {
      url: '#{kingfisher_api_url}#',
      clientSecret: '#{kingfisher_api_secret}#',
      clientId: '#{kingfisher_api_clientid}#',
      beginUsername: '#{kingfisher_api_beginusername}#',
      environmentUrl: '#{kingfisher_api_environmenturl}#'
    },
    captainWallet: {
      url: '#{captainwallet_url}#',
      campaignId: 'loyalty',
      channel: 'web'
    }
  },
  googleTagManager: {
    tag: '#{gtm_id}#'
  },
  links: {
    cguUrl: '#{cgu_url}#',
    cgvUrl: '#{cgv_url}#',
  },
  version: version
} as Environment;
