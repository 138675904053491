<form [formGroup]="form">
  <div class="form-card form-card-large" [ngClass]="{disabled: form.value?.memberType === ''}">
    <h2 class="form-title">
      <span class="icon"></span>
      <span *ngIf="!isCustomer()">Votre client habite : <span *ngIf="isRequired('habitationType')">*</span></span>
      <span *ngIf="isCustomer()">Vous habitez : <span *ngIf="isRequired('habitationType')">*</span></span>
    </h2>
    <div class="form-group">
      <div class="form-row selector-row">
        <div class="casto-selector project-selector">
          <input type="radio" name="habitationType" formControlName="habitationType" id="house" [value]="HabitationTypeCh.Maison">
          <label for="house">
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-project-maison"></use>
            </svg>
            <p>Maison</p>
          </label>
        </div>
        <div class="casto-selector project-selector">
          <input type="radio" name="habitationType" formControlName="habitationType" id="appartment" [value]="HabitationTypeCh.Appartement">
          <label for="appartment">
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-project-appartement"></use>
            </svg>
            <p>Appartement</p>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="form-card form-card-large">
    <h2 class="form-title">
      <span class="icon"></span>
      <span *ngIf="!isCustomer()">Votre client est : <span *ngIf="isRequired('customerPropertySituation')">*</span></span>
      <span *ngIf="isCustomer()">Vous êtes : <span *ngIf="isRequired('customerPropertySituation')">*</span></span>
    </h2>
    <div class="form-group">
      <div class="form-row selector-row">
        <div class="casto-selector project-selector">
          <input type="radio" name="customerPropertySituation" formControlName="customerPropertySituation" id="owner" [value]="CustomerPropertySituationCh.Proprietaire">
          <label for="owner">
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-project-proprietaire"></use>
            </svg>
            <p>Propriétaire</p>
          </label>
        </div>
        <div class="casto-selector project-selector">
          <input type="radio" name="customerPropertySituation" formControlName="customerPropertySituation" id="tenant" [value]="CustomerPropertySituationCh.Locataire">
          <label for="tenant">
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-project-locataire"></use>
            </svg>
            <p>Locataire</p>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="form-card form-card-large">
    <h2 class="form-title">
      <span class="icon"></span>
      <span>Avez-vous des projets dans les 6 prochains mois ?</span>
    </h2>
    <div class="form-group">
      <div class="form-row selector-row">
        <div class="casto-selector project-selector">
          <input type="radio"
                 id="upcomingProjectTrue"
                 [value]="true"
                 formControlName="upcomingProject">
          <label for="upcomingProjectTrue">Oui</label>
        </div>
        <div class="casto-selector project-selector">
          <input type="radio"
                 id="upcomingProjectFalse"
                 [value]="false"
                 formControlName="upcomingProject">
          <label for="upcomingProjectFalse">Non</label>
        </div>
      </div>

      <div class="form-row selector-row mt-3" formGroupName="projectNext6Months">
        <div class="casto-selector project-selector" *ngFor="let project of projects">
          <input type="checkbox" [name]="project.name" [id]="project.name" [formControlName]="project.name">
          <label [attr.for]="project.name">
            <svg>
              <use [attr.xlink:href]="'assets/img/icons.svg#'+project.icon"></use>
            </svg>
            <p>{{project.label}}</p>
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
