import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ng-casto-common-create-card-terms-conditions',
  templateUrl: './terms-conditions.component.html'
})
export class CreateCardTermsConditionsComponent {
  @Input() form: FormGroup;

  constructor() { }
}
