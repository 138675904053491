import { Component, Input, OnInit } from '@angular/core';
import { FidelityCardService } from '@proxy/user-casto/fidelity-card';
import type { AllGiftCardOfferCouponDto } from '@proxy/dto-common-website/fidelity-card/models';
import { ModalService } from '../modal.service';
import { Voucher } from '../../utils/voucher';
import { Context } from 'src/app/utils/context';
import { FidelityCardService as CustomerFidelityCardService } from './../../../publicproxy/src/proxy/public/fidelity-card/fidelity-card.service';
import { AllGiftCardOfferCouponDto as CustomerAllGiftCardOfferCouponDto } from './../../../publicproxy/src/proxy/dto-common-website/fidelity-card/models';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'ng-casto-vouchers',
  templateUrl: './vouchers.component.html'
})
export class VouchersComponent implements OnInit {
  @Input() customerId: string;
  @Input() context: Context;

  private _vouchers: Voucher[] = [];
  get vouchers(): Voucher[] {
    return this._vouchers;
  }

  voucherActives: Voucher[] = [];
  voucherInactives: Voucher[] = [];

  set vouchers(vouchers: AllGiftCardOfferCouponDto|CustomerAllGiftCardOfferCouponDto) {
    this._vouchers = vouchers.giftCardsList.map(g => new Voucher(null, g));
    this._vouchers.push(...vouchers.offerCouponsList.map(o => new Voucher(o)));
    this._vouchers.sort((a, b) => new Date(b.dateEnd).getTime() - new Date(a.dateEnd).getTime());
  }

  seeUseVouchers = false;
  isLoading = false;

  constructor(public modalService:ModalService,
              private fidelityCardService: FidelityCardService,
              private storageService: StorageService,
              private customerFidelityCardService: CustomerFidelityCardService) {}

  ngOnInit() {
    this.isLoading = true;

    if(!this.customerId) {
      this.customerId = this.modalService.currentDatas.customerId;
    }

    if(!this.context) {
      this.context = this.modalService.currentDatas.context;
    }

    if(this.context === Context.Colleague) {
      this.fidelityCardService.getAllOfferCard(this.customerId).subscribe((response: AllGiftCardOfferCouponDto) => {
        this.getVouchersCallback(response);
      });
    } else {
      this.customerFidelityCardService.getAllOfferCard(this.storageService.getToken().access_token)
        .subscribe((response: CustomerAllGiftCardOfferCouponDto) => {
          this.getVouchersCallback(response);
      })
    }
  }

  private getVouchersCallback(response: AllGiftCardOfferCouponDto | CustomerAllGiftCardOfferCouponDto) {
    this.vouchers = response;
    this.voucherActives = this.getVouchers(true);
    this.voucherInactives = this.getVouchers(false);
    this.isLoading = false;
  }

  getVouchers(isActive: boolean): Voucher[] {
    return this.vouchers.filter(v => v.active === isActive);
  }

  setSeeUseVoucher(): void {
    this.seeUseVouchers = !this.seeUseVouchers;
  }

  isCustomer(): boolean {
    return this.context === Context.Customer;
  }
}
