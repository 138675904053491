import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateImageDto } from '../application/contracts/models';
import type { ImageDto, ImageGroupDto, InfoForHomeDto } from '../../dto-common-website/images/models';
import type { ImageGroupName } from '../../utils/image-group-name.enum';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  apiName = 'Default';
  

  createImage = (imageDto: CreateUpdateImageDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/image/image',
      body: imageDto,
    },
    { apiName: this.apiName,...config });
  

  deleteImage = (imageId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/image/image/${imageId}`,
    },
    { apiName: this.apiName,...config });
  

  getAllImageGroup = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ImageGroupDto[]>({
      method: 'GET',
      url: '/api/app/image/image-group',
    },
    { apiName: this.apiName,...config });
  

  getImage = (imageId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ImageDto>({
      method: 'GET',
      url: `/api/app/image/image/${imageId}`,
    },
    { apiName: this.apiName,...config });
  

  getImageGroupCompleteById = (imageGroupId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ImageGroupDto>({
      method: 'GET',
      url: `/api/app/image/image-group-complete-by-id/${imageGroupId}`,
    },
    { apiName: this.apiName,...config });
  

  getImageGroupCompleteByName = (imageGroupEnum: ImageGroupName, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ImageGroupDto>({
      method: 'GET',
      url: '/api/app/image/image-group-complete-by-name',
      params: { imageGroupEnum },
    },
    { apiName: this.apiName,...config });
  

  getImageGroupFromName = (imageGroupEnum: ImageGroupName, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ImageGroupDto>({
      method: 'GET',
      url: '/api/app/image/image-group-from-name',
      params: { imageGroupEnum },
    },
    { apiName: this.apiName,...config });
  

  getInfoForHome = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, InfoForHomeDto>({
      method: 'GET',
      url: '/api/app/image/info-for-home',
    },
    { apiName: this.apiName,...config });
  

  updateImage = (imageId: string, imageDto: CreateUpdateImageDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/image/image/${imageId}`,
      body: imageDto,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
