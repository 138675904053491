import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { HabitationTypeCh, CustomerPropertySituationCh, FidelityCardTypeCh } from '@proxy/cheetah/client/enum';
import { atLeastOne } from 'src/app/_helpers/atLeastOneValidator';
import { Context } from 'src/app/utils/context';

const { required } = Validators;

@Component({
  selector: 'ng-casto-common-create-card-projects',
  templateUrl: './projects.component.html'
})
export class CreateCardProjectsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() projectsActive: boolean;
  @Input() isUpgrade: boolean;
  @Input() existingCardStatus: FidelityCardTypeCh;
  @Input() context?: Context = Context.Colleague;

  protected readonly FidelityCardTypeCh = FidelityCardTypeCh;

  projects: any[] = [{
    name: 'kitchen',
    label: 'Cuisine',
    icon: 'icon-project-cuisine'
  },{
    name: 'bathroom',
    label: 'Salle de bain',
    icon: 'icon-project-salledebain'
  },{
    name: 'floorAndWall',
    label: 'Sol et mur',
    icon: 'icon-project-soletmur'
  },{
    name: 'insulation',
    label: 'Isolation',
    icon: 'icon-project-isolation'
  },{
    name: 'storage',
    label: 'Rangement',
    icon: 'icon-project-rangement'
  },{
    name: 'outdoor',
    label: 'Aménagement extérieur',
    icon: 'icon-project-amenagementexterieur'
  }]

  HabitationTypeCh: typeof HabitationTypeCh;
  CustomerPropertySituationCh: typeof CustomerPropertySituationCh;

  constructor() {
    this.HabitationTypeCh = HabitationTypeCh;
    this.CustomerPropertySituationCh = CustomerPropertySituationCh
  }

  ngOnInit(): void {
    const upcomingProject = this.form.get('upcomingProject');
    const projectNext6Months = this.form.get('projectNext6Months') as FormGroup;

    this.projects.forEach(p => {
      projectNext6Months.get(p.name).valueChanges.subscribe(isSelected => {
        const checked = this.projects.map(p => projectNext6Months.get(p.name).value).filter(val => val);

        if (upcomingProject.value && checked.length === 0) {
          projectNext6Months.addValidators(atLeastOne());
        }
      });
    });

    upcomingProject.valueChanges.subscribe(isSelected => {
      if (!isSelected) {
        this.projects.forEach(p => {
          projectNext6Months.controls[p.name]?.disable();
          projectNext6Months.controls[p.name]?.setValue(false);
        });
      } else {
        projectNext6Months.addValidators(atLeastOne());
        this.projects.forEach(p => {
          projectNext6Months.controls[p.name]?.enable();
        });
      }
    });
  }

  isRequired(id: string): boolean {
    return this.form.controls[id].hasValidator(required);
  }

  isCustomer(): boolean {
    return this.context === Context.Customer;
  }
}
