import { Component, Input } from '@angular/core';
import { FidelityCardTypeCh } from '@proxy/cheetah/client/enum';
import { FidelityCardDto } from '@proxy/dto-common-website/fidelity-card';
import { Context } from 'src/app/utils/context';

@Component({
  selector: 'ng-casto-card-create',
  templateUrl: './card-create.component.html'
})
export class CardCreateComponent {
  @Input() fidelityCard: FidelityCardDto;
  @Input() cardIsPremium: boolean;
  @Input() isUpgrade: boolean;
  @Input() context: Context = Context.Colleague;

  protected readonly FidelityCardTypeCh = FidelityCardTypeCh;

  isCustomer(): boolean {
    return this.context === Context.Customer;
  }
}
