<div class="main-container customer">
  <div class="container">
    <div class="form-card">
      <h2 class="form-title">
        <span class="icon"></span>
        Historique d'achats
      </h2>
      <ng-casto-common-customer-order-history [customerId]="this.customerId" [resume]="false" [context]="context"></ng-casto-common-customer-order-history>
    </div>
  </div>
</div>
