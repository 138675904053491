<div class="container" *ngIf="paymentMethod !== PaymentMethodEnum.DIRECT">
  <section class="casto-card">
    <svg class="card-icon">
      <use xlink:href="assets/img/icons.svg#ccastomax"></use>
    </svg>

    <div class="warning-icon">
      <svg>
        <use xlink:href="assets/img/icons.svg#icon-warning"></use>
      </svg>
    </div>

    <div class="card-infos" *ngIf="fidelityCard">
      <h3>Carte C-Casto Max</h3>
      <p>Carte de fidélité</p>
      <p>n° {{ fidelityCard.cardNumber }}</p>
      <p *ngIf="fidelityCard.finalExpirationDate">Expire le <strong>{{ fidelityCard.finalExpirationDate }}</strong></p>
    </div>
  </section>

  <section class="message" [ngSwitch]="paymentMethod">
    <h2>Finalisez la création de la carte</h2>

    <p class="subtitle"><strong>Attention cette carte n'est pas encore active.</strong></p>

    <p *ngSwitchCase="PaymentMethodEnum.SMS">
      <ng-container *ngIf="isCustomer()">
        Veuillez procéder au règlement de la cotisation grâce au lien reçu par SMS.
      </ng-container>
      <ng-container *ngIf="!isCustomer()">
        Veuillez indiquer au client de procéder au règlement de la cotisation grâce au lien reçu par SMS.
      </ng-container>
    </p>

    <p *ngSwitchCase="PaymentMethodEnum.QR_CODE">
      <ng-container *ngIf="isCustomer()">
        Veuillez procéder au règlement de la cotisation grâce au QR code ci-dessous.
      </ng-container>
      <ng-container *ngIf="!isCustomer()">
        Veuillez indiquer au client de procéder au règlement de la cotisation grâce au QR code ci-dessous.
      </ng-container>
    </p>

    <p *ngSwitchCase="PaymentMethodEnum.CHECKOUT">
      Veuillez remettre le code service au client pour présentation et paiement de la cotisation en caisse.
    </p>

    <p>Un e-mail de confirmation sera ensuite envoyé au client avec son coupon de 5% de bienvenue.</p>
  </section>

  <div id="qr-code" *ngIf="paymentMethod === PaymentMethodEnum.QR_CODE && qrCodeBase64">
    <img [src]="qrCodeBase64" alt="qrcode">
  </div>

  <section class="actions">
    <ng-casto-btn class="outline"
                  *ngIf="paymentMethod === PaymentMethodEnum.SMS"
                  [label]="'Renvoyer le lien par SMS'"
                  (click)="openModalSendSms()"
                  [loading]="paymentLoading"></ng-casto-btn>

    <ng-casto-btn class="blue"
                  [label]="'Aller sur la fiche client'"
                  (click)="onFinish()"
                  [loading]="paymentLoading"></ng-casto-btn>
  </section>
</div>

<ng-container *ngIf="paymentMethod === PaymentMethodEnum.DIRECT" class="heightFull">
  <div class="form-card form-card-large heightFull">
    <div class="text-center" *ngIf="paymentLoading">
      <ng-casto-loader></ng-casto-loader>
    </div>
    <iframe title="paytweak" height="100%" *ngIf="!paymentLoading && iFrameLink" [src]="iFrameLink | safeUrl" frameborder="0"></iframe>
  </div>

  <section class="actions">
    <p>Une fois votre paiement validé, veuillez cliquez sur le bouton ci-dessous.</p>
    <ng-casto-btn class="blue"
                  [label]="'Aller sur la fiche client'"
                  (click)="onFinish()"
                  [loading]="paymentLoading"></ng-casto-btn>
  </section>
</ng-container>
