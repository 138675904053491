import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '../../../../_services/storage.service';
import { AuthService } from '../../../../_services/auth.service';
import { Router } from '@angular/router';
import { GTM } from '../../../../_services/gtm.service';
import { ResponsiveService } from '../../../../_services/responsive.service';
import { LoginContextEnum } from '../login.component';

@Component({
  selector: 'ng-casto-customer-login-form',
  templateUrl: './login-form.component.html'
})
export class CustomerLoginFormComponent implements OnInit {
  @Output() switchContext = new EventEmitter<LoginContextEnum>();

  form: FormGroup;
  loading: boolean = false;
  errorMessage = '';
  showPassword: boolean = false;

  protected readonly LoginContext = LoginContextEnum;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private gtm: GTM,
              private authService: AuthService,
              private storageService: StorageService,
              public responsive: ResponsiveService) {}

  ngOnInit() {
    const email = this.storageService.getEmail();
    this.form = this.formBuilder.group({
      email: [email, [Validators.email, Validators.required]],
      password: ['', Validators.required],
      forgotMe: [email !== '', Validators.required],
    });
  }

  onSwitchContext(loginContext: LoginContextEnum) {
    this.errorMessage = '';

    this.switchContext.emit(loginContext);
  }

  loginUser(): void {
    if(this.form.valid) {
      this.errorMessage = '';
      this.loading = true;

      let email = this.form.get('email').value;
      let password = this.form.get('password').value;
      let forgotMe = this.form.get('forgotMe').value;

      this.authService.login(email, password).subscribe({
        next: data => {
          this.storageService.saveToken(data);

          this.authService.getCustomerResources(data.access_token).subscribe({
            next: async userData => {
              this.storageService.saveUser(userData.data.attributes.profileId);

              if (forgotMe) {
                this.storageService.saveEmail(email);
              } else {
                this.storageService.clearEmail();
              }

              this.gtm.trackEvent("clic_event", "connexion", "succes", [{userType: "client"}]);
              await this.router.navigate(['/accueil']);
              this.loading = false;
            },
            error: err => {
              this.gtm.trackEvent("clic_event", "connexion", "echec", [{userType: "client"}]);
              if(err.error?.error === 'Key not authorized'){
                this.errorMessage = 'Erreur de connexion à Kingfisher';
              } else {
                this.errorMessage = 'Adresse e-mail ou mot de passe incorrect.';
              }
              this.loading = false;
            }
          })

        },
        error: err => {
          this.errorMessage = 'Adresse e-mail ou mot de passe incorrect.';
          this.loading = false;
        }
      })
    }
  }
}
