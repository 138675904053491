<header [ngClass]="{'is-modal': isModalOpening}">
  <div class="wrap">
    <div class="text-center">
      <ng-casto-loader *ngIf="loading"></ng-casto-loader>
    </div>

    <ng-container *ngIf="!loading">
      <img class="card-image" [src]="image.urlImage" (click)="redirectUrl()">
    </ng-container>

    <div class="header-actions">
      <button class="close" (click)="modalService.close()" title="Fermer">
        <span class="icon-view-close" aria-hidden="true">
          <svg>
            <use xlink:href="assets/img/icons.svg#icon-view-close"></use>
          </svg>
        </span>
        <span>Fermer</span>
      </button>
    </div>
  </div>
</header>
<div class="main">
</div>
