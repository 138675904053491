import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FidelityCardTypeCh, MemberTypeCh } from '@proxy/cheetah/client/enum';
import { Context } from 'src/app/utils/context';

@Component({
  selector: 'ng-casto-common-create-card-customer-type',
  templateUrl: './customer-type.component.html'
})
export class CreateCardCustomerTypeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() context: Context;
  @Input() existingCardStatus: FidelityCardTypeCh;
  newMoveInOptions: ListItem[];
  lastMoveInDate?: Date;

  months = {
    0: 'Janvier',
    1: 'Février',
    2: 'Mars',
    3: 'Avril',
    4: 'Mai',
    5: 'Juin',
    6: 'Juillet',
    7: 'Août',
    8: 'Septembre',
    9: 'Octobre',
    10: 'Novembre',
    11: 'Décembre'
  };

  MemberTypeCh: typeof MemberTypeCh;
  public readonly customerContext: Context = Context.Customer;
  protected readonly FidelityCardTypeCh = FidelityCardTypeCh;

  constructor() {
    this.MemberTypeCh = MemberTypeCh;
  }

  ngOnInit(): void {
    if (this.form.get('lastMoveInDate').value) {
      this.lastMoveInDate = new Date(this.form.get('lastMoveInDate').value);

      this.form.get('lastMoveInDate').setValue(this.getDateString(this.lastMoveInDate));
    }

    this.newMoveInOptions = this.getLastMoveInOptions();
  }

  /**
   * Construit le tableau de mois à afficher (3 mois avant & 6 mois après)
   * @returns tableau de mois à afficher
   */
  private getLastMoveInOptions(): ListItem[] {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const monthsBefore = 3;
    const monthsAfter = 6;

    let monthsToDisplay: ListItem[] = [];

    // ajout des options pour le lapse de temps indiqué
    for (let i = - monthsBefore; i <= monthsAfter; i++) {
      let date = new Date(`${currentYear}-${currentMonth + 1}-01`);
      date.setMonth(currentMonth + i);

      const newMoveInOption = this.getLastMoveInOption(date.getMonth(), date.getFullYear());
      monthsToDisplay.push(newMoveInOption);
    }

    // ajout d'une option si la valeur du champ n'est pas dans la liste
    if (this.lastMoveInDate && !monthsToDisplay.some(m => m.value === this.getDateString(this.lastMoveInDate))) {
      monthsToDisplay.push(this.getLastMoveInOption(this.lastMoveInDate.getMonth(), this.lastMoveInDate.getFullYear()));
    }

    // tri par ordre croissant de date
    monthsToDisplay = monthsToDisplay.sort((a, b) => a.value < b.value ? -1 : 1);

    /*monthsToDisplay.push(...[
      {value: null, text: 'Je n\'ai pas encore de date', selected: false},
      {value: null, text: 'J\'ai déjà déménagé', selected: false}
    ]);*/

    return monthsToDisplay;
  }

  /**
   * Créer une option pour un mois précis
   * @private
   */
  private getLastMoveInOption(month: number, year: number): ListItem {
    return {
      value: this.getDateString(new Date(`${year}-${this.getMonthNumberFormat(month)}-01`)),
      text: `${this.getMonthName(month)} - ${year}`,
      selected: this.lastMoveInDate
        && this.lastMoveInDate.getMonth() === month
        && this.lastMoveInDate.getFullYear() === year
    };
  }

  private getMonthName(index: number): string {
    return this.months[index];
  }

  /**
   * Récupérer le numéro du mois formatté en '01', '02, ..., '12'
   * @private
   */
  private getMonthNumberFormat(month: number): string {
    // getMonth() renvoie un nombre entre 0 et 11, j'ajoute 1 pour lui donner sa valeur dans le calendrier
    month++;

    return month.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
  }

  private getDateString(date: Date): string {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset * 60 * 1000));
    return date.toISOString().split('T')[0];
  }
}

interface ListItem {
  value: string;
  text: string;
  selected: boolean;
}
