<div class="text-center" *ngIf="loading">
  <ng-casto-loader></ng-casto-loader>
</div>

<div class="result-table" *ngIf="customerPoints?.items && customerPoints.totalCount > 0 && !loading">
    <ngx-datatable [rows]="customerPoints?.items" [count]="customerPoints.totalCount" [list]="list" default>

      <ngx-datatable-column name="Date" prop="activityDate" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ (row.activityDate | date:'dd/MM/yyyy') ?? '-' }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Motif" prop="activityTypeText" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ (row.activityType === ActivityTypePointCh.points_regulation ? row.regulationLabel : row.activityTypeText) }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Nombre de points" prop="pointsAcquired" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span *ngIf="row.pointsAcquired > 0" class="text-success">+{{ row.pointsAcquired | number }} points</span>
          <span *ngIf="row.pointsAcquired < 0" class="text-danger">{{ row.pointsAcquired | number }} points</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Date de validité" prop="validityPointsDateMax" [sortable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span *ngIf="row.validityPointsDateMax">Valable jusqu'au</span> {{ (row.validityPointsDateMax | date:'dd/MM/yyyy') ?? '-' }}
        </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>

<div class="text-center" *ngIf="!customerPoints.items.length && !loading">
  <h2 class="m-3">Aucune donnée disponible</h2>
</div>
