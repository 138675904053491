import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  /**
   * Taille max d'un écran de téléphone (correspond au small de bootstrap)
   */
  mobileBreakpoint = 768;
  isMobile = false;

  constructor() {
    this.isMobile = window.innerWidth < this.mobileBreakpoint;

    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < this.mobileBreakpoint;
    });
  }
}
