import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { regexPassword } from 'src/app/utils/constants';
import { ModalService } from '../modal.service';

const { required, pattern } = Validators;

@Component({
  selector: 'ng-casto-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;

  constructor(
    public modalService: ModalService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', [required]],
      newPassword: ['', [required, pattern(regexPassword)]],
      newPasswordConfirm: ['', [required]]
    }, { validators: this.checkPasswords });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('newPassword').value;
    let confirmPass = group.get('newPasswordConfirm').value
    return pass === confirmPass ? null : { notSame: true }
  }
}
