import { Component, OnInit, HostBinding, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/_services/auth.service';
import { StorageService } from 'src/app/_services/storage.service';
import { ConfigStateService, CurrentUserDto } from '@abp/ng.core';
import { OAuthService } from 'angular-oauth2-oidc';
import { CustomerService } from 'src/publicproxy/src/proxy/public/customer';
import { CustomerDto } from '../../../publicproxy/src/proxy/dto-common-website/customers';

@Component({
  selector: 'ng-casto-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @Input() context: string;
  public customerInfo: CustomerDto;
  connectedUser: CurrentUserDto;

  @HostBinding("class.configuration") get isInConfiguration():boolean { return this.router.url.indexOf("/configuration") > 0};
  @HostBinding("class.customer") get isCustomerContext():boolean { return this.context === 'customer'};
  @HostBinding("class.configuration") get isLogin():boolean { return this.location.path().indexOf('connexion') > 0};

  @ViewChild('sidebar') sidebar: ElementRef;

  isMenuOpen: boolean = false;

  constructor(public router: Router,
              private customerService: CustomerService,
              private location: Location,
              private authService: AuthService,
              private storageService: StorageService,
              private oAuthService: OAuthService,
              public config: ConfigStateService) {
                router.events.subscribe(() => {
                  this.isMenuOpen = false;
                })
              }

  ngOnInit(): void {
    if(this.context === 'customer' && this.storageService.isLoggedIn()){
      this.customerService.getCustomer(this.storageService.getToken().access_token).subscribe((c) => this.customerInfo = c);
    } else {
      this.connectedUser = this.config.getOne("currentUser");
    }

    this.storageService.getLogged().subscribe((x) => {
      if(x){
        this.customerService.getCustomer(this.storageService.getToken().access_token).subscribe((c) => this.customerInfo = c);
      }
    });
  }

  askLogout(){
    this.oAuthService.revokeTokenAndLogout();
  }

  async logout(): Promise<void> {
    if (this.context !== 'customer') {
      this.authService.logout().subscribe({
        next: res => {
          this.storageService.clean();
          this.router.navigate(['/accueil']);
        },
        error: err => {
          console.error(err);

          //TODO : supprimer les lignes suivantes lorsque le CORS sera réglé
          this.storageService.clean();
          this.router.navigate(['/connexion']);
        }
      });
    } else {
      this.storageService.clean();
      await this.router.navigate(['/connexion']);
    }
  }

  navigateTo(url: string){
    window.open(url, '_blank').focus();
  }
}
