export const isSiretValid = (siret: any) => {
    if ((siret?.length !== 14) || (isNaN(siret))) {
        return false;
    } else {
        let somme = 0;
        let tmp;
        for (let cpt = 0; cpt < siret.length; cpt++) {
            if ((cpt % 2) === 0) { // Les positions impaires : 1er, 3è, 5è, etc...
                tmp = siret.charAt(cpt) * 2; // On le multiplie par 2
                if (tmp > 9) {
                    tmp -= 9; // Si le résultat est supérieur à 9, on lui soustrait 9
                }
            } else {
                tmp = siret.charAt(cpt);
            }
            somme += parseInt(tmp, 10);
        }

        if ((somme % 10) !== 0) {
            return false; // Si la somme est un multiple de 10 alors le SIRET est valide
        }
    }

    return true;
};

export const getTvaFromSiret = (siret: any) => {
    let siren = siret.substring(0, 9);
    let tva = (12 + 3 * (siren % 97)) % 97;
    return "FR" + tva + siren;
};