import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthGuardService {
  constructor(private storageService: StorageService, public router: Router){}

  canActivate(): boolean {
    if(!this.storageService.isLoggedIn()) {
      this.router.navigate(['connexion']);
      return false;
    }
    return true;
  }
}
