import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomerDto } from '@proxy/dto-common-website/customers';
import { AuthService } from 'src/app/_services/auth.service';
import { StorageService } from 'src/app/_services/storage.service';
import { MODAL, ModalService } from 'src/app/modals/modal.service';
import { Context } from 'src/app/utils/context';
import { CustomerService } from 'src/publicproxy/src/proxy/public/customer';
import { UpdateCustomerMarketingConsentKfDto } from '../../../../publicproxy/src/proxy/kingfisher-api/models';
import { CardFormCreatorService, StatusEnum } from '../../../_services/card-form-creator.service';
import { SmallUpdateCustomerDto } from './../../../proxy/dto-common-website/customers/models';

@Component({
  selector: 'ng-casto-customer-informations',
  templateUrl: './customer-informations.component.html'
})
export class CustomerInformationsComponent implements OnInit {

  personalInformationsForm!: FormGroup;
  isEmailSearched: boolean = true;
  loading: boolean = false;
  saveLoading: boolean;

  constructor(
    private modalService: ModalService,
    private customerService: CustomerService,
    private storageService: StorageService,
    private cardFormCreatorService: CardFormCreatorService,
    private authService: AuthService) { }

  ngOnInit() {
    this.loading = true;

    this.customerService.getCustomer(this.storageService.getToken().access_token).subscribe((customer: CustomerDto) => {
      this.personalInformationsForm = this.cardFormCreatorService.createForm(
        StatusEnum.SmallUpdate,
        Context.Customer,
        customer);

      this.personalInformationsForm.get('marketingConsent').valueChanges.subscribe(marketingConsent => {
        let updateCustomerMarketingConsentKfDto: UpdateCustomerMarketingConsentKfDto = {
          customerIdKf: customer.id,
          marketingConsent: marketingConsent
        };

        this.customerService.updateMarketingConsentCustomer(updateCustomerMarketingConsentKfDto, this.storageService.getToken().access_token).subscribe({
          error: (err) => {
            console.error(err);
          }
        });
      });

      this.loading = false;
    });
  }

  submitForm(): void {
    this.saveLoading = true;

    let customerDto: SmallUpdateCustomerDto = Object.assign({},
      { customerIdKf: this.storageService.getUser() },
      this.personalInformationsForm.value);

    this.customerService.smallUpdateCustomer(customerDto, this.storageService.getToken().access_token).subscribe({
      next: () => {
        this.modalService.display(MODAL.SUCCESS, {
          title: 'Informations mises à jour',
          message: 'Vos informations ont bien été mises à jour.'
        });

        this.saveLoading = false;
      },
      error: (err) => {
        console.error(err);
        this.saveLoading = false;
      }
    });
  }

  openPasswdModal(): void {
    this.authService.forgottenPassword(this.personalInformationsForm.value.email, this.storageService.getToken().access_token).subscribe({
      next: () => {
        this.modalService.display(MODAL.SUCCESS, { title: 'Changement de mot de passe', message: 'Un e-mail de changement de mot de passe vous a été envoyé.' });
      }
    });
  }


}
