<form class="form-card" [formGroup]="form" (ngSubmit)="loginUser()">

  <header class="login-card-header">
    <img class="login-card-title" src="assets/img/login/login.svg">
    <h3 class="login-card-subtitle">Pour profiter de tous mes avantages …</h3>
  </header>

  <section class="login-card-body form-group">
    <div class="form-row">
      <div class="form-col">
        <label for="login-email" class="required">Mon email</label>
        <div class="input-icon">
          <svg (click)="loginEmail.focus()">
            <use xlink:href="assets/img/icons.svg#icon-login-email"></use>
          </svg>
          <input #loginEmail type="text" class="casto-input" name="email" id="login-email" formControlName="email">
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-col">
        <label for="login-password" class="required">Mot de passe</label>
        <div class="input-icon">
          <svg (click)="loginPassword.focus()">
            <use xlink:href="assets/img/icons.svg#icon-login-password"></use>
          </svg>
          <input #loginPassword type="{{ showPassword ? 'text' : 'password' }}" class="casto-input" name="password"
            id="login-password" formControlName="password" autocomplete="{{ showPassword ? 'off' : 'current-password' }}">
          <i class="fas" [ngClass]="{ 'fa-eye': !showPassword, 'fa-eye-slash': showPassword }" (click)="showPassword = !showPassword"></i>
        </div>
      </div>
    </div>

    <div class="error" *ngIf="errorMessage !== ''">{{errorMessage}}</div>

    <div class="form-row">
      <div class="form-col d-inline">
        <input type="checkbox" role="switch" id="login-forgot-me" name="forgot-me" formControlName="forgotMe">
        <label for="login-forgot-me">Se souvenir de moi</label>
      </div>

      <div class="form-col text-end">
        <a (click)="onSwitchContext(LoginContext.forgottenPassword)" class="casto-link forgotten-password-link"
          target="_blank">Mot de passe oublié</a>
      </div>
    </div>
  </section>

  <footer class="login-card-footer">
    <ng-casto-btn class="blue" [label]="'Je me connecte'" [disabled]="form.invalid" (click)="loginUser()"
      [loading]="loading"></ng-casto-btn>

    <p class="mt-3" *ngIf="responsive.isMobile">
      <small>
        Je n’ai pas encore d’espace !
        <a (click)="onSwitchContext(LoginContext.createCard)" class="casto-link">Je crée ma carte de fidélité</a>
      </small>
    </p>
  </footer>
</form>