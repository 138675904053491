<div class="main-container customer" [ngClass]="{ 'create-card': router.url === '/creation' }">
  <div class="container-fluid" id="top">
    <h1 class="title" *ngIf="step > 0 && step <= maxStep">
        <span class="icon">
          <div>
            <svg *ngIf="!isUpdate && step < 3">
              <use xlink:href="assets/img/icons.svg#icon-creation-carte"></use>
            </svg>
            <svg *ngIf="!isUpdate && step === 3">
          <use xlink:href="assets/img/icons.svg#icon-credit-card"></use>
            </svg>
          </div>
        </span>
      <span class="text" *ngIf="!isUpdate && step < 3">Création de votre carte</span>
      <span class="text" *ngIf="isUpdate">Modification de votre carte</span>
      <span class="text" *ngIf="!isUpdate && step === 3">Paiement</span>
      <span class="step">
          <ng-casto-btn class="btn-link"
                        (click)="submitForm()"
                        *ngIf='!requiredStepTwo() && this.step === 2 && !saveLoading && cardForm.valid'
                        [label]="'Passer cette étape'"></ng-casto-btn>
        {{ step }}/{{ maxStep }}
        </span>
    </h1>

    <ng-container *ngIf="step < 3">
      <div class="text-center" *ngIf="globalLoading">
        <ng-casto-loader></ng-casto-loader>
      </div>

      <form [formGroup]="cardForm" *ngIf="!globalLoading">
        <ng-container *ngIf="step === 1">
          <div class="row" id="cardType">
            <ng-casto-common-create-card-type *ngIf="!isUpdate"
                                              [form]="cardForm"
                                              [isUpgrade]="isUpgrade"
                                              [context]="context"></ng-casto-common-create-card-type>
          </div>
          <div class="row" id="customerType">
            <ng-casto-common-create-card-customer-type [form]="cardForm" [context]="context" [existingCardStatus]="existingCardStatus"></ng-casto-common-create-card-customer-type>
          </div>
          <div class="row" id="personalInformations">
            <ng-casto-common-create-card-personal-informations [isEmailSearched]="isEmailSearched"
                                                               [form]="cardForm"
                                                               [defaultEmail]="email"></ng-casto-common-create-card-personal-informations>
          </div>
          <div class="row" id="communicationPreferences">
            <ng-casto-common-create-card-communication-preferences [marketingConsentForm]="cardForm.get('marketingConsent')"></ng-casto-common-create-card-communication-preferences>
          </div>
          <div class="row" id="termsConditions" *ngIf="existingCardStatus === FidelityCardTypeCh.Aucune || this.isUpgrade">
            <ng-casto-common-create-card-terms-conditions [form]="cardForm"></ng-casto-common-create-card-terms-conditions>
          </div>
        </ng-container>

        <ng-container *ngIf="step === 2">
          <div class="row" id="projects">
            <ng-casto-common-create-card-projects [projectsActive]="projectsActive"
                                                  [context]="context"
                                                  [isUpgrade]="isUpgrade"
                                                  [existingCardStatus]="existingCardStatus"
                                                  [form]="cardForm"></ng-casto-common-create-card-projects>
          </div>
        </ng-container>

        <div class="btn-container" *ngIf="step > 0">
          <ng-casto-btn class="outline"
                        *ngIf="step > 1"
                        [label]="'Retour'"
                        (click)="decreaseStep()"></ng-casto-btn>
          <ng-casto-btn class="blue"
                        *ngIf="step === 1"
                        [disabled]="!firstStepValid()"
                        [loading]="saveLoading"
                        label="Continuer"
                        (click)="increaseStep()"></ng-casto-btn>
          <ng-casto-btn class="blue"
                        *ngIf="step === 2"
                        [disabled]="cardForm.invalid"
                        [loading]="saveLoading"
                        label="Enregistrer"
                        (click)="submitForm()"
                        id="saveButton"></ng-casto-btn>
        </div>
      </form>
    </ng-container>

    <ng-casto-finalization-creation *ngIf="step === 3"
                                    [paytweakPayload]="paytweakPayload"
                                    [fidelityCard]="fidelityCard"
                                    [paymentMethod]="PaymentMethodEnum.DIRECT"
                                    [context]="context"
                                    (finish)="increaseStep()"></ng-casto-finalization-creation>

    <ng-casto-card-create *ngIf="step === 4"
                          [cardIsPremium]="infoNewCard.cardIsPremium"
                          [isUpgrade]="isUpgrade"
                          [context]="context"
                          [fidelityCard]="fidelityCard"></ng-casto-card-create>
  </div>
</div>
