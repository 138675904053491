<header>
  <div class="wrap">
    <div class="text">
      <h1 class="title mt-3">
        <span class="text">Activation de la carte</span>
      </h1>
      <h2 class="modal-subtitle">Êtes-vous sûr de vouloir activer la carte <strong>{{cardNumber}}</strong> ?</h2>
    </div>
    <div class="header-actions">
      <button class="close" (click)="modalService.close()" title="Fermer">
        <span class="icon-view-close" aria-hidden="true">
          <svg>
            <use xlink:href="assets/img/icons.svg#icon-view-close"></use>
          </svg>
        </span>
        <span>Fermer</span>
      </button>
    </div>
  </div>
</header>
<div class="main">
  <footer class="popin-footer">
    <ng-casto-btn class="outline" label="Annuler" (click)="modalService.close()"></ng-casto-btn>
    <ng-casto-btn class="blue" [loading]="loading" label="Confirmer l'activation" (click)="confirmCardActivation()"></ng-casto-btn>
  </footer>
</div>
