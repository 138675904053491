import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import { FidelityCardService as ColleagueFidelityCardService } from '@proxy/user-casto/fidelity-card';
import { FidelityCardService } from 'src/publicproxy/src/proxy/public/fidelity-card';
import { Context } from 'src/app/utils/context';

@Component({
  selector: 'ng-casto-activate-card-confirmation',
  templateUrl: './activate-card-confirmation.component.html'
})
export class ActivateCardConfirmationComponent implements OnInit {
  cardNumber: string;
  customerId: string;
  token: string;
  context: Context;
  loading: boolean;

  constructor(public modalService:ModalService,
    private colleagueFidelityCardService: ColleagueFidelityCardService,
    private fidelityCardService: FidelityCardService,) {}

  ngOnInit(): void {
    this.cardNumber = this.modalService.currentDatas.cardNumber;
    this.customerId = this.modalService.currentDatas.customerId;
    this.token = this.modalService.currentDatas.registeredToken;
    this.context = this.modalService.currentDatas.context;
  }

  confirmCardActivation(): void {
    this.loading = true;
    switch (this.context) {
      case Context.Colleague:
        this.colleagueFidelityCardService.reactivatedCard(this.customerId).subscribe({
          next: () => {
            this.modalService.close();
            this.loading = false;
            window.location.reload();
          },
          error: (err) => {
            console.error(err);
            this.modalService.close();
            this.loading = false;
          }
        });
        break;
      case Context.Customer:
        this.fidelityCardService.reactivatedCard(this.token).subscribe({
          next: () => {
            this.modalService.close();
            this.loading = false;
            window.location.reload();
          },
          error: (err) => {
            console.error(err);
            this.modalService.close();
            this.loading = false;
          }
        });
        break;
    }
  }
}
