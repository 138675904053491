<div class="form-card form-card-large" *ngIf="form" [formGroup]="form">
  <h2 class="form-title">
    <span class="icon"></span>
    <span  *ngIf="form.get('cardIsPremium')?.value">Conditions générales de vente</span>
    <span  *ngIf="!form.get('cardIsPremium')?.value">Conditions générales d'utilisation</span>
  </h2>

  <div class="form-group">
    <div class="form-row form-inline">
      <input type="checkbox" name="cguValidated" formControlName="cguValidated" id="cguValidated" value="cguValidated" />
      <label for="cguValidated" class="required">
        <p *ngIf="form.get('cardIsPremium')?.value">J'ai lu et j'accepte les <a target="_blank" href="{{cgvUrl}}">conditions générales de vente</a>.</p>
        <p *ngIf="!form.get('cardIsPremium')?.value">J'ai lu et j'accepte les <a target="_blank" href="{{cguUrl}}">conditions générales d'utilisation</a>.</p>
      </label>
    </div>
  </div>
</div>