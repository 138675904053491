import { RestService, Rest } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { UpgradeDowngradeColleagueCard } from '../application/contracts/models';
import type { AddFidelityCardCustomerDto, CardNumberSearchDto, CustomerCheetahDto, CustomerDto, CustomerKfOnlyDto, MoveCardResultDto, RenewPremiumCard, SearchCheetahCustomerDto, SearchCustomerDto, SmallUpdateCustomerDto, UpdateCustomerCompleteDto } from '../../dto-common-website/customers/models';
import type { FidelityCardDto, InfoReturnCreateFidelityCardDto, PointInfoDto } from '../../dto-common-website/fidelity-card/models';
import type { UpdateCustomerMarketingConsentKfDto } from '../../kingfisher-api/models/models';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  apiName = 'Default';
  

  createFidelityCard = (customerInfo: AddFidelityCardCustomerDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, InfoReturnCreateFidelityCardDto>({
      method: 'POST',
      url: '/api/app/customer/fidelity-card',
      body: customerInfo,
    },
    { apiName: this.apiName,...config });
  

  forceColleagueFreeCard = (infoCustomer: UpgradeDowngradeColleagueCard, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/customer/force-colleague-free-card',
      body: infoCustomer,
    },
    { apiName: this.apiName,...config });
  

  forceColleaguePremiumCard = (infoCustomer: UpgradeDowngradeColleagueCard, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/customer/force-colleague-premium-card',
      body: infoCustomer,
    },
    { apiName: this.apiName,...config });
  

  forceUpgradePremiumCard = (infoCustomer: UpgradeDowngradeColleagueCard, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/customer/force-upgrade-premium-card',
      body: infoCustomer,
    },
    { apiName: this.apiName,...config });
  

  getCheetahList = (searchCustomerData: SearchCheetahCustomerDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerCheetahDto[]>({
      method: 'GET',
      url: '/api/app/customer/cheetah-list',
      params: { cardNumber: searchCustomerData.cardNumber, mail: searchCustomerData.mail },
    },
    { apiName: this.apiName,...config });
  

  getCustomer = (idCustomer: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerDto>({
      method: 'GET',
      url: '/api/app/customer/customer',
      params: { idCustomer },
    },
    { apiName: this.apiName,...config });
  

  getCustomerByCheetahId = (cheetahId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerCheetahDto>({
      method: 'GET',
      url: `/api/app/customer/customer-by-cheetah-id/${cheetahId}`,
    },
    { apiName: this.apiName,...config });
  

  getCustomerByEmail = (customerEmail: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerDto>({
      method: 'GET',
      url: '/api/app/customer/customer-by-email',
      params: { customerEmail },
    },
    { apiName: this.apiName,...config });
  

  getFidelityCard = (idCustomer: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FidelityCardDto>({
      method: 'GET',
      url: '/api/app/customer/fidelity-card',
      params: { idCustomer },
    },
    { apiName: this.apiName,...config });
  

  getList = (input: PagedAndSortedResultRequestDto, searchCustomerData: SearchCustomerDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomerKfOnlyDto>>({
      method: 'GET',
      url: '/api/app/customer',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, cardNumber: searchCustomerData.cardNumber, mail: searchCustomerData.mail, phoneNumber: searchCustomerData.phoneNumber, name: searchCustomerData.name, postalCode: searchCustomerData.postalCode },
    },
    { apiName: this.apiName,...config });
  

  getPointInfo = (idCustomer: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PointInfoDto>({
      method: 'GET',
      url: '/api/app/customer/point-info',
      params: { idCustomer },
    },
    { apiName: this.apiName,...config });
  

  moveCardToCustomerByIdCustomerAndCardNumberAndIdsCustomer = (idCustomer: string, cardNumber: string, idsCustomer: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, MoveCardResultDto>({
      method: 'PUT',
      url: '/api/app/customer/move-card-to-customer',
      params: { idCustomer, cardNumber },
      body: idsCustomer,
    },
    { apiName: this.apiName,...config });
  

  renewPremiumCard = (infoCustomer: RenewPremiumCard, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/customer/renew-premium-card',
      body: infoCustomer,
    },
    { apiName: this.apiName,...config });
  

  searchByCardNumberByCustomerCardNumber = (customerCardNumber: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CardNumberSearchDto>({
      method: 'POST',
      url: '/api/app/customer/search-by-card-number',
      params: { customerCardNumber },
    },
    { apiName: this.apiName,...config });
  

  smallCreateCustomer = (newInfoCustomer: SmallUpdateCustomerDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/customer/small-create-customer',
      body: newInfoCustomer,
    },
    { apiName: this.apiName,...config });
  

  smallUpdateCustomer = (newInfoCustomer: SmallUpdateCustomerDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/customer/small-update-customer',
      body: newInfoCustomer,
    },
    { apiName: this.apiName,...config });
  

  updateCustomer = (newInfoCustomer: UpdateCustomerCompleteDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/customer/customer',
      body: newInfoCustomer,
    },
    { apiName: this.apiName,...config });
  

  updateMarketingConsentCustomer = (newInfoCustomer: UpdateCustomerMarketingConsentKfDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/customer/marketing-consent-customer',
      body: newInfoCustomer,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
