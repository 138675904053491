import { ConfigStateService } from '@abp/ng.core';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FidelityCardTypeCh } from '@proxy/cheetah/client/enum';
import { MODAL, ModalService } from 'src/app/modals/modal.service';
import { Context } from 'src/app/utils/context';
import { BooleanParserService } from '../../../../_services/boolean-parser.service';
import { ResponsiveService } from '../../../../_services/responsive.service';

@Component({
  selector: 'ng-casto-common-create-card-type',
  templateUrl: './card-type.component.html'
})
export class CreateCardTypeComponent {

  @Input() form: FormGroup;
  @Input() isUpgrade: boolean;
  @Input() context: Context;
  type: FidelityCardTypeCh = null;

  protected readonly FidelityCardTypeCh = FidelityCardTypeCh;

  constructor(public config: ConfigStateService,
    private modalService: ModalService,
    public responsive: ResponsiveService,
    public booleanParser: BooleanParserService) { }

  selectCardType(type: FidelityCardTypeCh): void {
    this.type = type;
  }

  seeAdvantages(type: FidelityCardTypeCh): void {
    this.modalService.display(MODAL.CARD_INFOS, { type: type, context: this.context });
  }
}
