import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FidelityCardTypeCh } from '@proxy/cheetah/client/enum';
import { PaymentMethodEnum } from '../../../../utils/payment-method.enum';
import { Context } from 'src/app/utils/context';

@Component({
  selector: 'ng-casto-payment-contribution-choices',
  templateUrl: './payment-contribution-choices.component.html'
})
export class PaymentContributionChoicesComponent implements OnInit {
  @Input() isColleague: boolean;
  @Input() context: Context = Context.Colleague;
  @Input() step: number;
  @Input() maxStep: number;
  @Input() labelSave?: string;
  @Input() labelCancel?: string;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<PaymentMethodEnum> = new EventEmitter<PaymentMethodEnum>();

  paymentMethodSelected: PaymentMethodEnum = null;

  protected readonly FidelityCardTypeCh = FidelityCardTypeCh;
  protected readonly PaymentMethod = PaymentMethodEnum;

  ngOnInit(): void {
    if (!this.labelCancel) {
      this.labelCancel = 'Retour';
    }

    if (!this.labelSave) {
      this.labelSave = 'Enregistrer';
    }
  }

  choosePayment(type: PaymentMethodEnum): void {
    this.paymentMethodSelected = type;
  }

  onSave(): void {
    this.save.emit(this.paymentMethodSelected);
  }

  onCancel(): void {
    this.cancel.emit();
  }

  isCustomer(): boolean {
    return this.context === Context.Customer;
  }
}
