<div class="form-card">
  <h2 class="form-title">
      <span class="icon">
      </span>
    Mes points

    <a (click)="event()"
       *ngIf="cardType !== FidelityCardTypeCh.PremiumWaiting && context !== Context.Customer"
       [routerLink]="[ '/clients/historique/' + customerId ]"
       [queryParams]="{tab: 'points'}">Voir l’historique</a>
  </h2>

  <div class="no-discount" *ngIf="cardType === FidelityCardTypeCh.PremiumWaiting">
    <svg>
      <use xlink:href="assets/img/icons.svg#icon-discount"></use>
    </svg>
    <p>Vous pourrez voir vos points une fois votre carte validée.</p>
  </div>

  <ng-container *ngIf="cardType !== FidelityCardTypeCh.PremiumWaiting">
    <div class="progress-points">
      <div class="min-points"><span class="nb">0</span> pt</div>
      <div class="max-points">
        <span class="nb">{{customerPoints?.maxPointForNextStep | number}}</span>
        pt{{customerPoints?.maxPointForNextStep > 1 ? 's' : ''}}
      </div>
      <canvas id="chart"></canvas>
      <div id="actual-points" [style]="'left:' + progressBarPlugin?.ptsPos + 'px'">
        <span class="text">
          <span class="nb">{{customerPoints?.numberOfPoint | number}}</span>
          pt{{(customerPoints?.numberOfPoint > 1 || customerPoints?.numberOfPoint < -1) ? 's' : ''}}
        </span>
      </div>
    </div>
  </ng-container>

  <h2 class="form-title mt-3"><span class="icon"></span>Mes récompenses fidélité</h2>

  <div class="discounts">
    <div class="text-center" *ngIf="isLoading">
      <ng-casto-loader></ng-casto-loader>
    </div>

    <div class="discount" *ngIf="!isLoading">
      <div class="discount-body" *ngIf="lastValidVoucher">
        <div class="discount-title" *ngIf="lastValidVoucher">
          <div class="discount-title-nb">{{ lastValidVoucher.title }}</div>
          <div class="discount-title-description">{{ lastValidVoucher.label }}</div>
        </div>

        <div class="discount-description">
          <div class="discount-description-condition" *ngIf="lastValidVoucher">
            À utiliser en magasin. Voir conditions sur <a href="https://castorama.fr/" target="_blank">castorama.fr</a>.
          </div>

          <div class="discount-description-expiration" *ngIf="lastValidVoucher">
            Expire le <b>{{ (lastValidVoucher.dateEnd | date:'dd/MM/YYYY') ?? '-' }}</b>.
          </div>
        </div>
      </div>

      <div class="no-discount" *ngIf="!lastValidVoucher">
        <svg>
          <use xlink:href="assets/img/icons.svg#icon-discount"></use>
        </svg>
        <p *ngIf="!isCustomer()">Le client ne possède pas de récompenses actives actuellement !</p>
        <p *ngIf="isCustomer()">Vous ne possédez pas de récompenses actives actuellement !</p>
      </div>

      <ng-casto-btn class="blue"
                    *ngIf="this.customerPoints?.discountsList?.offerCouponsList?.length > 0"
                    [label]="'Voir tous les coupons (' + countVouchers() + ')'"
                    (click)="seeVouchers()"></ng-casto-btn>
    </div>
  </div>
  
</div>
