<header>
    <div class="wrap">
      <div class="text">
        <h1 class="title mt-3">
          <span class="icon">
            <div id="icon">
              
            </div>
          </span>
          <span class="text">{{ this.title }}</span>
        </h1>
        <div>
          <div>
            <p>
              <span class="text-center" id="barcode"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="header-actions">
        <button class="close" (click)="modalService.close()" title="Fermer">
          <span class="icon-view-close" aria-hidden="true">
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-view-close"></use>
            </svg>
          </span>
          <span>Fermer</span>
        </button>
      </div>
    </div>
  </header>
  <div class="main">
    <footer class="popin-footer">
      <ng-casto-btn class="outline" label="Fermer" (click)="modalService.close()"></ng-casto-btn>
      <ng-casto-btn class="blue" label="Imprimer" (click)="print()"></ng-casto-btn>
    </footer>
  </div>
  