import { Component, EventEmitter, Output } from '@angular/core';
import { ResponsiveService } from '../../../../_services/responsive.service';
import { LoginContextEnum } from '../login.component';

@Component({
  selector: 'ng-casto-customer-create-card-info',
  templateUrl: './create-card-info.component.html'
})
export class CustomerCreateCardInfoComponent {
  @Output() switchContext = new EventEmitter<LoginContextEnum>();

  protected readonly LoginContext = LoginContextEnum;

  constructor(public responsive: ResponsiveService) {}

  onSwitchContext(loginContext: LoginContextEnum) {
    this.switchContext.emit(loginContext);
  }
}
