<div class="form-card" [formGroup]="form" [ngClass]="{disabled: form.value?.cardType === ''}">
  <h2 class="form-title">
    <span class="icon">
      <!--svg>
        <use xlink:href="assets/img/icons.svg#icon-cadenas"></use>
      </svg-->
    </span>
    Situation
  </h2>
  <div class="form-group">
    <div class="form-row selector-row">
      <div class="casto-selector">
        <input type="radio" name="memberType" formControlName="memberType" id="particulier" [value]="MemberTypeCh.Particulier">
        <label for="particulier">
          <img src="assets/img/particulier.png" alt="">
          <p>Particulier</p>
        </label>
      </div>
      <div class="casto-selector">
        <input type="radio" name="memberType" formControlName="memberType" id="professionnel" [value]="MemberTypeCh.Professionnel">
        <label for="professionnel">
          <img src="assets/img/professionnel.png" alt="">
          <p>Professionnel</p>
        </label>
      </div>
    </div>
  </div>

  <h3 class="toggle" [ngClass]="{active: projectsActive}" (click)="toggleProjects()">Projets</h3>
  <div class="form-group" *ngIf="projectsActive">
    <div class="form-row">
      <div class="form-col">
        <div class="form-row form-inline">
          <label [ngClass]="{required: isRequired('habitationType')}">Vous habitez :</label>

          <div class="form-row selector-row">
            <div class="casto-selector project-selector">
              <input type="radio" name="habitationType" formControlName="habitationType" id="house" [value]="1">
              <label for="house">
                <svg>
                  <use xlink:href="assets/img/icons.svg#icon-project-maison"></use>
                </svg>
                <p>Maison</p>
              </label>
            </div>
            <div class="casto-selector project-selector">
              <input type="radio" name="habitationType" formControlName="habitationType" id="appartment" [value]="2">
              <label for="appartment">
                <svg>
                  <use xlink:href="assets/img/icons.svg#icon-project-appartement"></use>
                </svg>
                <p>Appartement</p>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-col">
        <div class="form-row form-inline">
          <label [ngClass]="{required: isRequired('customerPropertySituation')}">Vous êtes :</label>

          <div class="form-row selector-row">
            <div class="casto-selector project-selector">
              <input type="radio" name="customerPropertySituation" formControlName="customerPropertySituation" id="owner" [value]="1">
              <label for="owner">
                <svg>
                  <use xlink:href="assets/img/icons.svg#icon-project-proprietaire"></use>
                </svg>
                <p>Propriétaire</p>
              </label>
            </div>
            <div class="casto-selector project-selector">
              <input type="radio" name="customerPropertySituation" formControlName="customerPropertySituation" id="tenant" [value]="2">
              <label for="tenant">
                <svg>
                  <use xlink:href="assets/img/icons.svg#icon-project-locataire"></use>
                </svg>
                <p>Locataire</p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>