import { AccountConfigModule } from '@abp/ng.account/config';
import { CoreModule } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import { IdentityConfigModule } from '@abp/ng.identity/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import { ThemeLeptonXModule } from '@abp/ng.theme.lepton-x';
import { SideMenuLayoutModule } from '@abp/ng.theme.lepton-x/layouts';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { ModalsComponent } from './modals/modals.component';
import { LoaderComponent } from './ui/loader/loader.component';
import { LogoutComponent } from './modals/logout/logout.component';
import { BtnComponent } from './ui/btn/btn.component';
import { HomeComponent } from './pages/customer/home/home.component';
import { HeaderComponent } from './ui/header/header.component';
import { CustomerPointsComponent } from './pages/common/customer-points/customer-points.component';
import { OfferComponent } from './pages/customer/home/offer/offer.component';
import { CreateCardComponent } from './pages/customer/create-card/create-card.component';
import { CreateCardTypeComponent } from './pages/common/create-card/card-type/card-type.component';
import { CreateCardCustomerTypeComponent } from './pages/common/create-card/customer-type/customer-type.component';
import { CreateCardCommunicationPreferencesComponent } from './pages/common/create-card/communication-preferences/communication-preferences.component';
import { CreateCardPersonalInformationsComponent } from './pages/common/create-card/personal-informations/personal-informations.component';
import { CreateCardProjectsComponent } from './pages/common/create-card/projects/projects.component';
import { CustomerInformationsComponent } from './pages/customer/customer-informations/customer-informations.component';
import { ChangePasswordComponent } from './modals/change-password/change-password.component';
import { CreateCardSituationComponent } from './pages/common/create-card/situation/situation.component';
import { CustomerHistoryComponent } from './pages/customer/customer-history/customer-history.component';
import { CustomerPointsHistoryComponent } from './pages/common/customer-history/customer-points-history/customer-points-history.component';
import { CustomerOrderHistoryComponent } from './pages/common/customer-history/customer-order-history/customer-order-history.component';
import { CustomerLoginComponent } from './pages/customer/login/login.component';
import { OrderDetailComponent } from './modals/order-detail/order-detail.component';
import { httpInterceptorProviders } from './_helpers/http.interceptor';
import { AuthGuardService } from './_services/auth-guard.service';
import { GTM } from './_services/gtm.service';
import { SuccessComponent } from './modals/success/success.component';
import { PaymentMethodComponent } from './modals/payment-method/payment-method.component';
import { ResendPaymentLinkSmsComponent } from './modals/resend-payment-link-sms/resend-payment-link-sms.component';
import { CustomerVouchersComponent } from './pages/customer/customer-vouchers/customer-vouchers.component';
import { VouchersComponent } from './modals/vouchers/vouchers.component';
import { CustomerRegistrationComponent } from './pages/customer/login/registration/registration.component';
import { CustomerLoginFormComponent } from './pages/customer/login/login-form/login-form.component';
import { CustomerForgottenPasswordComponent } from './pages/customer/login/forgotten-password/forgotten-password.component';
import { CustomerCreateCardInfoComponent } from './pages/customer/login/create-card-info/create-card-info.component';
import { CardInfosComponent } from './modals/card-infos/card-infos.component';
import { CustomerCardInformationComponent } from './pages/common/customer-card-informations/customer-card-information.component';
import { CancelCardConfirmationComponent } from './modals/cancel-card-confirmation/cancel-card-confirmation.component';
import { PaymentContributionChoicesComponent } from './pages/common/create-card/payment-contribution-choices/payment-contribution-choices.component';
import { FinalizationCreationComponent } from './pages/common/create-card/finalization-creation/finalization-creation.component';
import { CardCreateComponent } from './pages/common/create-card/card-create/card-create.component';
import { SafePipeUrl } from './_helpers/safePipeUrl';
import { VoucherComponent } from './modals/vouchers/voucher/voucher.component';
import { EnvServiceProvider } from './env.service.provider';
import { ActivateCardConfirmationComponent } from './modals/activate-card-confirmation/activate-card-confirmation.component';
import { CreateCardTermsConditionsComponent } from './pages/common/create-card/terms-conditions/terms-conditions.component';
import { DisplayBarcodeComponent } from './modals/display-barcode/display-barcode.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BtnComponent,
    HeaderComponent,
    CustomerLoginComponent,
    CustomerRegistrationComponent,
    CustomerLoginFormComponent,
    CustomerForgottenPasswordComponent,
    CustomerCreateCardInfoComponent,
    OfferComponent,
    CreateCardComponent,
    CreateCardTypeComponent,
    CreateCardCustomerTypeComponent,
    CreateCardCommunicationPreferencesComponent,
    CreateCardTermsConditionsComponent,
    CreateCardPersonalInformationsComponent,
    CreateCardProjectsComponent,
    CustomerInformationsComponent,
    CreateCardSituationComponent,
    CustomerHistoryComponent,
    CustomerOrderHistoryComponent,
    CustomerPointsHistoryComponent,
    CustomerVouchersComponent,
    CustomerCardInformationComponent,
    PaymentContributionChoicesComponent,
    FinalizationCreationComponent,
    CardCreateComponent,

    /** Modals */
    ModalsComponent,
    LogoutComponent,
    ChangePasswordComponent,
    OrderDetailComponent,
    SuccessComponent,
    PaymentMethodComponent,
    ResendPaymentLinkSmsComponent,
    VouchersComponent,
    VoucherComponent,
    CardInfosComponent,
    CancelCardConfirmationComponent,
    ActivateCardConfirmationComponent,
    DisplayBarcodeComponent,
    /** Fin modals */

    LoaderComponent,
    CustomerPointsComponent,
    SafePipeUrl
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot(),
    AccountConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    FeatureManagementModule.forRoot()
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    httpInterceptorProviders,
    AuthGuardService,
    EnvServiceProvider,
    GTM,
    { provide: LOCALE_ID, useValue: 'fr' }, 
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
