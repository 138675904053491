<div class="login-container">
  <header id="login-header">
    <img id="login-title" src="/assets/img/castorama-logo-white.svg" alt="">
    <h2 id="login-subtitle">Votre espace fidélité</h2>
  </header>

  <!-- Call to actions (only mobile) -->
  <section id="call-to-actions" *ngIf="contexts.length === 0">
    <ng-casto-btn class="blue"
                  [label]="'Je me connecte'"
                  (click)="switchContext(LoginContext.login)"></ng-casto-btn>

    <ng-casto-btn class="white"
                  [label]="'Je crée ma carte'"
                  (click)="switchContext(LoginContext.createCard)"></ng-casto-btn>
  </section>

  <div class="container" *ngIf="contexts.length > 0">

    <!-- Login -->
    <ng-casto-customer-login-form class="login-card"
                                  *ngIf="contexts.includes(LoginContext.login)"
                                  (switchContext)="switchContext($event)"></ng-casto-customer-login-form>


    <!-- Forgotten password -->
    <ng-casto-customer-forgotten-password class="login-card"
                                          *ngIf="contexts.includes(LoginContext.forgottenPassword)"
                                          (switchContext)="switchContext($event)"></ng-casto-customer-forgotten-password>


    <!-- Create card -->
    <ng-casto-customer-create-card-info class="login-card"
                                        *ngIf="contexts.includes(LoginContext.createCard)"
                                        (switchContext)="switchContext($event)"></ng-casto-customer-create-card-info>

    <!-- Create card form -->
    <ng-casto-customer-registration class="login-card"
                                    *ngIf="contexts.includes(LoginContext.registation)"
                                    (switchContext)="switchContext($event)"></ng-casto-customer-registration>

  </div>
</div>
