<div class="form-card" [ngClass]="{premium: cardFidelity?.type === FidelityCardType.Premium || cardFidelity?.type === FidelityCardType.PremiumWaiting, free: cardFidelity?.type === FidelityCardType.Gratuite}">
  <div *ngIf="!loading" class="form-group">
    <div class="form-row">
      <div class="form-col card-container">
        <svg *ngIf="cardFidelity?.type === FidelityCardType.Premium || cardFidelity?.type === FidelityCardType.PremiumWaiting">
          <use xlink:href="assets/img/icons.svg#ccastomax"></use>
        </svg>
        <svg *ngIf="cardFidelity?.type === FidelityCardType.Gratuite">
          <use xlink:href="assets/img/icons.svg#ccasto"></use>
        </svg>
      </div>
      <div class="form-col card-description">
        <div>
          <div class="card-state active" *ngIf="cardFidelity?.cardEnabled && cardFidelity?.type !== FidelityCardType.PremiumWaiting">Active</div>
          <div class="card-state inactive" *ngIf="cardFidelity?.cardEnabled && cardFidelity?.type === FidelityCardType.PremiumWaiting && !customerInfos?.cardIsPaidButNotActiveYet">En attente de paiement</div>
          <div class="card-state inactive" *ngIf="cardFidelity?.cardEnabled && cardFidelity?.type === FidelityCardType.PremiumWaiting && customerInfos?.cardIsPaidButNotActiveYet">Payée - en cours d'activation</div>
          <div class="card-state inactive" *ngIf="!cardFidelity?.cardEnabled">Inactive</div>
        </div>
        <div class="card-name"
             *ngIf="cardFidelity?.type === FidelityCardType.Premium
                    || cardFidelity?.type === FidelityCardType.PremiumWaiting">C-Casto Max</div>
        <div class="card-name" *ngIf="cardFidelity?.type === FidelityCardType.Gratuite">C-Casto</div>
        <div>Carte de fidélité</div>
      </div>
    </div>

    <div class="card-infos">
      <p>Carte n°{{cardFidelity?.cardNumber}}</p>
      <p>Créée le <b>{{cardFidelity?.creationDate}}</b></p>
      <p *ngIf="cardFidelity?.type === FidelityCardType.Premium && !customerInfos?.isColleague">Expire le <b>{{cardFidelity?.finalExpirationDate}}</b></p>
    </div>

    <div class="form-row" *ngIf="!isReadOnly">
      <div class="button-container">

        <ng-casto-btn *ngIf="isCustomer()" label="Ajouter sur mon smartphone" class="outline" (click)="addToWallet()"></ng-casto-btn>
        <ng-casto-btn label="Afficher le code barre" class="blue" (click)="printCard()"></ng-casto-btn>

        <ng-container *ngIf="cardFidelity?.cardEnabled">
          <ng-casto-btn *ngIf="cardFidelity?.type === FidelityCardType.Premium && displayRenewBtn()"
                        label="Renouveler"
                        class="outline"
                        (click)="renewCard()"></ng-casto-btn>

          <ng-casto-btn *ngIf="displayPayCardBtn()"
                        label="Payer"
                        class="blue"
                        (click)="payCard()"></ng-casto-btn>

          <ng-casto-btn *ngIf="cardFidelity?.type === FidelityCardType.Gratuite && !customerInfos?.isColleague && !isCustomer()"
                        label="Passer sur la carte C-Casto Max"
                        class="yellow"
                        (click)="goToUpgradeCard()"></ng-casto-btn>
          <ng-container *abpPermission="'GestionClients.CreationCarteCollegue'">
            <ng-casto-btn *ngIf="cardFidelity?.type === FidelityCardType.Gratuite && customerInfos?.isColleague"
                          label="Passer sur la carte C-Casto Max"
                          class="yellow" (click)="goToUpgradeCard()"></ng-casto-btn>
          </ng-container>

          <ng-container *abpPermission="'GestionClients.CreationCarteCollegue'">
            <ng-casto-btn *ngIf="cardFidelity?.type === FidelityCardType.Premium
                                             && config.getSetting('CastoramaFidelite.ActivatedFreeCardCreation')
                                             && customerInfos?.isColleague"
                          label="Passer sur C-Casto"
                          class="blue"
                          (click)="downgradeCard()"></ng-casto-btn>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!cardFidelity?.cardEnabled">
          <ng-casto-btn label="Activer la carte" class="blue" (click)="activateCard()"></ng-casto-btn>
        </ng-container>

        <a (click)="cancelCard()"
           *ngIf="cardFidelity.cardEnabled"
           class="casto-link"><i class="fas fa-trash-alt"></i> Résilier la carte de fidélité</a>
      </div>
    </div>

    <div *ngIf="cardEmail !== null && cardEmail !== undefined">
      <br>
      <span>Email associé : {{ cardEmail }}</span>
    </div>
  </div>

  <div class="text-center" *ngIf="loading">
    <ng-casto-loader></ng-casto-loader>
  </div>
</div>
