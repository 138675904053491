import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ng-casto-btn',
  templateUrl: './btn.component.html'
})
export class BtnComponent {

  @HostBinding("class.isLoading") get isLoading(){return this.loading};
  @HostBinding("class.disabled") get isDisabled(){return this.disabled};

  @Input() disabled:boolean;
  @Input() loading:boolean;
  @Input() label:string;

  constructor() { }

  disabledAreaClick(e:Event){
    e.stopPropagation();
    e.preventDefault();
  }
}
