<div class="container">
  <div class="casto-card">
    <svg class="card-icon" *ngIf="!cardIsPremium">
      <use xlink:href="assets/img/icons.svg#ccasto"></use>
    </svg>

    <svg class="card-icon" *ngIf="cardIsPremium">
      <use xlink:href="assets/img/icons.svg#ccastomax"></use>
    </svg>

    <div class="success-icon">
      <svg>
        <use xlink:href="assets/img/icons.svg#icon-success"></use>
      </svg>
    </div>
  </div>

  <div class="message">
    <h2 *ngIf="cardIsPremium">La carte C-Casto Max a bien été créée</h2>
    <h2 *ngIf="!cardIsPremium">La carte C-Casto a bien été créée</h2>
    <p>
      <span *ngIf="!isUpgrade">La carte de fidélité <strong>N° {{ fidelityCard.cardNumber }}</strong> est maintenant active.</span>
      <span *ngIf="isUpgrade">La carte de fidélité <strong>N° {{ fidelityCard.cardNumber }}</strong> a bien été basculée sur la carte C-Casto Max.</span>
      <span *ngIf="fidelityCard.finalExpirationDate">Elle expirera le <strong>{{ fidelityCard.finalExpirationDate }}</strong>.</span>
    </p>
    <p *ngIf="!cardIsPremium && !isUpgrade">
      <span *ngIf="!isCustomer()">Le client peut retrouver le détail de ses informations sur son espace fidélité.</span>
      <span *ngIf="isCustomer()">Vous pouvez retrouver le détail de vos informations sur votre espace fidélité.</span>
    </p>
    <p *ngIf="cardIsPremium && !isUpgrade">
      <span *ngIf="!isCustomer()">Un e-mail de confirmation sera ensuite envoyé au client avec son coupon de 5% de bienvenue.</span>
      <span *ngIf="isCustomer()">Un e-mail de confirmation vous sera ensuite envoyé avec votre coupon de 5% de bienvenue.</span>
    </p>
    <p *ngIf="isUpgrade">
      <span *ngIf="!isCustomer()">Un email de confirmation vient d’être envoyé au client.</span>
      <span *ngIf="isCustomer()">Un e-mail de confirmation vient de vous être envoyé.</span>
    </p>
  </div>

  <ng-casto-btn class="outline"
                [label]="isCustomer() ? 'Voir vos informations' : 'Voir la fiche client'"
                [routerLink]="['/clients/carte/' + this.fidelityCard.customerId]"></ng-casto-btn>
</div>
