import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentMethodEnum } from '../../../../utils/payment-method.enum';
import { FidelityCardTypeCh } from '@proxy/cheetah/client/enum';
import { FidelityCardDto } from '@proxy/dto-common-website/fidelity-card';
import { MODAL, ModalService } from '../../../../modals/modal.service';
import { Context } from 'src/app/utils/context';
import type { GetPaymentLinkPwDto, PaytweakRequestDto } from '@proxy/paytweak-api/dto';
import { PaytweakService as ColleaguePaytweakService } from '@proxy/user-casto/paytweak';
import { PaytweakService } from 'src/publicproxy/src/proxy/public/paytweak';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'ng-casto-finalization-creation',
  templateUrl: './finalization-creation.component.html'
})
export class FinalizationCreationComponent implements OnInit {
  @Input() paytweakPayload: PaytweakRequestDto;
  @Input() fidelityCard?: FidelityCardDto;
  @Input() paymentMethod: PaymentMethodEnum;
  @Input() context: Context = Context.Colleague;
  @Input() labelSave?: string;

  @Output() finish: EventEmitter<void> = new EventEmitter<void>();

  qrCodeBase64: string;

  paymentLoading: boolean;

  protected readonly FidelityCardTypeCh = FidelityCardTypeCh;
  protected readonly PaymentMethodEnum = PaymentMethodEnum;
  iFrameLink: string;

  constructor(private colleaguePaytweakService: ColleaguePaytweakService,
    private paytweakService: PaytweakService,
    private storageService: StorageService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.paymentLoading = true;

    if (!this.labelSave) {
      this.labelSave = 'Continuer';
    }

    switch (this.paymentMethod) {
      case PaymentMethodEnum.SMS:
        if (this.context === Context.Colleague) {
          this.colleaguePaytweakService.getPaymentSmsPaytweak(this.paytweakPayload)
            .subscribe(() => this.paymentLoading = false);
        }
        break;
      case PaymentMethodEnum.QR_CODE:
        if (this.context === Context.Colleague) {
          this.colleaguePaytweakService.getPaymentLinkPaytweak(this.paytweakPayload)
            .subscribe((res: GetPaymentLinkPwDto) => {
              this.qrCodeBase64 = res.qrCodeImageBase64;
              this.paymentLoading = false;
            });
        }
        break;
      case PaymentMethodEnum.CHECKOUT:
        if (this.context === Context.Colleague) {
          this.colleaguePaytweakService.sendPaymentCheckout(this.paytweakPayload.customerId)
            .subscribe(() => this.paymentLoading = false);
        }
        break;
      case PaymentMethodEnum.DIRECT:
        if (this.context === Context.Customer) {
          this.paytweakService.getPaymentLinkPaytweak(this.paytweakPayload, this.storageService.getToken().access_token)
            .subscribe((res: GetPaymentLinkPwDto) => {
              this.iFrameLink = res.url;
              this.paymentLoading = false;
            });
        }
        break;
    }
  }

  openModalSendSms() {
    this.modalService.display(MODAL.RESEND_PAYMENT_LINK_SMS, { paytweakPayload: this.paytweakPayload });
  }

  onFinish() {
    this.finish.emit();
  }

  isCustomer(): boolean {
    return this.context === Context.Customer;
  }
}
