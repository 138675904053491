import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../_services/auth.service';
import { LoginContextEnum } from '../login.component';

@Component({
  selector: 'ng-casto-customer-forgotten-password',
  templateUrl: './forgotten-password.component.html'
})
export class CustomerForgottenPasswordComponent implements OnInit {
  @Output() switchContext = new EventEmitter<LoginContextEnum>();

  form: FormGroup;
  loading: boolean = false;
  errorMessage = '';
  validMessage = '';

  protected readonly LoginContext = LoginContextEnum;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  onSwitchContext(loginContext: LoginContextEnum) {
    this.errorMessage = '';
    this.validMessage = '';

    this.switchContext.emit(loginContext);
  }

  forgottenPassword(): void {
    if(this.form.valid) {
      this.errorMessage = '';
      this.loading = true;

      this.authService.getAnonToken().subscribe({
        next: (data) => {
          this.authService.forgottenPassword(this.form.get('email').value, data.access_token).subscribe({
            next: () => {
              this.errorMessage = 'Si l\'adresse mail existe, vous allez recevoir un e-mail contenant un lien de récupération du mot de passe.';
              this.loading = false;
            },
            error: (err) => {
              console.error(err);
              this.errorMessage = err.error?.errors[0]?.detail;
              this.loading = false;
            }
          });
        }
      });
    }
  }
}
