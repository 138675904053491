import { AuthService, ConfigStateService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { FidelityCardTypeCh } from '@proxy/cheetah/client/enum';
import { PrintService } from 'src/app/_services/print.service';
import { StorageService } from 'src/app/_services/storage.service';
import { FidelityCardDto } from 'src/publicproxy/src/proxy/dto-common-website/fidelity-card';
import { Router } from '@angular/router';
import { ImageService } from 'src/publicproxy/src/proxy/public/images';
import { MODAL, ModalService } from 'src/app/modals/modal.service';
import { CustomerDto } from '@proxy/dto-common-website/customers';
import { CustomerService } from 'src/publicproxy/src/proxy/public/customer';
import { InfoForHomeDto } from 'src/publicproxy/src/proxy/dto-common-website/images';
import { Context } from 'src/app/utils/context';

@Component({
  selector: 'ng-casto-customer-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  FidelityCardTypeCh = FidelityCardTypeCh;
  cardInfos: FidelityCardDto;
  globalLoading: boolean = false;
  images: InfoForHomeDto;
  customerInfos: CustomerDto;
  public context: Context = Context.Customer;

  constructor(private authService: AuthService,
    private customerService: CustomerService,
    public storageService: StorageService,
    private print: PrintService,
    private imageService: ImageService,
    public config: ConfigStateService,
    private modalService: ModalService,
    private router: Router) { }

  ngOnInit() {
    this.globalLoading = true;
    this.customerService.getCustomer(this.storageService.getToken().access_token).subscribe({
      next: (e) => {
        this.customerInfos = e;

        if (e.cardNumber) {
          this.customerService.getFidelityCard(this.storageService.getToken().access_token).subscribe({
            next: (infos) => {
              this.cardInfos = infos;
              this.globalLoading = false;
            }, error: () => {
              this.globalLoading = false;
            }
          });
        } else {
          this.router.navigate(['/creation/']);
        }
      },
      error: (err) => {
        console.error(err);
        this.globalLoading = false;
      }
    });

    this.imageService.getInfoForHome().subscribe((res) => {
      this.images = res;
    });
  }

  login() {
    this.authService.navigateToLogin();
  }

  displayRenewBtn(): boolean {

    let today = new Date();

    //Conversion de la date d'expiration en Date javascript
    let parts = this.cardInfos.finalExpirationDate.split('/');
    let expirationDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));

    let dateToNotice = new Date();

    //Ajout de la date de notification en fonction du setting
    dateToNotice.setTime(expirationDate.getTime() - (parseInt(this.config.getSetting('CastoramaFidelite.NbDaysNoticeEndSubscriptionPaidCard')) * 24 * 60 * 60 * 1000))

    return today > dateToNotice;
  }

  goToUpgradeCard() {
    this.router.navigate(['/creation/'], { queryParams: { upgrade: true } });
  }

  openModalCardInfo() {
    this.modalService.display(MODAL.CARD_INFOS, { type: FidelityCardTypeCh.Premium, context: this.context });
  }

  displayPremiumInfos(): boolean {
    return this.cardInfos?.type !== FidelityCardTypeCh.Premium && this.cardInfos?.type !== FidelityCardTypeCh.PremiumWaiting;
  }

  goToItem(type: string): void {
    switch (type) {
      case 'cgu':
        window.open('https://www.castorama.fr/cgv-achat-en-ligne', '_blank').focus();
        break;
      case 'faq':
        window.open('https://www.castorama.fr/relation-client/faq', '_blank').focus();
        break;
      case 'contact':
        window.open('https://www.castorama.fr/services/contactez-nous', '_blank').focus();
        break;
      case 'cookies':
        window.open('https://www.castorama.fr/notre-politique-cookies', '_blank').focus();
        break;
      default:
        break;
    }
  }
}
