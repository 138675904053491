<form class="form-card" [formGroup]="form" (ngSubmit)="createCard()">
  <header class="login-card-header">
    <img class="login-card-title" src="assets/img/login/signin.svg">
    <h3 class="login-card-subtitle">Pour profiter de tous mes avantages …</h3>
  </header>

  <section class="login-card-body form-group">
    <div class="form-row">
      <div class="form-col">
        <label for="create-card-email" class="required">Adresse mail</label>
        <div class="input-icon">
          <svg (click)="createCardEmail.focus()">
            <use xlink:href="assets/img/icons.svg#icon-login-email"></use>
          </svg>
          <input #createCardEmail type="text" class="casto-input" name="email" id="create-card-email"
            formControlName="email">
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-col">
        <label for="create-card-first-name" class="required">Prénom</label>
        <div class="input-icon">
          <svg (click)="createCardFirstName.focus()">
            <use xlink:href="assets/img/icons.svg#icon-user"></use>
          </svg>
          <input #createCardFirstName type="text" class="casto-input" name="text" id="create-card-first-name"
            formControlName="givenName">
        </div>
      </div>

      <div class="form-col">
        <label for="create-card-last-name" class="required">Nom</label>
        <div class="input-icon">
          <svg (click)="createCardLastName.focus()">
            <use xlink:href="assets/img/icons.svg#icon-user"></use>
          </svg>
          <input #createCardLastName type="text" class="casto-input" name="text" id="create-card-last-name"
            formControlName="familyName">
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-col">
        <label for="create-card-password" class="required">Mot de passe</label>
        <div class="input-icon">
          <svg (click)="createCardPassword.focus()">
            <use xlink:href="assets/img/icons.svg#icon-login-password"></use>
          </svg>
          <input #createCardPassword type="{{ showPasswordCreate ? 'text' : 'password' }}" class="casto-input"
            name="password" id="create-card-password" formControlName="password"
            autocomplete="{{ showPasswordCreate ? 'off' : 'new-password' }}">
          <i class="fas" [ngClass]="{ 'fa-eye': !showPasswordCreate, 'fa-eye-slash': showPasswordCreate }"
            (click)="showPasswordCreate = !showPasswordCreate"></i>
        </div>
      </div>

      <div class="form-col">
        <label for="create-card-password" class="required">Confirmation du mot de passe</label>
        <div class="input-icon">
          <svg (click)="createCardConfirmationPassword.focus()">
            <use xlink:href="assets/img/icons.svg#icon-login-password"></use>
          </svg>
          <input #createCardConfirmationPassword type="{{ showPasswordConfirm ? 'text' : 'password' }}"
            class="casto-input" name="password" id="create-card-confirmation-password"
            formControlName="confirmationPassword" autocomplete="{{ showPasswordConfirm ? 'off' : 'new-password' }}">
          <i class="fas" [ngClass]="{ 'fa-eye': !showPasswordConfirm, 'fa-eye-slash': showPasswordConfirm }"
            (click)="showPasswordConfirm = !showPasswordConfirm"></i>
        </div>
      </div>
    </div>

    <!-- Check password -->
    <p class="text-center mt-2">
      Votre mot de passe doit contenir :
      <span [ngClass]="{
          'text-success': checkIfPasswordContainLowercase(),
          'text-danger': !checkIfPasswordContainLowercase()
      }">une minuscule</span>,
      <span [ngClass]="{
          'text-success': checkIfPasswordContainUppercase(),
          'text-danger': !checkIfPasswordContainUppercase()
      }">une majuscule</span>,
      <span [ngClass]="{
          'text-success': checkIfPasswordContainNumber(),
          'text-danger': !checkIfPasswordContainNumber()
      }">un chiffre</span>,
      <span [ngClass]="{
          'text-success': checkIfPasswordContainSpecialCharacter(),
          'text-danger': !checkIfPasswordContainSpecialCharacter()
      }">un caractère spécial (&#64;$!%*?&)</span>
      et fait au moins
      <span [ngClass]="{
          'text-success': checkIfPasswordHasMinLength(),
          'text-danger': !checkIfPasswordHasMinLength()
      }">13 caractères</span>.
    </p>
    <ng-container *ngIf="form.get('password').value?.length > 0 && form.get('confirmationPassword').value?.length > 0">
      <p *ngIf="checkIfPasswordsHasSame()" class="text-center text-success">Les mots de passe sont identiques.</p>
      <p *ngIf="!checkIfPasswordsHasSame()" class="text-center text-danger">Les mots de passe ne sont pas identiques.
      </p>
    </ng-container>

    <div class="error" *ngIf="errorMessage">{{ errorMessage }}</div>
  </section>

  <footer class="login-card-footer">
    <ng-casto-btn class="blue" [disabled]="form.invalid" (click)="createCard()" [loading]="loading"
      [label]="'Je crée ma carte'"></ng-casto-btn>

    <p class="mt-3">
      <small>
        <a (click)="onSwitchContext(LoginContext.login)" class="casto-link">Je me connecte</a>
      </small>
    </p>
  </footer>
</form>