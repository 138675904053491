import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CustomerPropertySituationCh,
  FidelityCardTypeCh,
  HabitationTypeCh,
  IsNewTenantCh,
  MemberTypeCh
} from '@proxy/cheetah/client/enum';
import { CustomerDto } from '@proxy/dto-common-website/customers';
import { regexColleagueMatricule, regexPhoneNumber, regexPostalCode } from 'src/app/utils/constants';
import { Context } from 'src/app/utils/context';
import { getTvaFromSiret, isSiretValid } from '../_helpers/siret';

export enum StatusEnum {
  Create,
  Update,
  SmallUpdate,
  Upgrade,
  SmallCreate,
  SmallReadOnly
}

@Injectable({
  providedIn: 'root'
})
export class CardFormCreatorService {
  constructor(private formBuilder: FormBuilder) { }

  colleagueMatriculePatternValidator = Validators.pattern(regexColleagueMatricule);

  createForm(status: StatusEnum, context: Context, customer?: CustomerDto): FormGroup {
    // Form
    let form: FormGroup;

    switch (status) {
      case StatusEnum.Create:
        if (context === Context.Customer) {
          form = this.createFormUpdate(customer, context);
        } else {
          form = this.createFormCreate(customer);
        }
        break;
      case StatusEnum.Upgrade:
        form = this.createFormUpgrade(customer, context);
        break;
      case StatusEnum.Update:
        form = this.createFormUpdate(customer);
        break;
      case StatusEnum.SmallUpdate:
        form = this.createFormSmallUpdate(customer, context);
        break;
      case StatusEnum.SmallReadOnly:
        form = this.createFormSmallReadOnly(customer, context);
        break;
    }

    this.setFormHandlers(form, status, context, customer);

    return form;
  }

  createSmallCreateForm(email: string, cardIsPremium?: boolean) {
    const form = this.createFormSmallCreate(email, cardIsPremium);
    this.setFormHandlers(form, StatusEnum.SmallCreate, Context.Colleague);
    this.buildAddressRestrictions(form);
    return form;
  }

  private setFormHandlers(form: FormGroup, status: StatusEnum, context: Context, customer?: CustomerDto) {
    if (context === Context.Colleague) {
      // A ignorer sur le site collègue
      form.controls['cguValidated']?.removeValidators(Validators.requiredTrue);
    }

    if (form.get('cardIsPremium')?.value
      || customer?.houseNumber || customer?.address
      || customer?.postalCode || customer?.city
      || customer?.country) {
      this.makeRequired(form, ['houseNumber', 'address', 'postalCode', 'city', 'country']);
      this.buildProjectRestrictions(form);
    }

    if (customer?.cardType === FidelityCardTypeCh.Gratuite
      || customer?.cardType === FidelityCardTypeCh.Aucune) {
      this.buildAddressRestrictions(form);
    }

    form.get('cardIsPremium')?.valueChanges.subscribe(cardIsPremium => {
      if (cardIsPremium) {
        this.makeRequired(form, ['houseNumber', 'address', 'city', 'postalCode', 'country']);

        this.buildProjectRestrictions(form);
      } else {
        this.makeOptional(form, ['houseNumber', 'address', 'city', 'postalCode', 'country']);

        this.buildProjectRestrictions(form);
        this.buildAddressRestrictions(form);
      }
    });

    form.get('country').valueChanges.subscribe(country => {
      if (country === 'FR') {
        form.get('phoneNumber').addValidators(Validators.pattern(regexPhoneNumber));
        form.get('postalCode').addValidators(Validators.pattern(regexPostalCode));
      } else {
        form.get('phoneNumber').clearValidators();
        form.get('phoneNumber').addValidators(Validators.required);

        let isPostalCodeRequired = form.get('postalCode').hasValidator(Validators.required);
        form.get('postalCode').clearValidators();

        if (isPostalCodeRequired) {
          form.get('postalCode').addValidators(Validators.required);
        }
      }

      form.get('phoneNumber').updateValueAndValidity();
      form.get('postalCode').updateValueAndValidity();
    });

    form.get('newMoveIn')?.valueChanges.subscribe((newMoveIn: boolean) => {
      this.buildProjectRestrictions(form);

      if (newMoveIn) {
        form.get('isNewTenantCh').setValue(IsNewTenantCh.Yes);
      }
    });

    this.buildProRestrictions(form);

    if (
      !(
        (
          status === StatusEnum.SmallUpdate ||
          status === StatusEnum.SmallCreate ||
          status === StatusEnum.SmallReadOnly
        ) &&
        context === Context.Colleague
      )
    ) {
      this.activateMarketingConsentOptins(form);
    }

    if (context === Context.Colleague) {
      this.setColleagueMatriculeValidators(form, form.get('isColleague')?.value);

      form.get('isColleague')?.valueChanges.subscribe((isColleague: boolean) => {
        this.setColleagueMatriculeValidators(form, isColleague);
      });
    }

    return form;
  }

  private setColleagueMatriculeValidators(form: FormGroup, isColleague: boolean) {
    if (isColleague) {
      setTimeout(() => {
        this.makeRequired(form, ['colleagueMatricule']);
        this.addColleagueMatriculePatternValidator(form);
      });
    } else {
      setTimeout(() => {
        this.makeOptional(form, ['colleagueMatricule']);
        this.removeColleagueMatriculePatternValidator(form);
      });
    }
  }

  private addColleagueMatriculePatternValidator(form: FormGroup) {
    form.controls['colleagueMatricule']?.setValidators(this.colleagueMatriculePatternValidator);
    form.controls['colleagueMatricule']?.updateValueAndValidity({ emitEvent: false });
  }

  private removeColleagueMatriculePatternValidator(form: FormGroup) {
    form.controls['colleagueMatricule']?.removeValidators(this.colleagueMatriculePatternValidator);
    form.controls['colleagueMatricule']?.updateValueAndValidity({ emitEvent: false });
  }

  private createFormMarketingConsent(customer?: CustomerDto): FormGroup {
    return this.formBuilder.group({
      optinEmail: [{
        value: (customer && customer?.marketingConsent.optinEmail) ?? false,
        disabled: !customer?.mail
      }],
      optinSms: [{
        value: (customer && customer?.marketingConsent.optinSms) ?? false,
        disabled: !customer?.phoneNumber
      }],
      optinPhone: [{
        value: (customer && customer?.marketingConsent.optinPhone) ?? false,
        disabled: !customer?.phoneNumber
      }],
      optoutPostal: [{
        value: (customer && customer?.marketingConsent.optoutPostal) ?? false,
        disabled: false
      }]
    });
  }

  private createFormCreate(customer?: CustomerDto): FormGroup {
    return this.formBuilder.group({
      cardIsPremium: [null, [Validators.required]],
      email: [(customer ? customer.mail : null), [Validators.email, Validators.required]],
      phoneNumber: [null, [Validators.required]],
      civility: [{ value: null, disabled: true }, [Validators.required]],
      lastName: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      houseNumber: [null, [Validators.maxLength(10)]],
      address: [null],
      postalCode: [null],
      city: [null],
      birthDate: [null],
      country: [{ value: null, disabled: true }],
      localityPoBox: [null],
      buildingFloorStaircase: [null],
      memberType: [null, [Validators.required]],
      colleagueMatricule: [null],
      newMoveIn: [false],
      isNewTenantCh: [IsNewTenantCh.Eligible],
      lastMoveInDate: [null],
      companyName: [null],
      siret: [null],
      nTvaIntracommunautaire: [{ value: null, disabled: true }],
      marketingConsent: this.createFormMarketingConsent(),
      habitationType: [HabitationTypeCh.NonRenseigne],
      customerPropertySituation: [CustomerPropertySituationCh.NonRenseigne],
      upcomingMove: [false],
      upcomingProject: [null],
      projectNext6Months: this.formBuilder.group({
        kitchen: [{ value: false, disabled: true }],
        bathroom: [{ value: false, disabled: true }],
        floorAndWall: [{ value: false, disabled: true }],
        insulation: [{ value: false, disabled: true }],
        storage: [{ value: false, disabled: true }],
        outdoor: [{ value: false, disabled: true }]
      }),
      isColleague: [false],
      cguValidated: [false, [Validators.requiredTrue]]
    });
  }

  private createFormUpgrade(customer: CustomerDto, context?: Context): FormGroup {
    const form = this.createFormUpdate(customer);
    form.get('cardIsPremium').setValue(true);
    form.get('cardIsPremium').disable();

    if (context === Context.Colleague) {
      form.get('newMoveIn').enable();
      form.get('lastMoveInDate').enable();
    }

    return form;
  }

  private createFormUpdate(customer: CustomerDto, context?: Context): FormGroup {
    const upcomingProject = this.getUpcomingProjectValue(customer);

    let cardIsPremium: boolean = null;

    switch (customer.cardType) {
      case FidelityCardTypeCh.Premium:
      case FidelityCardTypeCh.PremiumWaiting:
        cardIsPremium = true;
        break;
      case FidelityCardTypeCh.Gratuite:
        cardIsPremium = false;
        break;
    }

    return this.formBuilder.group({
      cardIsPremium: [{
        value: cardIsPremium,
        disabled: false
      }],
      memberType: [customer.cardNumber ? customer.memberType : null, [Validators.required]],
      isColleague: [customer.isColleague],
      colleagueMatricule: [customer.colleagueMatricule],
      newMoveIn: [{
        value: customer.isNewTenant === IsNewTenantCh.Yes,
        disabled: context === Context.Customer ? true : customer.isNewTenant !== IsNewTenantCh.Eligible,
      }],
      isNewTenantCh: [customer.isNewTenant],
      lastMoveInDate: [{
        value: customer.lastMoveInDate,
        disabled: context === Context.Customer ? true : customer.isNewTenant !== IsNewTenantCh.Eligible
      }],
      email: [customer.mail, [Validators.email, Validators.required]],
      phoneNumber: [customer.phoneNumber, [Validators.required]],
      civility: [customer.civility, [Validators.required]],
      lastName: [customer.name, [Validators.required]],
      firstName: [customer.firstName, [Validators.required]],
      houseNumber: [customer.houseNumber, [Validators.maxLength(10)]],
      address: [customer.address],
      postalCode: [customer.postalCode],
      city: [customer.city],
      birthDate: [customer.birthDate],
      country: [customer.country],
      localityPoBox: [customer.localityPoBox],
      buildingFloorStaircase: [customer.buildingFloorStaircase],
      companyName: [customer.companyName],
      siret: [null],
      nTvaIntracommunautaire: [{ value: null, disabled: true }],
      marketingConsent: this.createFormMarketingConsent(customer),
      habitationType: [customer.habitationType],
      customerPropertySituation: [customer.customerPropertySituation],
      upcomingMove: [false],
      upcomingProject: [upcomingProject],
      projectNext6Months: this.formBuilder.group({
        kitchen: [{ value: customer.projectNext6Months?.kitchen, disabled: !upcomingProject }],
        bathroom: [{ value: customer.projectNext6Months?.bathroom, disabled: !upcomingProject }],
        floorAndWall: [{ value: customer.projectNext6Months?.floorAndWall, disabled: !upcomingProject }],
        insulation: [{ value: customer.projectNext6Months?.insulation, disabled: !upcomingProject }],
        storage: [{ value: customer.projectNext6Months?.storage, disabled: !upcomingProject }],
        outdoor: [{ value: customer.projectNext6Months?.outdoor, disabled: !upcomingProject }]
      }),
      cguValidated: [false, [Validators.requiredTrue]]
    });
  }

  private createFormSmallUpdate(customer: CustomerDto, context: Context): FormGroup {
    let cardIsPremium: boolean = null;

    switch (customer.cardType) {
      case FidelityCardTypeCh.Premium:
      case FidelityCardTypeCh.PremiumWaiting:
        cardIsPremium = true;
        break;
      case FidelityCardTypeCh.Gratuite:
        cardIsPremium = false;
        break;
    }

    const form = this.formBuilder.group({
      cardIsPremium: [cardIsPremium],
      memberType: [{ value: customer.cardNumber ? customer.memberType : null, disabled: true }, [Validators.required]],
      isColleague: [{ value: customer.isColleague, disabled: true }],
      newMoveIn: [{ value: customer.isNewTenant === IsNewTenantCh.Yes, disabled: true }],
      isNewTenantCh: [{ value: customer.isNewTenant, disabled: true }],
      lastMoveInDate: [{ value: customer.lastMoveInDate, disabled: true }],
      email: [customer.mail, [Validators.email, Validators.required]],
      phoneNumber: [customer.phoneNumber, [Validators.required]],
      civility: [customer.civility, [Validators.required]],
      lastName: [customer.name, [Validators.required]],
      firstName: [customer.firstName, [Validators.required]],
      houseNumber: [customer.houseNumber, [Validators.maxLength(10)]],
      address: [customer.address],
      postalCode: [customer.postalCode],
      city: [customer.city],
      birthDate: [customer.birthDate],
      country: [customer.country],
      localityPoBox: [customer.localityPoBox],
      buildingFloorStaircase: [customer.buildingFloorStaircase],
      companyName: [customer.companyName],
      marketingConsent: this.createFormMarketingConsent(customer),
    });

    if (context === Context.Colleague) {
      form.get('marketingConsent').get('optinEmail').disable();
      form.get('marketingConsent').get('optinSms').disable();
      form.get('marketingConsent').get('optinPhone').disable();
      form.get('marketingConsent').get('optoutPostal').disable();
    }

    return form;
  }

  private createFormSmallReadOnly(customer: CustomerDto, context: Context): FormGroup {
    const form = this.createFormSmallUpdate(customer, context);
    form.disable();

    return form;
  }

  private createFormSmallCreate(mail: string, cardIsPremium?: boolean): FormGroup {
    const form = this.formBuilder.group({
      cardIsPremium: [{ value: cardIsPremium ?? false, disabled: true }],
      email: [mail, [Validators.email, Validators.required]],
      phoneNumber: [null, [Validators.required]],
      civility: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      houseNumber: [null, [Validators.maxLength(10)]],
      address: [null],
      postalCode: [null],
      city: [null],
      birthDate: [null],
      country: [null],
      localityPoBox: [null],
      buildingFloorStaircase: [null]
    });

    return form;
  }

  /**
   * Si le client à une carte payante et qu'il a coché "nouvel emménagé"
   * @param form
   * @private
   */
  private buildProjectRestrictions(form: FormGroup): void {
    if (form.get('newMoveIn') && form.get('habitationType') && form.get('customerPropertySituation')) {
      if (form.get('newMoveIn').value && form.get('cardIsPremium').value) {
        form.get('habitationType').addValidators(Validators.min(HabitationTypeCh.Appartement));
        form.get('customerPropertySituation').addValidators(Validators.min(CustomerPropertySituationCh.Proprietaire));
      } else {
        form.get('habitationType').clearValidators();
        form.get('customerPropertySituation').clearValidators();
      }
    }
  }

  private getUpcomingProjectValue(customer: CustomerDto): boolean | null {
    // Upcoming project
    let upcomingProject = null;

    if (customer.projectNext6Months) {
      upcomingProject = customer.projectNext6Months?.kitchen
        || customer.projectNext6Months?.bathroom
        || customer.projectNext6Months?.floorAndWall
        || customer.projectNext6Months?.insulation
        || customer.projectNext6Months?.storage
        || customer.projectNext6Months?.outdoor;
    }

    return upcomingProject;
  }

  /**
   * Met les champs à required
   * @param form
   * @param controls liste des noms de champs à mettre à required
   */
  private makeRequired(form: FormGroup, controls: string[]): void {
    if (form) {
      controls.forEach(i => {
        form.controls[i]?.setValidators(Validators.required);
        form.controls[i]?.updateValueAndValidity({ emitEvent: false });
      });
    }
  }

  /**
   * Met les champs optionnels
   * @param form
   * @param controls liste des noms de champs à mettre optionnel
   */
  private makeOptional(form: FormGroup, controls: string[]): void {
    controls.forEach(i => {
      form.controls[i].removeValidators(Validators.required);
      form.controls[i].updateValueAndValidity({ emitEvent: false });
    });
  }

  /**
   * Activation des préférences de communication en fonction des champs remplis
   */
  private activateMarketingConsentOptins(form: FormGroup): void {
    form.get('email').valueChanges.subscribe(email => {
      if (email && email.length > 0) {
        form.get('marketingConsent').get('optinEmail').enable();
      } else {
        form.get('marketingConsent').get('optinEmail').disable();
        form.get('marketingConsent').get('optinEmail').setValue(false);
      }
    });

    form.get('phoneNumber').valueChanges.subscribe(phoneNumber => {
      if (phoneNumber && phoneNumber.length > 0 && form.controls['phoneNumber'].valid) {
        form.get('marketingConsent').get('optinSms').enable();
        form.get('marketingConsent').get('optinPhone').enable();
      } else {
        form.get('marketingConsent').get('optinSms').disable();
        form.get('marketingConsent').get('optinPhone').disable();
        form.get('marketingConsent').get('optinSms').setValue(false);
        form.get('marketingConsent').get('optinPhone').setValue(false);
      }
    });

    this.isAddressValid(form).then((valid: boolean) => {
      if (valid) {
        form.get('marketingConsent').get('optoutPostal').enable();
      } else {
        form.get('marketingConsent').get('optoutPostal').setValue(false);
        form.get('marketingConsent').get('optoutPostal').disable();
      }
    });
  }

  /**
   * Restriction de champs d'adresse :
   * Si carte gratuite : adresse facultative si aucun champs n'est renseigné
   * L'adresse devient payante si un des champs d'adresse est rempli
   */
  private buildAddressRestrictions(form: FormGroup): void {
    // Liste des champs contenus dans l'adresse postale nécessitants une restriction
    const inputList: string[] = ['houseNumber', 'address', 'city', 'postalCode', 'country'];

    // Liste des champs qui rendent, si renseignés, les champs principaux de l'adresse postale requis
    const secondaryInputList: string[] = ['localityPoBox', 'buildingFloorStaircase'];

    inputList.concat(secondaryInputList).forEach(i =>
      form.get(i).valueChanges.subscribe(value => onAddressValueChanged(value)));

    const onAddressValueChanged = (value: any): void => {
      if (!value
        && allValueNull()
        && allSecondaryValueNull()
        && !form.controls['cardIsPremium']?.value) {
        this.makeOptional(form, inputList);
      }
      else {
        this.makeRequired(form, inputList);
      }
    }

    /** Détermine si toutes les valeurs des champs de l'adresse ne sont pas remplies */
    const allValueNull = (): boolean => {
      let res: boolean = true;
      inputList.forEach(i => {
        if (form.controls[i].value) {
          res = false;
        };
      });
      return res;
    };

    /** Détermine si toutes les valeurs des champs secondaires de l'adresse ne sont pas remplies */
    const allSecondaryValueNull = (): boolean => {
      let res: boolean = true;
      secondaryInputList.forEach(i => {
        if (form.controls[i].value) res = false;
      });
      return res;
    };
  }

  private buildProRestrictions(form): void {
    form.get('memberType')?.valueChanges.subscribe((memberType: MemberTypeCh) => {
      if (memberType === MemberTypeCh.Professionnel) {
        //SIRET obligatoire si professionnel
        this.makeRequired(form, ['companyName', 'siret']);
      } else if (memberType === MemberTypeCh.Particulier || memberType === MemberTypeCh.NonRenseigne) {
        this.makeOptional(form, ['companyName', 'siret']);
      }
    });

    form.get('siret')?.valueChanges.subscribe((siret: string) => {
      form.get('nTvaIntracommunautaire').setValue(null);
      if (isSiretValid(siret)) {
        form.get('nTvaIntracommunautaire').setValue(getTvaFromSiret(siret));
      }
    });
  }

  /**
   * Détermine si l'adresse est valide ou non
   * @returns boolean
   */
  private isAddressValid(form: FormGroup): Promise<boolean> {
    return new Promise((resolve) => {
      let houseNumberValid: boolean,
        addressValid: boolean,
        postalCodeValid: boolean,
        cityValid: boolean = false,
        countryValid: boolean;

      form.get('houseNumber').valueChanges.subscribe(houseNumber => {
        if (houseNumber?.length > 0) houseNumberValid = true;
        if (houseNumberValid && addressValid && postalCodeValid && cityValid && countryValid) resolve(true);
      });

      form.get('address').valueChanges.subscribe(address => {
        if (address?.length > 0) addressValid = true;
        if (houseNumberValid && addressValid && postalCodeValid && cityValid && countryValid) resolve(true);
      });

      form.get('postalCode').valueChanges.subscribe(postalCode => {
        if (postalCode?.length > 0) postalCodeValid = true;
        if (houseNumberValid && addressValid && postalCodeValid && cityValid && countryValid) resolve(true);
      });

      form.get('city').valueChanges.subscribe(city => {
        if (city?.length > 0) cityValid = true;
        if (houseNumberValid && addressValid && postalCodeValid && cityValid && countryValid) resolve(true);
      });

      form.get('country').valueChanges.subscribe(country => {
        if (country?.length > 0) countryValid = true;
        if (houseNumberValid && addressValid && postalCodeValid && cityValid && countryValid) resolve(true);
      });
    });
  }
}
