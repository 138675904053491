import { mapEnumToOptions } from '@abp/ng.core';

export enum StatisticTypeName {
  NbConnexionCollaborateur = 0,
  NbEchecConnexionCollaborateur = 1,
  NbConnexionUtilisateur = 2,
  NbEchecConnexionUtilisateur = 3,
  NbEchecPaiementCollaborateur = 4,
  NbReussitePaiementCollaborateur = 5,
  NbEchecPaiementUtilisateur = 6,
  NbReussitePaiementUtilisateur = 7,
}

export const statisticTypeNameOptions = mapEnumToOptions(StatisticTypeName);
