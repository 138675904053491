import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './_services/storage.service';
import { GTM } from './_services/gtm.service';
import { Subscription, filter } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ng-casto-root',
  template: `
    <ng-casto-header context="customer"></ng-casto-header>
    <router-outlet></router-outlet>
    <ng-casto-modals></ng-casto-modals>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean;
  roles: any;
  showAdminBoard: boolean;
  showModeratorBoard: boolean;
  username: any;

  routerSubscription: Subscription | undefined;

  constructor(private storageService: StorageService, public gtm: GTM, private router: Router, private titleService: Title) {
    this.gtm.init();

    this.routerSubscription = this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd)
      ).subscribe((e: NavigationEnd) => {
        setTimeout(() => {
          this.gtm.trackPage(e.url, this.titleService.getTitle());
        }, 1000);
      });
  }

  ngOnInit(): void {
    this.isLoggedIn = this.storageService.isLoggedIn();

    if (this.isLoggedIn) {
      const user = this.storageService.getToken();
      this.roles = user.roles;

      if (this.roles) {
        this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
        this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');
      }

      this.username = user.username;
    }

    console.log('Version actuelle : ' + environment.version);
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }
}
