export function getCompleteAddress(houseNumber?: string, address?: string, buildingFloorStaircase?: string, localityPoBox?: string): string {
  return `${houseNumber || ''} ${address || ''}`?.trim();
}

/**
 * Scroll à l'élément
 * @param id id de l'élément
 */
export function scrollTo(id: string): void {
  const yOffset = -65;
  const element = document.getElementById(id);

  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}