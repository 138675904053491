import { ConfigStateService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { FidelityCardTypeCh } from '@proxy/cheetah/client/enum';
import { ImageDto, ImageGroupDto } from '@proxy/dto-common-website/images';
import { ImageService as ColleagueImageService } from '@proxy/user-casto/images';
import { ImageGroupName } from '@proxy/utils';
import { Context } from 'src/app/utils/context';
import { ImageService as CustomerImageService } from 'src/publicproxy/src/proxy/public/images';
import { ModalService } from '../modal.service';

@Component({
  selector: 'ng-casto-card-infos',
  templateUrl: './card-infos.component.html'
})
export class CardInfosComponent implements OnInit {

  @Input() type: FidelityCardTypeCh;
  @Input() context: Context;
  image: ImageDto;
  isModalOpening = false;
  loading = false;

  protected readonly FidelityCardTypeCh = FidelityCardTypeCh;

  constructor(public modalService: ModalService,
    public config: ConfigStateService,
    private colleagueImageService: ColleagueImageService,
    private customerImageService: CustomerImageService) { }

  ngOnInit(): void {
    this.loading = true;

    if (!this.type) {
      this.isModalOpening = true;
      this.type = this.modalService.currentDatas.type;
      this.context = this.modalService.currentDatas.context;
    }

    const $getImageGroupCompleteByName = this.context === Context.Colleague
      ? this.colleagueImageService.getImageGroupCompleteByName(ImageGroupName.ImageGroupNameFidelityCard)
      : this.customerImageService.getImageGroupFromName(ImageGroupName.ImageGroupNameFidelityCard);

    $getImageGroupCompleteByName.subscribe((imageGroupDto) => {
      this.image = this.getImage(imageGroupDto);
      this.loading = false;
    });
  }

  private getImage(imageGroup: ImageGroupDto): ImageDto | undefined {
    switch (this.type) {
      case FidelityCardTypeCh.Premium:
      case FidelityCardTypeCh.PremiumWaiting:
        return imageGroup.images.find(i => i.tooltip === "PremiumCard");

      case FidelityCardTypeCh.Gratuite:
        return imageGroup.images.find(i => i.tooltip === "FreeCard");
    }
  }

  redirectUrl() {
    window.open(this.image.urlRedirect, '_blank');
  }
}
