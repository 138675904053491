<form [formGroup]="changePasswordForm">
  <header>
    <div class="wrap">
      <div class="text">
        <h1 class="title mt-3">
          <span class="text">Modifier mon mot de passe</span>
        </h1>

        <div class="form-group">
          <div class="form-row">
            <div class="form-col">
              <label for="oldPassword" class="required">Ancien mot de passe</label>
              <input class="casto-input"
                     formControlName="oldPassword"
                     type="password"
                     name="oldPassword"
                     id="oldPassword">
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="newPassword" class="required">Nouveau mot de passe</label>
              <input class="casto-input"
                     disabled
                     formControlName="newPassword"
                     type="password"
                     name="newPassword"
                     id="newPassword">
              <small class="error"
                     *ngIf="changePasswordForm.controls['newPassword'].invalid
                            && changePasswordForm.controls['newPassword'].dirty
                            && changePasswordForm.controls['newPassword'].touched">
                Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre, un caractère spécial et faire entre 13 et 35 caractères.
              </small>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="newPasswordConfirm" class="required">Confirmation du nouveau mot de passe</label>
              <input class="casto-input"
                     disabled
                     formControlName="newPasswordConfirm"
                     type="password"
                     name="newPasswordConfirm"
                     id="newPasswordConfirm">
              <small class="error"
                     *ngIf="changePasswordForm.controls['newPasswordConfirm'].dirty
                             && changePasswordForm.controls['newPasswordConfirm'].touched
                             && changePasswordForm.errors?.notSame">
                Les mots de passe ne correspondent pas
              </small>
            </div>
          </div>
        </div>

      </div>
      <div class="header-actions">
        <button class="close" (click)="modalService.close()" title="Fermer">
          <span class="icon-view-close" aria-hidden="true">
            <svg>
              <use xlink:href="assets/img/icons.svg#icon-view-close"></use>
            </svg>
          </span>
          <span>Fermer</span>
        </button>
      </div>
    </div>
  </header>
  <div class="main">
    <footer class="popin-footer">
      <ng-casto-btn class="outline" label="Annuler" (click)="modalService.close()"></ng-casto-btn>
      <ng-casto-btn class="blue" [disabled]="changePasswordForm.invalid" label="Modifier"></ng-casto-btn>
    </footer>
  </div>
</form>
