<form class="form-card"
      [formGroup]="form"
      (ngSubmit)="forgottenPassword()">
  <header class="login-card-header">
    <img class="login-card-title" src="assets/img/login/forgotten-password.svg">
    <h3 class="login-card-subtitle">Pour profiter de tous mes avantages …</h3>
  </header>

  <section class="login-card-body form-group">
    <div class="form-row">
      <div class="form-col">
        <small>Veuillez saisir votre adresse mail ci-dessous. Un e-mail de récupération vous sera envoyé.</small>
      </div>
    </div>

    <div class="form-row">
      <div class="form-col">
        <label for="forgotten-password-email" class="required">Adresse mail</label>
        <div class="input-icon">
          <svg (click)="forgottenPasswordEmail.focus()"><use xlink:href="assets/img/icons.svg#icon-login-email"></use></svg>
          <input #forgottenPasswordEmail
                 type="text"
                 class="casto-input"
                 name="email"
                 id="forgotten-password-email"
                 formControlName="email">
        </div>
      </div>
    </div>

    <div class="error" *ngIf="errorMessage !== ''">{{errorMessage}}</div>
    <div class="valid text-center mb-3" *ngIf="validMessage !== ''">{{validMessage}}</div>

    <div class="form-row">
      <div class="form-col text-end">
        <a (click)="onSwitchContext(LoginContext.login)"
           class="casto-link forgotten-password-link"
           target="_blank">Me connecter</a>
      </div>
    </div>
  </section>

  <footer class="login-card-footer">
    <ng-casto-btn class="blue"
                  [label]="'Envoyer l\'e-mail de récupération'"
                  [disabled]="form.invalid"
                  (click)="forgottenPassword()"
                  [loading]="loading"></ng-casto-btn>
  </footer>
</form>
