import { Component, Input, OnInit, ViewChild  } from '@angular/core';
import { PointInfoDto } from '@proxy/dto-common-website/fidelity-card/models';
import { CustomerService as ColleagueCustomerService } from '@proxy/user-casto/customers';
import { Chart } from 'chart.js/auto';
import { GTM } from 'src/app/_services/gtm.service';
import { MODAL, ModalService } from 'src/app/modals/modal.service';
import { CustomerService } from 'src/publicproxy/src/proxy/public/customer';
import { Voucher } from '../../../utils/voucher';
import { FidelityCardTypeCh } from '@proxy/cheetah/client/enum';
import { StorageService } from 'src/app/_services/storage.service';
import { Context } from 'src/app/utils/context';

@Component({
  selector: 'ng-casto-common-customer-points',
  templateUrl: './customer-points.component.html'
})
export class CustomerPointsComponent implements OnInit {
  @Input() customerId = '';
  @Input() context: Context;
  @Input() cardType: FidelityCardTypeCh;

  chart: Chart;
  private chartCtx:HTMLCanvasElement;

  protected readonly FidelityCardTypeCh = FidelityCardTypeCh;
  protected readonly Context = Context;

  @ViewChild('nbPointsText') nbPointsText;

  customerPoints: PointInfoDto;
  lastValidVoucher: Voucher;

  progressBarPlugin = {
    id: 'progressBar',
    beforeDatasetsDraw(chart: Chart) {
      // calcul de la position du cursor
      const points = chart.data.datasets[0].data[0] as number;
      const totalPoints = points + (chart.data.datasets[1].data[0] as number);
      const badgePoints = document.getElementById('actual-points');
      const badgeCursorWidth = 2;
      const marginLeft = 20;
      const circleRadius = 6;
      const circlePos = chart.width * points / totalPoints;

      // position du badge
      this.ptsPos = circlePos - badgePoints.clientWidth / 2 + marginLeft + circleRadius + badgeCursorWidth / 2;

      // ajout d'un rond à la fin de la barre pour le style
      chart.ctx.globalCompositeOperation = 'destination-over';
      chart.ctx.beginPath();
      chart.ctx.arc(circlePos, 35, circleRadius, 0, 4 * Math.PI);
      chart.ctx.fillStyle = '#0078d4';
      chart.ctx.fill();
    },
    ptsPos: null
  };

  isLoading = false;

  constructor(private colleagueCustomerService: ColleagueCustomerService,
              private customerService: CustomerService,
              private storageService: StorageService,
              private gtm: GTM,
              private modalService: ModalService) {}

  ngOnInit(): void {
    this.isLoading = true;

    if (this.context === Context.Colleague) {
      this.colleagueCustomerService.getPointInfo(this.customerId).subscribe({
        next: (points) => {
          this.buildProgress(points);
          this.getLastValidVoucher();
          this.isLoading = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoading = false;
        }
      });
    } else {
      this.customerService.getPointInfo(this.storageService.getToken().access_token).subscribe({
        next: (points) => {
          this.buildProgress(points);
          this.getLastValidVoucher();
          this.isLoading = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoading = false;
        }
      });
    }
  }

  countVouchers(): number {
    const giftCardLength = this.customerPoints?.discountsList.giftCardsList
      .filter(g => g.active && !this.isExpire(new Date(g.usabilityEndDate)))
      .length;

    const offerCouponLength = this.customerPoints?.discountsList.offerCouponsList
      .filter(o => o.usable && !this.isExpire(new Date(o.usabilityEndDate)))
      .length;

    return giftCardLength + offerCouponLength;
  }

  private isExpire(dateEnd: Date | string): boolean {
    let now = new Date();
    now.setHours(0, 0, 0, 0);

    dateEnd = new Date(dateEnd);
    dateEnd.setHours(0, 0, 0, 0);

    return dateEnd.getTime() < now.getTime();
  }

  private getLastValidVoucher(): Voucher {
    const offerCoupon = this.customerPoints?.discountsList.offerCouponsList
      .filter(o => o.usable && !this.isExpire(new Date(o.usabilityEndDate)))
      .sort((a, b) =>
        new Date(b.usabilityStartDate).getTime() - new Date(a.usabilityStartDate).getTime())[0];

    if (offerCoupon) {
      this.lastValidVoucher = new Voucher(null, null, offerCoupon);
      return;
    }

    const giftCard = this.customerPoints?.discountsList.giftCardsList
      .filter(o => o.active && !this.isExpire(new Date(o.usabilityEndDate)))
      .sort((a, b) =>
        new Date(b.usabilityStartDate).getTime() - new Date(a.usabilityStartDate).getTime())[0];

    if (giftCard) {
      this.lastValidVoucher = new Voucher(null, null, null, giftCard);
    }

    return;
  }

  seeVouchers(): void {
    this.modalService.display(MODAL.VOUCHERS, {
      customerId: this.customerId,
      context: this.context
    });
  }

  private buildProgress(points: PointInfoDto): void {
    this.customerPoints = points;
    
    // On bloque la barre d'avancement à 0 en cas de points négatifs
    const nbPoints = this.customerPoints.numberOfPoint >= 0 ? this.customerPoints.numberOfPoint : 0;

    // si le nombre de points dépasse le max, on cap la longueur au max possible
    const lengthPoints = nbPoints <= this.customerPoints.maxPointForNextStep
      ? nbPoints
      : this.customerPoints.maxPointForNextStep;
    const maxLengthPoints = this.customerPoints.maxPointForNextStep - lengthPoints;

    this.chartCtx = document.getElementById("chart") as HTMLCanvasElement;
    if (this.chartCtx) {
      this.createChart(lengthPoints, maxLengthPoints);
    }
  }

  private createChart(lengthPoints: number, maxLengthPoints: number) {
    this.chart = new Chart(this.chartCtx, {
      type: 'bar',
      data: {
        labels: [''],
        datasets: [
          {
            data: [lengthPoints],
            backgroundColor: "#0078d4",
            hoverBackgroundColor: "#0078d4",
            borderSkipped: false,
            barPercentage: .3
          },{
            data: [maxLengthPoints],
            backgroundColor: "#E9F4FC",
            hoverBackgroundColor: "#E9F4FC",
            borderSkipped: false,
            barPercentage: .3
          }
        ]
      },
      options: {
        indexAxis: 'y',
        events: [],
        plugins: {
          legend: {
            display: false,
          }
        },
        layout: {
          padding: {
            left: -20,
            right: -20,
            top: 0,
            bottom: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              stepSize: 1,
            },
            border: {
              display: false
            }
          },
          y: {
            stacked: true,
            beginAtZero: true,
            grid: {
              display: false
            },
            border: {
              display: false
            }
          }
        },
      },
      plugins: [this.progressBarPlugin]
    });
  }

  event(): void {
    this.gtm.trackEvent("clic_event", "fiche-client", "historique-points", this.context === Context.Colleague ? [{userType: "collegue"}] : [{userType: "client"}]);
  }

  isCustomer(): boolean {
    return this.context === Context.Customer;
  }
}
