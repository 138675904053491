import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetPaymentLinkPwDto, PaytweakRequestDto } from '../../paytweak-api/dto/models';

@Injectable({
  providedIn: 'root',
})
export class PaytweakService {
  apiName = 'Default';
  

  getPaymentLinkPaytweak = (input: PaytweakRequestDto, registeredToken: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GetPaymentLinkPwDto>({
      method: 'GET',
      url: '/api/app/paytweak/payment-link-paytweak',
      params: { customerId: input.customerId, siret: input.siret, numTvaIntra: input.numTvaIntra, cardNumber: input.cardNumber, firstname: input.firstname, lastname: input.lastname, address: input.address, postalCode: input.postalCode, city: input.city, country: input.country, phone: input.phone, email: input.email, registeredToken },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
