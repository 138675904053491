import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'ng-casto-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  constructor(public modal:ModalService) { }

  ngOnInit(): void {
  }

  signout(){
    this.modal.close();
  }
}
