<form [formGroup]="phoneNumberForm">
  <header>
    <div class="wrap">
      <div class="text">
        <h1 class="title"><span class="text">Renvoyer le lien par SMS</span></h1>
        <h2 class="modal-subtitle">Confirmer votre numéro de téléphone pour renvoyer le lien</h2>
      </div>

      <div class="form-group">
        <div class="form-row">
          <div class="form-col">
            <label for="phoneNumber" class="required">Numéro de téléphone</label>
            <input class="casto-input" formControlName="phoneNumber" type="tel" name="phoneNumber" id="phoneNumber">
          </div>
        </div>
      </div>

      <p *ngIf="sendSuccess" class="text-success text-center mt-3">Le lien a été renvoyé avec succès.</p>

      <div class="header-actions">
        <button class="close" (click)="modalService.close()" title="Fermer">
        <span class="icon-view-close" aria-hidden="true">
          <svg>
            <use xlink:href="assets/img/icons.svg#icon-view-close"></use>
          </svg>
        </span>
          <span>Fermer</span>
        </button>
      </div>
    </div>
  </header>

  <div class="main">
    <footer class="popin-footer">
      <ng-casto-btn class="blue"
                    [disabled]="phoneNumberForm.invalid"
                    [loading]="loading"
                    [label]="'Confirmer'"
                    (click)="sendLink()"></ng-casto-btn>
    </footer>
  </div>
</form>
